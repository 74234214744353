import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  ImageBackground,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const MemberDetailScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const sendDirectMessage = (my_id, rec_id, auth_header) => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    let result = [];
    result.push(my_id);
    result.push(rec_id);
    result = result.sort();

    try {
      const getOptions = {
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + auth_header,
          'Content-Type': 'application/json',
        }),
      };
      fetch(
        'https://xjz0-jmv4-gghx.n7.xano.io/api:D9yaTDL_/chatrooms/existing/' +
          result.join('-'),
        getOptions
      ).then(response =>
        response.json().then(data => {
          if (data.id) {
            navigation.navigate('ChatScreen', {
              chatroom_id: data?.id,
            });
          } else {
            //Else navigate them to below logic that creates a new chatroom
            const requestOptions = {
              method: 'POST',
              headers: new Headers({
                Authorization: 'Bearer ' + auth_header,
                'Content-Type': 'application/json',
              }),
              body: JSON.stringify({
                members_ids: result,
                title: result.join('-'),
              }),
            };
            fetch(
              'https://xjz0-jmv4-gghx.n7.xano.io/api:D9yaTDL_/chatrooms',
              requestOptions
            ).then(response =>
              response.json().then(data =>
                navigation.navigate('ChatScreen', {
                  chatroom_id: data?.id,
                })
              )
            );
          }
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const [imageBase, setImageBase] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={true}
    >
      <RisingCoachesApi.FetchGetUserDetailGET
        method={'GET'}
        user_id={props.route?.params?.navigate_user_Id ?? ''}
      >
        {({ loading, error, data, refetchGetUserDetail }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              <Touchable>
                <View
                  style={StyleSheet.applyWidth(
                    { height: 420, width: '100%' },
                    dimensions.width
                  )}
                >
                  <ImageBackground
                    resizeMode={'cover'}
                    source={{ uri: `${fetchData?.profile_image?.url}` }}
                    style={StyleSheet.applyWidth(
                      {
                        height: '100%',
                        justifyContent: 'flex-end',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: 24,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 24,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors.primary,
                            borderRadius: 8,
                            flexDirection: 'row',
                            paddingBottom: 6,
                            paddingLeft: 12,
                            paddingRight: 8,
                            paddingTop: 6,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.surface,
                                fontFamily: 'Poppins_500Medium',
                                fontSize: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {fetchData?.name}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </ImageBackground>
                </View>
              </Touchable>

              <View>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      paddingBottom: 24,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 24,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.light,
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {fetchData?.job_title}
                  </Text>

                  <Text
                    allowFontScaling={true}
                    ellipsizeMode={'tail'}
                    numberOfLines={2}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 22,
                      },
                      dimensions.width
                    )}
                    textBreakStrategy={'highQuality'}
                  >
                    {fetchData?.current_university}
                  </Text>
                  <Spacer bottom={8} left={8} right={8} top={8} />
                  <Spacer bottom={12} left={8} right={8} top={12} />
                  <View>
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Poppins_600SemiBold',
                          fontSize: 16,
                        },
                        dimensions.width
                      )}
                    >
                      {'Bio'}
                    </Text>
                    <Spacer bottom={6} left={8} right={8} top={6} />
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.medium,
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 14,
                          lineHeight: 26,
                          textAlign: 'left',
                        },
                        dimensions.width
                      )}
                    >
                      {fetchData?.bio}
                    </Text>
                  </View>
                  <Spacer bottom={12} left={8} right={8} top={12} />
                  <View>
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Poppins_600SemiBold',
                          fontSize: 16,
                        },
                        dimensions.width
                      )}
                    >
                      {'Social Media'}
                    </Text>
                    <Spacer bottom={6} left={8} right={8} top={6} />
                    <>
                      {!(fetchData && fetchData['_islinkedin']) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'Poppins_400Regular',
                                },
                                dimensions.width
                              )}
                            >
                              {'LinkedIn'}
                            </Text>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', flex: 1 },
                              dimensions.width
                            )}
                          >
                            <IconButton
                              icon={'AntDesign/linkedin-square'}
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    await WebBrowser.openBrowserAsync(
                                      `${fetchData?.linkedin_link}`
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              size={32}
                            />
                          </View>
                        </View>
                      )}
                    </>
                    <Divider
                      color={theme.colors.lightest}
                      style={StyleSheet.applyWidth(
                        { height: 1, marginBottom: 12, marginTop: 12 },
                        dimensions.width
                      )}
                    />
                    <>
                      {!(fetchData && fetchData['_istwitter']) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'Poppins_400Regular',
                                },
                                dimensions.width
                              )}
                            >
                              {'Twitter'}
                            </Text>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', flex: 1 },
                              dimensions.width
                            )}
                          >
                            <IconButton
                              icon={'AntDesign/twitter'}
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    await WebBrowser.openBrowserAsync(
                                      `${fetchData?.twitter_link}`
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              size={32}
                            />
                          </View>
                        </View>
                      )}
                    </>
                    <Divider
                      color={theme.colors.lightest}
                      style={StyleSheet.applyWidth(
                        { height: 1, marginBottom: 12, marginTop: 12 },
                        dimensions.width
                      )}
                    />
                    <>
                      {!(fetchData && fetchData['_isuniversitybio']) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'Poppins_400Regular',
                                },
                                dimensions.width
                              )}
                            >
                              {'University Bio'}
                            </Text>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', flex: 1 },
                              dimensions.width
                            )}
                          >
                            <IconButton
                              icon={'FontAwesome/university'}
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    await WebBrowser.openBrowserAsync(
                                      `${fetchData?.university_bio_link}`
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              size={32}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                  <Spacer bottom={16} left={8} right={8} top={16} />
                </View>
              </View>
            </>
          );
        }}
      </RisingCoachesApi.FetchGetUserDetailGET>
    </ScreenContainer>
  );
};

export default withTheme(MemberDetailScreen);
