import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const addJobToTimelinePOST = (
  Constants,
  { end_date, isCurrentJob, job_description, job_title, organization, user_id },
  handlers = {}
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/job_timeline`, {
    body: JSON.stringify({
      job_title: job_title,
      organization: organization,
      user_id: user_id,
      end_date: end_date,
      isCurrentJob: isCurrentJob,
      job_description: job_description,
      start_date: null,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useAddJobToTimelinePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      addJobToTimelinePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Profile', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Profile');
        queryClient.invalidateQueries('Profiles');
      },
    }
  );
};

export const FetchAddJobToTimelinePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  end_date,
  isCurrentJob,
  job_description,
  job_title,
  organization,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAddJobToTimelinePOST(
    {
      end_date,
      isCurrentJob,
      job_description,
      job_title,
      organization,
      user_id,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAddJobToTimeline: refetch });
};

export const addLikePOST = (Constants, { feed_post_id }, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/likes`, {
    body: JSON.stringify({ feed_post_id: feed_post_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useAddLikePOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => addLikePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Likes', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Like');
        queryClient.invalidateQueries('Likes');
      },
    }
  );
};

export const FetchAddLikePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  feed_post_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAddLikePOST(
    { feed_post_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAddLike: refetch });
};

export const applyPOST = (Constants, { opportunity_id }, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/applicants`, {
    body: JSON.stringify({ opportunities_id: opportunity_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useApplyPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => applyPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Opportunities', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Opportunity');
        queryClient.invalidateQueries('Opportunities');
      },
    }
  );
};

export const FetchApplyPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  opportunity_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useApplyPOST(
    { opportunity_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchApply: refetch });
};

export const checkAuthGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/auth/me`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useCheckAuthGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['risingCoachesCheckAuthGET', args],
    () => checkAuthGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['risingCoachesCheckAuthGETS']),
    }
  );
};

export const FetchCheckAuthGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCheckAuthGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCheckAuth: refetch });
};

export const checkLiveEventGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/live_event`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useCheckLiveEventGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Live Event', args],
    () => checkLiveEventGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Live Events']),
    }
  );
};

export const FetchCheckLiveEventGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCheckLiveEventGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCheckLiveEvent: refetch });
};

export const checkPayingGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/paying/user`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useCheckPayingGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['User', args],
    () => checkPayingGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Users']),
    }
  );
};

export const FetchCheckPayingGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCheckPayingGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCheckPaying: refetch });
};

export const createAccountPOST = (
  Constants,
  { email, handle, name, password, profile_image },
  handlers = {}
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/auth/signup`, {
    body: JSON.stringify({
      name: name,
      email: email,
      password: password,
      handle: handle,
      profile_image: profile_image,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateAccountPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createAccountPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const FetchCreateAccountPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  handle,
  name,
  password,
  profile_image,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateAccountPOST(
    { email, handle, name, password, profile_image },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateAccount: refetch });
};

export const createAdminContentPOST = (
  Constants,
  {
    content_title,
    content_type,
    content_uploader_id,
    file_content,
    folder_id,
    image_content,
    isFile,
    isImage,
    isShared,
    isVideo,
    tags,
    text_content,
    youtubeID,
  },
  handlers = {}
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/admin/folder_content`, {
    body: JSON.stringify({
      folders_id: folder_id,
      content_uploader: content_uploader_id,
      content_title: content_title,
      tags: tags,
      content_type: content_type,
      text_content: text_content,
      content: image_content,
      isShared: isShared,
      youtubeId: youtubeID,
      isVideo: isVideo,
      isFile: isFile,
      isImage: isImage,
      file_content: file_content,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateAdminContentPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createAdminContentPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const FetchCreateAdminContentPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  content_title,
  content_type,
  content_uploader_id,
  file_content,
  folder_id,
  image_content,
  isFile,
  isImage,
  isShared,
  isVideo,
  tags,
  text_content,
  youtubeID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateAdminContentPOST(
    {
      content_title,
      content_type,
      content_uploader_id,
      file_content,
      folder_id,
      image_content,
      isFile,
      isImage,
      isShared,
      isVideo,
      tags,
      text_content,
      youtubeID,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateAdminContent: refetch });
};

export const createAdminFolderPOST = (Constants, { name }, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/admin/folders`, {
    body: JSON.stringify({ name: name }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateAdminFolderPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createAdminFolderPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const FetchCreateAdminFolderPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateAdminFolderPOST(
    { name },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateAdminFolder: refetch });
};

export const createCalendarItemPOST = (
  Constants,
  {
    creator_id,
    description,
    duration_minutes,
    event_date,
    event_day,
    event_location,
    event_name,
    geometry,
  },
  handlers = {}
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/calendar`, {
    body: JSON.stringify({
      event_name: event_name,
      height: 100,
      day: event_day,
      datetime: event_date,
      creator_id: creator_id,
      location: event_location,
      start_time: null,
      duration_minutes: duration_minutes,
      description: description,
      geo: geometry,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateCalendarItemPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createCalendarItemPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Calendar', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Calendar');
        queryClient.invalidateQueries('Calendars');
      },
    }
  );
};

export const FetchCreateCalendarItemPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  creator_id,
  description,
  duration_minutes,
  event_date,
  event_day,
  event_location,
  event_name,
  geometry,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateCalendarItemPOST(
    {
      creator_id,
      description,
      duration_minutes,
      event_date,
      event_day,
      event_location,
      event_name,
      geometry,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateCalendarItem: refetch });
};

export const createCommentPOST = (
  Constants,
  { author_user_id, comment_text, feed_post_id },
  handlers = {}
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/comment`, {
    body: JSON.stringify({
      comment_text: comment_text,
      author_user_id: author_user_id,
      feed_post_id: feed_post_id,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateCommentPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createCommentPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Comment', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Comment');
        queryClient.invalidateQueries('Comments');
      },
    }
  );
};

export const FetchCreateCommentPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  author_user_id,
  comment_text,
  feed_post_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateCommentPOST(
    { author_user_id, comment_text, feed_post_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateComment: refetch });
};

export const createContentPOST = (
  Constants,
  {
    content_title,
    content_type,
    content_uploader_id,
    file_content,
    folder_id,
    image_content,
    isFile,
    isFolder,
    isImage,
    isShared,
    isVideo,
    sub_folder_id,
    tags,
    text_content,
    youtubeID,
  },
  handlers = {}
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/folder_content`, {
    body: JSON.stringify({
      folders_id: folder_id,
      content_uploader: content_uploader_id,
      content_title: content_title,
      tags: tags,
      content_type: content_type,
      text_content: text_content,
      content: image_content,
      isShared: isShared,
      youtubeId: youtubeID,
      isVideo: isVideo,
      isFile: isFile,
      isFolder: isFolder,
      isImage: isImage,
      file_content: file_content,
      sub_folder_id: sub_folder_id,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateContentPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createContentPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const FetchCreateContentPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  content_title,
  content_type,
  content_uploader_id,
  file_content,
  folder_id,
  image_content,
  isFile,
  isFolder,
  isImage,
  isShared,
  isVideo,
  sub_folder_id,
  tags,
  text_content,
  youtubeID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateContentPOST(
    {
      content_title,
      content_type,
      content_uploader_id,
      file_content,
      folder_id,
      image_content,
      isFile,
      isFolder,
      isImage,
      isShared,
      isVideo,
      sub_folder_id,
      tags,
      text_content,
      youtubeID,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateContent: refetch });
};

export const createFeedPostPOST = (
  Constants,
  {
    content_title,
    content_type,
    file_content,
    image_content,
    isFile,
    isImage,
    isText,
    isVideo,
    tags,
    text_content,
    youtubeID,
  },
  handlers = {}
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/news_feed_content`, {
    body: JSON.stringify({
      content_title: content_title,
      tags: tags,
      content_type: content_type,
      text_content: text_content,
      content: image_content,
      youtubeId: youtubeID,
      isVideo: isVideo,
      isFile: isFile,
      isImage: isImage,
      isText: isText,
      file_content: file_content,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateFeedPostPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createFeedPostPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Feed', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Feed');
        queryClient.invalidateQueries('Feeds');
      },
    }
  );
};

export const FetchCreateFeedPostPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  content_title,
  content_type,
  file_content,
  image_content,
  isFile,
  isImage,
  isText,
  isVideo,
  tags,
  text_content,
  youtubeID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateFeedPostPOST(
    {
      content_title,
      content_type,
      file_content,
      image_content,
      isFile,
      isImage,
      isText,
      isVideo,
      tags,
      text_content,
      youtubeID,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateFeedPost: refetch });
};

export const createFolderPOST = (
  Constants,
  { folder_name, program_id },
  handlers = {}
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/folders`, {
    body: JSON.stringify({ name: folder_name, programs_id: program_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateFolderPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createFolderPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const FetchCreateFolderPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  folder_name,
  program_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateFolderPOST(
    { folder_name, program_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateFolder: refetch });
};

export const createRSVPPOST = (Constants, { event_id }, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/rsvps`, {
    body: JSON.stringify({ event_id: event_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateRSVPPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createRSVPPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Calendar', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Calendar');
        queryClient.invalidateQueries('Calendars');
      },
    }
  );
};

export const FetchCreateRSVPPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  event_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateRSVPPOST(
    { event_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateRSVP: refetch });
};

export const createStoryPOST = (Constants, { content }, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/live_stories`, {
    body: JSON.stringify({ content: content }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateStoryPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createStoryPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Live Stories', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Live Story');
        queryClient.invalidateQueries('Live Stories');
      },
    }
  );
};

export const FetchCreateStoryPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  content,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateStoryPOST(
    { content },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateStory: refetch });
};

export const dELETEACCOUNTDELETE = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/user/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDELETEACCOUNTDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      dELETEACCOUNTDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const deleteAccountDELETE = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/user/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteAccountDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteAccountDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const deleteAdminFolderDELETE = (
  Constants,
  { folders_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/admin/folders/${
      typeof folders_id === 'string'
        ? folders_id
        : JSON.stringify(folders_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteAdminFolderDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteAdminFolderDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const deleteContentItemDELETE = (
  Constants,
  { folder_content_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/folder_content/${
      typeof folder_content_id === 'string'
        ? folder_content_id
        : JSON.stringify(folder_content_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteContentItemDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteContentItemDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const deleteFeedPostDELETE = (
  Constants,
  { news_feed_content_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/news_feed_content/${
      typeof news_feed_content_id === 'string'
        ? news_feed_content_id
        : JSON.stringify(news_feed_content_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteFeedPostDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteFeedPostDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Feed', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Feed');
        queryClient.invalidateQueries('Feeds');
      },
    }
  );
};

export const deleteFolderDELETE = (Constants, { folders_id }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/folders/${
      typeof folders_id === 'string'
        ? folders_id
        : JSON.stringify(folders_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteFolderDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteFolderDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const deleteJobDELETE = (
  Constants,
  { job_timeline_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/job_timeline/${
      typeof job_timeline_id === 'string'
        ? job_timeline_id
        : JSON.stringify(job_timeline_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteJobDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deleteJobDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Profile', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Profile');
        queryClient.invalidateQueries('Profiles');
      },
    }
  );
};

export const deleteNotificationDELETE = (
  Constants,
  { notifications_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/notifications/${
      typeof notifications_id === 'string'
        ? notifications_id
        : JSON.stringify(notifications_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteNotificationDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteNotificationDELETE(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Notifications', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Notification');
        queryClient.invalidateQueries('Notifications');
      },
    }
  );
};

export const deleteRSVPDELETE = (Constants, { rsvp_id }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/rsvps/${
      typeof rsvp_id === 'string' ? rsvp_id : JSON.stringify(rsvp_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteRSVPDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => deleteRSVPDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Calendar', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Calendar');
        queryClient.invalidateQueries('Calendars');
      },
    }
  );
};

export const followUserPOST = (Constants, { followed_id }, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/follows`, {
    body: JSON.stringify({ followed_id: followed_id }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useFollowUserPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => followUserPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Follows', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Follow');
        queryClient.invalidateQueries('Follows');
      },
    }
  );
};

export const FetchFollowUserPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  followed_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useFollowUserPOST(
    { followed_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchFollowUser: refetch });
};

export const getAdminContentGET = (Constants, { folder_id }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/admin/folder_content/folder/${
      typeof folder_id === 'string'
        ? folder_id
        : JSON.stringify(folder_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetAdminContentGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Respositories', args],
    () => getAdminContentGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAdminContentGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  folder_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAdminContentGET(
    { folder_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAdminContent: refetch });
};

export const getAdminContentDetailsGET = (
  Constants,
  { folder_content_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/admin/folder_content/${
      typeof folder_content_id === 'string'
        ? folder_content_id
        : JSON.stringify(folder_content_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetAdminContentDetailsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Respository', args],
    () => getAdminContentDetailsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Respositories']),
    }
  );
};

export const FetchGetAdminContentDetailsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  folder_content_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAdminContentDetailsGET(
    { folder_content_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetAdminContentDetails: refetch,
  });
};

export const getAdminFoldersGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/admin/folders`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetAdminFoldersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Respositories', args],
    () => getAdminFoldersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAdminFoldersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAdminFoldersGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAdminFolders: refetch });
};

export const getAllUsersGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/user`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetAllUsersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Users', args],
    () => getAllUsersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAllUsersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllUsersGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAllUsers: refetch });
};

export const getBirthdaysGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/birthdays/user`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetBirthdaysGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Users', args],
    () => getBirthdaysGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetBirthdaysGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetBirthdaysGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetBirthdays: refetch });
};

export const getCalendarGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/calendar`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetCalendarGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Calendars', args],
    () => getCalendarGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetCalendarGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCalendarGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetCalendar: refetch });
};

export const getContentDetailsGET = (
  Constants,
  { folder_content_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/folder_content/${
      typeof folder_content_id === 'string'
        ? folder_content_id
        : JSON.stringify(folder_content_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetContentDetailsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Respository', args],
    () => getContentDetailsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Respositories']),
    }
  );
};

export const FetchGetContentDetailsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  folder_content_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetContentDetailsGET(
    { folder_content_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetContentDetails: refetch });
};

export const getEventDetailGET = (Constants, { calendar_id }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/calendar/${
      typeof calendar_id === 'string'
        ? calendar_id
        : JSON.stringify(calendar_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetEventDetailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Calendar', args],
    () => getEventDetailGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Calendars']),
    }
  );
};

export const FetchGetEventDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  calendar_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetEventDetailGET(
    { calendar_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetEventDetail: refetch });
};

export const getFeaturedVideosGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/featured_videos`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetFeaturedVideosGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Feeds', args],
    () => getFeaturedVideosGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetFeaturedVideosGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFeaturedVideosGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetFeaturedVideos: refetch });
};

export const getFeedPostGET = (Constants, { post_id }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/news_feed_content/${
      typeof post_id === 'string' ? post_id : JSON.stringify(post_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetFeedPostGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Feed', args],
    () => getFeedPostGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Feeds']),
    }
  );
};

export const FetchGetFeedPostGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  post_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFeedPostGET(
    { post_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetFeedPost: refetch });
};

export const getFeedPostsGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/news_feed_content`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetFeedPostsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Feeds', args],
    () => getFeedPostsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetFeedPostsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFeedPostsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetFeedPosts: refetch });
};

export const getFeedWithFilterGET = (Constants, { category }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/filter/news_feed_content/${
      typeof category === 'string' ? category : JSON.stringify(category ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetFeedWithFilterGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Feeds', args],
    () => getFeedWithFilterGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetFeedWithFilterGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  category,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFeedWithFilterGET(
    { category },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetFeedWithFilter: refetch });
};

export const getFolderContentsWebGET = (
  Constants,
  { folder_content_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/webapp/folder_content/${
      typeof folder_content_id === 'string'
        ? folder_content_id
        : JSON.stringify(folder_content_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetFolderContentsWebGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Respository', args],
    () => getFolderContentsWebGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Respositories']),
    }
  );
};

export const FetchGetFolderContentsWebGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  folder_content_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFolderContentsWebGET(
    { folder_content_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetFolderContentsWeb: refetch,
  });
};

export const getFoldersByProgramIDGET = (
  Constants,
  { program_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/program/folders/ids/${
      typeof program_id === 'string'
        ? program_id
        : JSON.stringify(program_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetFoldersByProgramIDGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Respositories', args],
    () => getFoldersByProgramIDGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetFoldersByProgramIDGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  program_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetFoldersByProgramIDGET(
    { program_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetFoldersByProgramID: refetch,
  });
};

export const getJobTimelineGET = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/job_timeline/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetJobTimelineGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Profiles', args],
    () => getJobTimelineGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetJobTimelineGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetJobTimelineGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetJobTimeline: refetch });
};

export const getNextVideoNumberGET = (
  Constants,
  { folder_id, order_number },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/folder_content/folder/${
      typeof folder_id === 'string'
        ? folder_id
        : JSON.stringify(folder_id ?? '')
    }/${
      typeof order_number === 'string'
        ? order_number
        : JSON.stringify(order_number ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetNextVideoNumberGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Respository', args],
    () => getNextVideoNumberGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Respositories']),
    }
  );
};

export const FetchGetNextVideoNumberGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  folder_id,
  order_number,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetNextVideoNumberGET(
    { folder_id, order_number },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetNextVideoNumber: refetch });
};

export const getNextVideoNumberInSubGET = (
  Constants,
  { order_number, sub_folder_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/folder_content/subfolder/${
      typeof sub_folder_id === 'string'
        ? sub_folder_id
        : JSON.stringify(sub_folder_id ?? '')
    }/${
      typeof order_number === 'string'
        ? order_number
        : JSON.stringify(order_number ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetNextVideoNumberInSubGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Respository', args],
    () => getNextVideoNumberInSubGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Respositories']),
    }
  );
};

export const FetchGetNextVideoNumberInSubGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  order_number,
  sub_folder_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetNextVideoNumberInSubGET(
    { order_number, sub_folder_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetNextVideoNumberInSub: refetch,
  });
};

export const getNotificationsGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/notifications`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetNotificationsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Notifications', args],
    () => getNotificationsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetNotificationsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetNotificationsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetNotifications: refetch });
};

export const getOpportunitiesGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/opportunities`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetOpportunitiesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Opportunities', args],
    () => getOpportunitiesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetOpportunitiesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetOpportunitiesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetOpportunities: refetch });
};

export const getOpportunityDetailGET = (
  Constants,
  { opportunity_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/opportunities/${
      typeof opportunity_id === 'string'
        ? opportunity_id
        : JSON.stringify(opportunity_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetOpportunityDetailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Opportunity', args],
    () => getOpportunityDetailGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Opportunities']),
    }
  );
};

export const FetchGetOpportunityDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  opportunity_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetOpportunityDetailGET(
    { opportunity_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetOpportunityDetail: refetch,
  });
};

export const getOwnedProgramsGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/owned/programs`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetOwnedProgramsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Programs', args],
    () => getOwnedProgramsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetOwnedProgramsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetOwnedProgramsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetOwnedPrograms: refetch });
};

export const getPostGET = (Constants, { post_id }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/feedposts/${
      typeof post_id === 'string' ? post_id : JSON.stringify(post_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetPostGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['SinglePost', args],
    () => getPostGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['SinglePosts']),
    }
  );
};

export const FetchGetPostGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  post_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPostGET(
    { post_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPost: refetch });
};

export const getProgramFoldersGET = (
  Constants,
  { program_name },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/program/folders/${
      typeof program_name === 'string'
        ? program_name
        : JSON.stringify(program_name ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetProgramFoldersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Programs', args],
    () => getProgramFoldersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetProgramFoldersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  program_name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetProgramFoldersGET(
    { program_name },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetProgramFolders: refetch });
};

export const getProgramIDByNameGET = (
  Constants,
  { program_name },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/program/folders/id/${
      typeof program_name === 'string'
        ? program_name
        : JSON.stringify(program_name ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetProgramIDByNameGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Program', args],
    () => getProgramIDByNameGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Programs']),
    }
  );
};

export const FetchGetProgramIDByNameGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  program_name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetProgramIDByNameGET(
    { program_name },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetProgramIDByName: refetch });
};

export const getProgramListGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/all/programs`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetProgramListGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Programs', args],
    () => getProgramListGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetProgramListGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetProgramListGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetProgramList: refetch });
};

export const getRecentUserPostsGET = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/recent/news_feed_content/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetRecentUserPostsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Feeds', args],
    () => getRecentUserPostsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetRecentUserPostsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetRecentUserPostsGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetRecentUserPosts: refetch });
};

export const getScoresGET = (Constants, { leagues }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/livescores?leagues=${encodeURIComponent(
      `${typeof leagues === 'string' ? leagues : JSON.stringify(leagues ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetScoresGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Scoreboards', args],
    () => getScoresGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetScoresGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  leagues,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetScoresGET(
    { leagues },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetScores: refetch });
};

export const getSharedFoldersGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/shared/folders`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetSharedFoldersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Respositories', args],
    () => getSharedFoldersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetSharedFoldersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSharedFoldersGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetSharedFolders: refetch });
};

export const getStoriesGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/live_stories`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetStoriesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Live Stories', args],
    () => getStoriesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetStoriesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetStoriesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetStories: refetch });
};

export const getSubFolderContentsGET = (
  Constants,
  { sub_folder_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/folder_content/sub_folder/${
      typeof sub_folder_id === 'string'
        ? sub_folder_id
        : JSON.stringify(sub_folder_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetSubFolderContentsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Respositories', args],
    () => getSubFolderContentsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetSubFolderContentsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  sub_folder_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSubFolderContentsGET(
    { sub_folder_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetSubFolderContents: refetch,
  });
};

export const getUnownedProgramsGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/unowned/programs`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetUnownedProgramsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Programs', args],
    () => getUnownedProgramsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetUnownedProgramsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUnownedProgramsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUnownedPrograms: refetch });
};

export const getUserDetailGET = (Constants, { user_id }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/user/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetUserDetailGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Users', args],
    () => getUserDetailGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetUserDetailGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUserDetailGET(
    { user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUserDetail: refetch });
};

export const getUserFolderContentsGET = (
  Constants,
  { folder_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/folder_content/folder/${
      typeof folder_id === 'string'
        ? folder_id
        : JSON.stringify(folder_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetUserFolderContentsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Respositories', args],
    () => getUserFolderContentsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetUserFolderContentsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  folder_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUserFolderContentsGET(
    { folder_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetUserFolderContents: refetch,
  });
};

export const getUserFoldersGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/folders`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetUserFoldersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Respositories', args],
    () => getUserFoldersGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetUserFoldersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUserFoldersGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUserFolders: refetch });
};

export const loginPOST = (Constants, { email, password }, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/auth/login`, {
    body: JSON.stringify({ email: email, password: password }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useLoginPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['risingCoachesLoginPOST', args],
    () => loginPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['risingCoachesLoginPOSTS']),
    }
  );
};

export const FetchLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useLoginPOST(
    { email, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLogin: refetch });
};

export const removeApplicationDELETE = (
  Constants,
  { applicant_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/applicants/${
      typeof applicant_id === 'string'
        ? applicant_id
        : JSON.stringify(applicant_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useRemoveApplicationDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      removeApplicationDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Opportunities', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Opportunity');
        queryClient.invalidateQueries('Opportunities');
      },
    }
  );
};

export const removeLikeDELETE = (Constants, { post_id }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/likes/${
      typeof post_id === 'string' ? post_id : JSON.stringify(post_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useRemoveLikeDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => removeLikeDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Likes', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Like');
        queryClient.invalidateQueries('Likes');
      },
    }
  );
};

export const saveFeedToFolderPOST = (
  Constants,
  {
    content,
    content_title,
    file_content,
    folder_id,
    isFile,
    isImage,
    isShared,
    isVideo,
    tags,
    text_content,
    uploader_id,
    youtubeId,
  },
  handlers = {}
) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/clone/folder_content`, {
    body: JSON.stringify({
      folders_id: folder_id,
      content_title: content_title,
      tags: tags,
      text_content: text_content,
      isShared: isShared,
      youtubeId: youtubeId,
      isVideo: isVideo,
      isFile: isFile,
      isImage: isImage,
      content: content,
      file_content: file_content,
      user_id: uploader_id,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useSaveFeedToFolderPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      saveFeedToFolderPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Feed', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Feed');
        queryClient.invalidateQueries('Feeds');
      },
    }
  );
};

export const FetchSaveFeedToFolderPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  content,
  content_title,
  file_content,
  folder_id,
  isFile,
  isImage,
  isShared,
  isVideo,
  tags,
  text_content,
  uploader_id,
  youtubeId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSaveFeedToFolderPOST(
    {
      content,
      content_title,
      file_content,
      folder_id,
      isFile,
      isImage,
      isShared,
      isVideo,
      tags,
      text_content,
      uploader_id,
      youtubeId,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSaveFeedToFolder: refetch });
};

export const searchDirectoryGET = (Constants, { input }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/search/coaches_directory?search=${encodeURIComponent(
      `${typeof input === 'string' ? input : JSON.stringify(input ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useSearchDirectoryGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Directories', args],
    () => searchDirectoryGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchSearchDirectoryGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  input,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSearchDirectoryGET(
    { input },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSearchDirectory: refetch });
};

export const unfollowUserDELETE = (Constants, { followed_id }, handlers = {}) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/follows/${
      typeof followed_id === 'string'
        ? followed_id
        : JSON.stringify(followed_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useUnfollowUserDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      unfollowUserDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Follows', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Follow');
        queryClient.invalidateQueries('Follows');
      },
    }
  );
};

export const updateContentPUT = (
  Constants,
  { editedTags, editedTitle, folder_content_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/update/folder_content/${
      typeof folder_content_id === 'string'
        ? folder_content_id
        : JSON.stringify(folder_content_id ?? '')
    }`,
    {
      body: JSON.stringify({
        folder_content_id: folder_content_id,
        editedTitle: editedTitle,
        editedTags: editedTags,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  ).then(res => handleResponse(res, handlers));

export const useUpdateContentPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateContentPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Respository', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Respository');
        queryClient.invalidateQueries('Respositories');
      },
    }
  );
};

export const updateProfilePhotoPOST = (
  Constants,
  {
    alma_mater,
    bio,
    content,
    current_uni,
    email,
    handle,
    job_title,
    name,
    user_id,
  },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/user/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      body: JSON.stringify({
        user_id: user_id,
        handle: handle,
        name: name,
        email: email,
        job_title: job_title,
        current_university: current_uni,
        alma_mater: alma_mater,
        bio: bio,
        content: content,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useUpdateProfilePhotoPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateProfilePhotoPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Profile', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Profile');
        queryClient.invalidateQueries('Profiles');
      },
    }
  );
};

export const FetchUpdateProfilePhotoPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  alma_mater,
  bio,
  content,
  current_uni,
  email,
  handle,
  job_title,
  name,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateProfilePhotoPOST(
    {
      alma_mater,
      bio,
      content,
      current_uni,
      email,
      handle,
      job_title,
      name,
      user_id,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdateProfilePhoto: refetch });
};

export const updatePushTokenPOST = (
  Constants,
  { expo_push_token, user_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/push/user/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      body: JSON.stringify({
        user_id: user_id,
        expo_push_token: expo_push_token,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useUpdatePushTokenPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updatePushTokenPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const FetchUpdatePushTokenPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  expo_push_token,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdatePushTokenPOST(
    { expo_push_token, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdatePushToken: refetch });
};

export const updateResumePOST = (
  Constants,
  { file_base_64, user_id },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/user/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }/resume`,
    {
      body: JSON.stringify({ resume_content: file_base_64 }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useUpdateResumePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateResumePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Profile', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Profile');
        queryClient.invalidateQueries('Profiles');
      },
    }
  );
};

export const FetchUpdateResumePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  file_base_64,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateResumePOST(
    { file_base_64, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdateResume: refetch });
};

export const updateUserPOST = (
  Constants,
  {
    alma_mater,
    bio,
    birthday,
    current_university,
    email,
    handle,
    isOnboarded,
    job_title,
    linkedin,
    name,
    photo_file,
    twitter,
    university_bio_link,
    user_id,
  },
  handlers = {}
) =>
  fetch(
    `https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/user/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }`,
    {
      body: JSON.stringify({
        user_id: 17,
        handle: handle,
        name: name,
        email: email,
        job_title: job_title,
        current_university: current_university,
        alma_mater: alma_mater,
        bio: bio,
        content: photo_file,
        twitter_link: twitter,
        linkedin_link: linkedin,
        university_bio_link: university_bio_link,
        birthday: birthday,
        isOnboarded: isOnboarded,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: Constants['auth_header'],
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useUpdateUserPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateUserPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('User');
        queryClient.invalidateQueries('Users');
      },
    }
  );
};

export const FetchUpdateUserPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  alma_mater,
  bio,
  birthday,
  current_university,
  email,
  handle,
  isOnboarded,
  job_title,
  linkedin,
  name,
  photo_file,
  twitter,
  university_bio_link,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateUserPOST(
    {
      alma_mater,
      bio,
      birthday,
      current_university,
      email,
      handle,
      isOnboarded,
      job_title,
      linkedin,
      name,
      photo_file,
      twitter,
      university_bio_link,
      user_id,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdateUser: refetch });
};

export const getProgramsGET = (Constants, _args, handlers = {}) =>
  fetch(`https://xjz0-jmv4-gghx.n7.xano.io/api:wm-WeY32/programs`, {
    headers: {
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
    },
  }).then(res => handleResponse(res, handlers));

export const useGetProgramsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Programs', args],
    () => getProgramsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetProgramsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetProgramsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPrograms: refetch });
};
