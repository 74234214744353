import { systemWeights } from 'react-native-typography';
export default {
  disabledOpacity: 0.5,
  roundness: 6,
  colors: {
    Background: 'rgb(244, 247, 250)',
    'Card BG': 'rgb(244, 247, 250)',
    'Custom #ffffff': 'rgb(255, 255, 255)',
    'Custom Color': 'rgb(206, 206, 206)',
    'Custom Color_2': 'rgb(6, 166, 228)',
    'Custom Color_3': 'rgb(1, 15, 42)',
    'Custom Color_4': 'rgb(155, 155, 155)',
    Custom_Color: 'rgb(255, 255, 255)',
    Darken: 'rgb(0, 0, 0)',
    Darker_Pink: 'rgb(228, 60, 92)',
    Divider: 'rgba(234, 237, 242, 1)',
    Error: 'rgba(255, 69, 100, 1)',
    Golden: 'rgb(252, 216, 109)',
    Gray_Background: 'rgb(246, 246, 250)',
    Light: 'rgb(153, 153, 153)',
    'Light Inverse': 'rgba(255, 255, 255, 0.68)',
    Light_Inverse: 'rgba(255, 255, 255, 0.68)',
    Lighter_Pink: 'rgb(255, 110, 139)',
    Lightest: 'rgb(217, 219, 236)',
    Medium: 'rgb(68, 68, 68)',
    'Medium Inverse': 'rgba(255, 255, 255, 0.87)',
    Medium_Inverse: 'rgba(255, 255, 255, 0.87)',
    NFT_TIME_Black: 'rgb(0, 0, 0)',
    NFT_TIME_Blue: 'rgb(33, 158, 188)',
    NFT_TIME_Border: 'rgb(153, 153, 153)',
    NFT_TIME_Cyan_White: 'rgb(233, 236, 239)',
    NFT_TIME_Dark_Gray: 'rgb(64, 72, 82)',
    NFT_TIME_Dark_White: 'rgb(241, 241, 241)',
    NFT_TIME_Divider_Dark: 'rgb(151, 151, 151)',
    NFT_TIME_Gray: 'rgb(111, 111, 111)',
    NFT_TIME_Icons: 'rgb(86, 86, 86)',
    NFT_TIME_Light_Blue: 'rgb(142, 202, 230)',
    NFT_TIME_Light_Gray: 'rgb(223, 223, 223)',
    NFT_TIME_Lime_Green: 'rgb(221, 242, 71)',
    NFT_TIME_Primary_Black: 'rgb(16, 16, 1)',
    NFT_TIME_Red: 'rgb(255, 0, 0)',
    NFT_TIME_Stone: 'rgb(207, 207, 207)',
    NFT_TIME_Stone_Gray: 'rgb(183, 183, 183)',
    NFT_Time_Dark_Mode_Link: 'rgb(252, 252, 252)',
    NFT_Time_Green: 'rgb(0, 128, 0)',
    NFT_Time_Hot_Blue: 'rgb(0, 0, 255)',
    NFT_Time_Mac_Gray: 'rgb(128, 128, 128)',
    NFT_Time_Secondary: 'rgb(71, 129, 242)',
    NFT_Time_UI_Black: 'rgb(16, 16, 16)',
    NFT_White_v2: 'rgb(255, 255, 255)',
    'PDF Grey': 'rgb(51, 54, 57)',
    Primary: 'rgb(1, 15, 42)',
    Secondary: 'rgb(0, 187, 238)',
    Strong: 'rgba(18, 20, 44, 1)',
    'Strong Inverse': 'rgba(255, 255, 255, 1)',
    Strong_Inverse: 'rgba(255, 255, 255, 1)',
    Surface: 'rgba(255, 255, 255, 1)',
    Transparent: 'rgba(255, 255, 255, 0)',
    background: 'rgb(244, 247, 250)',
    cardBG: 'rgb(244, 247, 250)',
    custom_rgb155_155_155: 'rgb(155, 155, 155)',
    custom_rgb1_15_42: 'rgb(1, 15, 42)',
    custom_rgb206_206_206: 'rgb(206, 206, 206)',
    custom_rgb255_255_255: 'rgb(255, 255, 255)',
    custom_rgb6_166_228: 'rgb(6, 166, 228)',
    darken: 'rgb(0, 0, 0)',
    darkerPink: 'rgb(228, 60, 92)',
    divider: 'rgba(234, 237, 242, 1)',
    error: 'rgba(255, 69, 100, 1)',
    golden: 'rgb(252, 216, 109)',
    grayBackground: 'rgb(246, 246, 250)',
    light: 'rgb(153, 153, 153)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    lighterPink: 'rgb(255, 110, 139)',
    lightest: 'rgb(217, 219, 236)',
    medium: 'rgb(68, 68, 68)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    nFTTIMEBlack: 'rgb(0, 0, 0)',
    nFTTIMEBlue: 'rgb(33, 158, 188)',
    nFTTIMEBorder: 'rgb(153, 153, 153)',
    nFTTIMECyanWhite: 'rgb(233, 236, 239)',
    nFTTIMEDarkGray: 'rgb(64, 72, 82)',
    nFTTIMEDarkWhite: 'rgb(241, 241, 241)',
    nFTTIMEDividerDark: 'rgb(151, 151, 151)',
    nFTTIMEGray: 'rgb(111, 111, 111)',
    nFTTIMEIcons: 'rgb(86, 86, 86)',
    nFTTIMELightBlue: 'rgb(142, 202, 230)',
    nFTTIMELightGray: 'rgb(223, 223, 223)',
    nFTTIMELimeGreen: 'rgb(221, 242, 71)',
    nFTTIMEPrimaryBlack: 'rgb(16, 16, 1)',
    nFTTIMERed: 'rgb(255, 0, 0)',
    nFTTIMEStone: 'rgb(207, 207, 207)',
    nFTTIMEStoneGray: 'rgb(183, 183, 183)',
    nFTTimeDarkModeLink: 'rgb(252, 252, 252)',
    nFTTimeGreen: 'rgb(0, 128, 0)',
    nFTTimeHotBlue: 'rgb(0, 0, 255)',
    nFTTimeMacGray: 'rgb(128, 128, 128)',
    nFTTimeSecondary: 'rgb(71, 129, 242)',
    nFTTimeUIBlack: 'rgb(16, 16, 16)',
    nFTWhiteV2: 'rgb(255, 255, 255)',
    pDFGrey: 'rgb(51, 54, 57)',
    primary: 'rgb(1, 15, 42)',
    secondary: 'rgb(0, 187, 238)',
    strong: 'rgba(18, 20, 44, 1)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    surface: 'rgba(255, 255, 255, 1)',
    transparent: 'rgba(255, 255, 255, 0)',
  },
  typography: {
    body1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    body2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
    button: {
      ...systemWeights.bold,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 16,
    },
    caption: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: 16,
    },
    headline1: {
      ...systemWeights.bold,
      fontSize: 60,
      letterSpacing: 0,
      lineHeight: 71,
    },
    headline2: {
      ...systemWeights.bold,
      fontSize: 48,
      letterSpacing: 0,
      lineHeight: 58,
    },
    headline3: {
      ...systemWeights.bold,
      fontSize: 34,
      letterSpacing: 0,
      lineHeight: 40,
    },
    headline4: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: 34,
    },
    headline5: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 26,
    },
    headline6: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 24,
    },
    overline: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 2,
      lineHeight: 16,
    },
    subtitle1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    subtitle2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
  },
};
