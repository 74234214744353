import React from 'react';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import {
  Button,
  CircleImage,
  DatePicker,
  IconButton,
  Link,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  KeyboardAvoidingView,
  Modal,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const OnboardingScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const risingCoachesUpdateUserPOST = RisingCoachesApi.useUpdateUserPOST();
  const risingCoachesDELETEACCOUNTDELETE =
    RisingCoachesApi.useDELETEACCOUNTDELETE();

  const [almaMaterInput, setAlmaMaterInput] = React.useState('');
  const [bioInput, setBioInput] = React.useState('');
  const [birthdayInput, setBirthdayInput] = React.useState('1991-11-28');
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [fourActive, setFourActive] = React.useState(false);
  const [imageBase, setImageBase] = React.useState('');
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);
  const [linkedInInput, setLinkedInInput] = React.useState('');
  const [oneActive, setOneActive] = React.useState(false);
  const [positionInput, setPositionInput] = React.useState('');
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [threeActive, setThreeActive] = React.useState(false);
  const [twitterInput, setTwitterInput] = React.useState('');
  const [twoActive, setTwoActive] = React.useState(true);
  const [uniBioInput, setUniBioInput] = React.useState('');
  const [universityInput, setUniversityInput] = React.useState('');

  return (
    <ScreenContainer hasTopSafeArea={true}>
      <RisingCoachesApi.FetchGetUserDetailGET
        handlers={{
          onData: fetchData => {
            try {
              setUniversityInput(fetchData?.current_university);
              setAlmaMaterInput(fetchData?.alma_mater);
              setBioInput(fetchData?.bio);
              setPositionInput(fetchData?.job_title);
              setBirthdayInput(fetchData?.birthday);
              setLinkedInInput(fetchData?.linkedin_link);
              setTwitterInput(fetchData?.twitter_link);
              setUniBioInput(fetchData?.university_bio_link);
              setImageBase(fetchData?.profile_image?.url);
            } catch (err) {
              console.error(err);
            }
          },
        }}
        user_id={Constants['user_id']}
      >
        {({ loading, error, data, refetchGetUserDetail }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <KeyboardAwareScrollView
              contentContainerStyle={StyleSheet.applyWidth(
                { flex: 1, justifyContent: 'center' },
                dimensions.width
              )}
            >
              <KeyboardAvoidingView behavior={'position'} enabled={true}>
                {/* Step Two */}
                <>
                  {!twoActive ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 24, paddingLeft: 36, paddingRight: 36 },
                        dimensions.width
                      )}
                    >
                      {/* Error Message */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.error,
                            fontSize: 12,
                            marginBottom: 16,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {Constants['error_message']}
                      </Text>

                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              const base64_image = await openImagePickerUtil({
                                mediaTypes: 'Images',
                                allowsEditing: false,
                                quality: 0.2,
                              });

                              setImageBase(base64_image);
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center' },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'Mulish_400Regular',
                              },
                              dimensions.width
                            )}
                          >
                            {'Click to Upload a Profile Picture'}
                          </Text>
                          <Spacer bottom={4} left={8} right={8} top={4} />
                          <>
                            {!imageBase ? null : (
                              <Image
                                resizeMode={'cover'}
                                source={{ uri: `${imageBase}` }}
                                style={StyleSheet.applyWidth(
                                  { height: 250, width: 250 },
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                          <>
                            {imageBase ? null : (
                              <CircleImage
                                size={180}
                                source={Images.UploadIcon}
                              />
                            )}
                          </>
                        </View>
                      </Touchable>
                      <Spacer bottom={8} left={8} right={8} top={12} />
                      {/* Next Button */}
                      <>
                        {Constants['is_loading'] ? null : (
                          <Button
                            onPress={() => {
                              try {
                                setTwoActive(false);
                                setThreeActive(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors.primary,
                                borderRadius: 8,
                                fontFamily: 'System',
                                fontWeight: '700',
                                paddingBottom: 16,
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 16,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                            title={'Next\n'}
                          >
                            {'Sign Up'}
                          </Button>
                        )}
                      </>
                      {/* Next Button Loading */}
                      <>
                        {!Constants['is_loading'] ? null : (
                          <Button
                            disabled={true}
                            loading={true}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors.primary,
                                borderRadius: 8,
                                fontFamily: 'System',
                                fontWeight: '700',
                                paddingBottom: 16,
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 16,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                            title={''}
                          >
                            {'Sign Up'}
                          </Button>
                        )}
                      </>
                      <Spacer bottom={16} left={8} right={8} top={16} />
                      {/* SkipView */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', justifyContent: 'center' },
                          dimensions.width
                        )}
                      >
                        {/* Sign In Link */}
                        <Link
                          onPress={() => {
                            try {
                              setTwoActive(false);
                              setThreeActive(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { color: theme.colors.primary },
                            dimensions.width
                          )}
                          title={'Skip this for now'}
                        />
                      </View>
                    </View>
                  )}
                </>
                {/* Step Three */}
                <>
                  {!threeActive ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 24, paddingLeft: 36, paddingRight: 36 },
                        dimensions.width
                      )}
                    >
                      {/* Error Message */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.error,
                            fontSize: 12,
                            marginBottom: 16,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {Constants['error_message']}
                      </Text>

                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: 'Mulish_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Biography'}
                      </Text>
                      <Spacer bottom={4} left={8} right={8} top={4} />
                      {/* Bio Text Area */}
                      <TextInput
                        multiline={true}
                        onChangeText={newBioTextAreaValue => {
                          try {
                            setBioInput(newBioTextAreaValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Write your bio'}
                        placeholderTextColor={theme.colors.light}
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            borderLeftWidth: 1,
                            borderRadius: 8,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            minHeight: 300,
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 16,
                          },
                          dimensions.width
                        )}
                        value={bioInput}
                      />
                      <Spacer bottom={12} left={8} right={8} top={12} />
                      {/* Next Button */}
                      <>
                        {Constants['is_loading'] ? null : (
                          <Button
                            onPress={() => {
                              try {
                                setThreeActive(false);
                                setFourActive(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors.primary,
                                borderRadius: 8,
                                fontFamily: 'System',
                                fontWeight: '700',
                                paddingBottom: 16,
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 16,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                            title={'Next\n'}
                          >
                            {'Sign Up'}
                          </Button>
                        )}
                      </>
                      {/* Next Button Loading */}
                      <>
                        {!Constants['is_loading'] ? null : (
                          <Button
                            disabled={true}
                            loading={true}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors.primary,
                                borderRadius: 8,
                                fontFamily: 'System',
                                fontWeight: '700',
                                paddingBottom: 16,
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 16,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                            title={''}
                          >
                            {'Sign Up'}
                          </Button>
                        )}
                      </>
                      <Spacer bottom={16} left={8} right={8} top={16} />
                      {/* SkipView */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', justifyContent: 'center' },
                          dimensions.width
                        )}
                      >
                        {/* Sign In Link */}
                        <Link
                          onPress={() => {
                            try {
                              setThreeActive(false);
                              setFourActive(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { color: theme.colors.primary },
                            dimensions.width
                          )}
                          title={'Skip this for now'}
                        />
                      </View>
                    </View>
                  )}
                </>
                {/* Step Four */}
                <>
                  {!fourActive ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 24, paddingLeft: 36, paddingRight: 36 },
                        dimensions.width
                      )}
                    >
                      {/* Next Button */}
                      <>
                        {Constants['is_loading'] ? null : (
                          <Button
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  setGlobalVariableValue({
                                    key: 'is_loading',
                                    value: true,
                                  });
                                  const response = (
                                    await risingCoachesUpdateUserPOST.mutateAsync(
                                      {
                                        alma_mater: almaMaterInput,
                                        bio: bioInput,
                                        birthday: birthdayInput,
                                        current_university: universityInput,
                                        email: fetchData?.email,
                                        handle: fetchData?.handle,
                                        isOnboarded: true,
                                        job_title: positionInput,
                                        linkedin: linkedInInput,
                                        name: fetchData?.name,
                                        photo_file: imageBase,
                                        twitter: twitterInput,
                                        university_bio_link: uniBioInput,
                                        user_id: Constants['user_id'],
                                      }
                                    )
                                  )?.json;
                                  const avatar = response?.profile_image;
                                  setGlobalVariableValue({
                                    key: 'user_image',
                                    value: avatar?.url,
                                  });
                                  navigation.navigate('NewsFeedScreen');
                                  setGlobalVariableValue({
                                    key: 'is_loading',
                                    value: false,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors.primary,
                                borderRadius: 8,
                                fontFamily: 'System',
                                fontWeight: '700',
                                paddingBottom: 16,
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 16,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                            title={'Finish'}
                          >
                            {'Sign Up'}
                          </Button>
                        )}
                      </>
                      {/* Next Button Loading */}
                      <>
                        {!Constants['is_loading'] ? null : (
                          <Button
                            disabled={true}
                            loading={true}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors.primary,
                                borderRadius: 8,
                                fontFamily: 'System',
                                fontWeight: '700',
                                paddingBottom: 16,
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 16,
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                            title={''}
                          >
                            {'Sign Up'}
                          </Button>
                        )}
                      </>
                    </View>
                  )}
                </>
              </KeyboardAvoidingView>
            </KeyboardAwareScrollView>
          );
        }}
      </RisingCoachesApi.FetchGetUserDetailGET>
      <>
        {!true ? null : (
          <Modal
            animationType={'slide'}
            presentationStyle={'fullScreen'}
            visible={isDeleteModal}
          >
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flex: 1, justifyContent: 'center' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { marginLeft: 20, marginRight: 20 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Error'],
                      fontFamily: 'Manrope_700Bold',
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {
                    'ARE YOU SURE YOU WANT TO DELETE YOUR ACCOUNT? THIS ACTION IS PERMANENT AND CANNOT BE REVERSED. '
                  }
                </Text>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    marginTop: 30,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <Button
                  onPress={() => {
                    try {
                      setIsDeleteModal(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors.primary,
                      borderRadius: 8,
                      fontFamily: 'System',
                      fontWeight: '700',
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={'CANCEL'}
                />
                <Button
                  onPress={() => {
                    const handler = async () => {
                      try {
                        navigation.navigate('WelcomeScreen');
                        setGlobalVariableValue({
                          key: 'auth_header',
                          value: '',
                        });
                        (
                          await risingCoachesDELETEACCOUNTDELETE.mutateAsync({
                            user_id: Constants['user_id'],
                          })
                        )?.json;
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Error'],
                      borderRadius: 8,
                      fontFamily: 'System',
                      fontWeight: '700',
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={'DELETE ACCOUNT'}
                />
              </View>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(OnboardingScreen);
