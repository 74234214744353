import React from 'react';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import formatDate from '../global-functions/formatDate';
import isEmpty from '../global-functions/isEmpty';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  CircleImage,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const NotificationsScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const risingCoachesDeleteNotificationDELETE =
    RisingCoachesApi.useDeleteNotificationDELETE();

  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [menuTab1, setMenuTab1] = React.useState(true);
  const [menuTab2, setMenuTab2] = React.useState(false);
  const [menuTab3, setMenuTab3] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
    >
      {/* Scroll Content Frame */}
      <ScrollView
        bounces={true}
        showsVerticalScrollIndicator={true}
        style={StyleSheet.applyWidth({ flexGrow: 1 }, dimensions.width)}
        contentContainerStyle={StyleSheet.applyWidth(
          { flexShrink: 0 },
          dimensions.width
        )}
      >
        {/* Following List Frame */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 12 }, dimensions.width)}
        >
          {/* List Content Frame */}
          <View>
            <RisingCoachesApi.FetchGetNotificationsGET>
              {({ loading, error, data, refetchGetNotifications }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    <>
                      {!isEmpty(fetchData?.itemsReceived) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignContent: 'center',
                              alignItems: 'center',
                              alignSelf: 'center',
                              justifyContent: 'center',
                              marginTop: 100,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              { color: theme.colors.light },
                              dimensions.width
                            )}
                          >
                            {"You don't have any notifications right now."}
                          </Text>
                        </View>
                      )}
                    </>
                    <FlatList
                      contentContainerStyle={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                      data={fetchData?.items}
                      keyExtractor={listData => listData?.id}
                      listKey={'8ko1U5wk'}
                      numColumns={1}
                      renderItem={({ item }) => {
                        const listData = item;
                        return (
                          <>
                            {/* isComment Frame */}
                            <>
                              {!listData?.isComment ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginTop: 12 },
                                    dimensions.width
                                  )}
                                >
                                  {/* Flex Touchable */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginBottom: 12,
                                        marginLeft: 12,
                                        marginRight: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Touchable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            navigation.navigate(
                                              'PostDetailsScreen',
                                              {
                                                feed_post_id:
                                                  listData?.content_reference_id,
                                              }
                                            );
                                            (
                                              await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                {
                                                  notifications_id:
                                                    listData?.id,
                                                }
                                              )
                                            )?.json;
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      {/* Record Item Frame */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: theme.colors.divider,
                                            borderLeftWidth: 1,
                                            borderRadius: 12,
                                            borderRightWidth: 1,
                                            borderTopWidth: 1,
                                            flexDirection: 'row',
                                            flexGrow: 0,
                                            flexShrink: 0,
                                            paddingBottom: 12,
                                            paddingTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Left Side Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              borderBottomLeftRadius: 12,
                                              borderTopLeftRadius: 12,
                                              flexGrow: 0,
                                              flexShrink: 0,
                                              justifyContent: 'center',
                                              paddingLeft: 12,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Review Image */}
                                          <CircleImage
                                            size={64}
                                            source={{
                                              uri: `${
                                                listData &&
                                                listData['_sender_details']
                                                  ?.profile_image?.url
                                              }`,
                                            }}
                                          />
                                        </View>
                                        {/* Middle Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flexGrow: 1,
                                              flexShrink: 0,
                                              justifyContent: 'center',
                                              marginLeft: 18,
                                              maxWidth: 200,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Top Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { marginRight: 12 },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Record Name */}
                                            <Text
                                              ellipsizeMode={'tail'}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily:
                                                    'OpenSans_700Bold',
                                                  paddingBottom: 9,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listData &&
                                                listData['_sender_details']
                                                  ?.name}
                                            </Text>
                                          </View>
                                          {/* Second Line Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                flexGrow: 0,
                                                flexShrink: 0,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Time */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors
                                                      .custom_rgb153_153_153,
                                                  fontFamily:
                                                    'OpenSans_600SemiBold',
                                                  fontSize: 10,
                                                  lineHeight: 16,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {formatDate(listData?.created_at)}
                                            </Text>
                                          </View>
                                          {/* Third Line Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginTop: 6,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Data Point */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily:
                                                    'OpenSans_400Regular',
                                                  fontSize: 12,
                                                  lineHeight: 18,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Message}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* Right Side Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'center',
                                              alignItems: 'center',
                                              alignSelf: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Touchable
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: true,
                                                  });
                                                  (
                                                    await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                      {
                                                        notifications_id:
                                                          listData?.id,
                                                      }
                                                    )
                                                  )?.json;
                                                  await refetchGetNotifications();
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: false,
                                                  });
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                          >
                                            {/* Badge Frame */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignContent: 'center',
                                                  alignItems: 'center',
                                                  alignSelf: 'center',
                                                  backgroundColor:
                                                    theme.colors
                                                      .custom_rgb97_116_246,
                                                  borderRadius: 12,
                                                  flexDirection: 'row',
                                                  flexGrow: 0,
                                                  flexShrink: 0,
                                                  justifyContent: 'center',
                                                  paddingBottom: 6,
                                                  paddingLeft: 6,
                                                  paddingRight: 6,
                                                  paddingTop: 6,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Icon Frame */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { marginRight: 3 },
                                                  dimensions.width
                                                )}
                                              >
                                                <Icon
                                                  color={theme.colors.medium}
                                                  name={'MaterialIcons/clear'}
                                                  size={24}
                                                />
                                              </View>
                                            </View>
                                          </Touchable>
                                        </View>
                                      </View>
                                    </Touchable>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* isLiked */}
                            <>
                              {!listData?.isLike ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginTop: 12 },
                                    dimensions.width
                                  )}
                                >
                                  {/* Flex Touchable */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginBottom: 12,
                                        marginLeft: 12,
                                        marginRight: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Touchable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            navigation.navigate(
                                              'PostDetailsScreen',
                                              {
                                                feed_post_id:
                                                  listData?.content_reference_id,
                                              }
                                            );
                                            (
                                              await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                {
                                                  notifications_id:
                                                    listData?.id,
                                                }
                                              )
                                            )?.json;
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      {/* Record Item Frame */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: theme.colors.divider,
                                            borderLeftWidth: 1,
                                            borderRadius: 12,
                                            borderRightWidth: 1,
                                            borderTopWidth: 1,
                                            flexDirection: 'row',
                                            flexGrow: 0,
                                            flexShrink: 0,
                                            paddingBottom: 12,
                                            paddingTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Left Side Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              borderBottomLeftRadius: 12,
                                              borderTopLeftRadius: 12,
                                              flexGrow: 0,
                                              flexShrink: 0,
                                              justifyContent: 'center',
                                              paddingLeft: 12,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Review Image */}
                                          <CircleImage
                                            size={64}
                                            source={{
                                              uri: `${
                                                listData &&
                                                listData['_sender_details']
                                                  ?.profile_image?.url
                                              }`,
                                            }}
                                          />
                                        </View>
                                        {/* Middle Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flexGrow: 1,
                                              flexShrink: 0,
                                              justifyContent: 'center',
                                              marginLeft: 18,
                                              maxWidth: 200,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Top Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { marginRight: 12 },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Record Name */}
                                            <Text
                                              ellipsizeMode={'tail'}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily:
                                                    'OpenSans_700Bold',
                                                  paddingBottom: 9,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listData &&
                                                listData['_sender_details']
                                                  ?.name}
                                            </Text>
                                          </View>
                                          {/* Second Line Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                flexGrow: 0,
                                                flexShrink: 0,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Time */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors
                                                      .custom_rgb153_153_153,
                                                  fontFamily:
                                                    'OpenSans_600SemiBold',
                                                  fontSize: 10,
                                                  lineHeight: 16,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {formatDate(listData?.created_at)}
                                            </Text>
                                          </View>
                                          {/* Third Line Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginTop: 6,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Data Point */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily:
                                                    'OpenSans_400Regular',
                                                  fontSize: 12,
                                                  lineHeight: 18,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Message}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* Right Side Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'center',
                                              alignItems: 'center',
                                              alignSelf: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Touchable
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: true,
                                                  });
                                                  (
                                                    await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                      {
                                                        notifications_id:
                                                          listData?.id,
                                                      }
                                                    )
                                                  )?.json;
                                                  await refetchGetNotifications();
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: false,
                                                  });
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                          >
                                            {/* Badge Frame */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignContent: 'center',
                                                  alignItems: 'center',
                                                  alignSelf: 'center',
                                                  backgroundColor:
                                                    theme.colors
                                                      .custom_rgb97_116_246,
                                                  borderRadius: 12,
                                                  flexDirection: 'row',
                                                  flexGrow: 0,
                                                  flexShrink: 0,
                                                  justifyContent: 'center',
                                                  paddingBottom: 6,
                                                  paddingLeft: 6,
                                                  paddingRight: 6,
                                                  paddingTop: 6,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Icon Frame */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { marginRight: 3 },
                                                  dimensions.width
                                                )}
                                              >
                                                <Icon
                                                  color={theme.colors.medium}
                                                  name={'MaterialIcons/clear'}
                                                  size={24}
                                                />
                                              </View>
                                            </View>
                                          </Touchable>
                                        </View>
                                      </View>
                                    </Touchable>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* isNewPost */}
                            <>
                              {!listData?.isNewPost ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginTop: 12 },
                                    dimensions.width
                                  )}
                                >
                                  {/* Flex Touchable */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginBottom: 12,
                                        marginLeft: 12,
                                        marginRight: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Touchable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            navigation.navigate(
                                              'PostDetailsScreen',
                                              {
                                                feed_post_id:
                                                  listData?.content_reference_id,
                                              }
                                            );
                                            (
                                              await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                {
                                                  notifications_id:
                                                    listData?.id,
                                                }
                                              )
                                            )?.json;
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      {/* Record Item Frame */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: theme.colors.divider,
                                            borderLeftWidth: 1,
                                            borderRadius: 12,
                                            borderRightWidth: 1,
                                            borderTopWidth: 1,
                                            flexDirection: 'row',
                                            flexGrow: 0,
                                            flexShrink: 0,
                                            paddingBottom: 12,
                                            paddingTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Left Side Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              borderBottomLeftRadius: 12,
                                              borderTopLeftRadius: 12,
                                              flexGrow: 0,
                                              flexShrink: 0,
                                              justifyContent: 'center',
                                              paddingLeft: 12,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Review Image */}
                                          <CircleImage
                                            size={64}
                                            source={{
                                              uri: `${
                                                listData &&
                                                listData['_sender_details']
                                                  ?.profile_image?.url
                                              }`,
                                            }}
                                          />
                                        </View>
                                        {/* Middle Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flexGrow: 1,
                                              flexShrink: 0,
                                              justifyContent: 'center',
                                              marginLeft: 18,
                                              maxWidth: 200,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Top Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { marginRight: 12 },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Record Name */}
                                            <Text
                                              ellipsizeMode={'tail'}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily:
                                                    'OpenSans_700Bold',
                                                  paddingBottom: 9,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listData &&
                                                listData['_sender_details']
                                                  ?.name}
                                            </Text>
                                          </View>
                                          {/* Second Line Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                flexGrow: 0,
                                                flexShrink: 0,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Time */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors
                                                      .custom_rgb153_153_153,
                                                  fontFamily:
                                                    'OpenSans_600SemiBold',
                                                  fontSize: 10,
                                                  lineHeight: 16,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {formatDate(listData?.created_at)}
                                            </Text>
                                          </View>
                                          {/* Third Line Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginTop: 6,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Data Point */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily:
                                                    'OpenSans_400Regular',
                                                  fontSize: 12,
                                                  lineHeight: 18,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Message}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* Right Side Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'center',
                                              alignItems: 'center',
                                              alignSelf: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Touchable
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: true,
                                                  });
                                                  (
                                                    await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                      {
                                                        notifications_id:
                                                          listData?.id,
                                                      }
                                                    )
                                                  )?.json;
                                                  await refetchGetNotifications();
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: false,
                                                  });
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                          >
                                            {/* Badge Frame */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignContent: 'center',
                                                  alignItems: 'center',
                                                  alignSelf: 'center',
                                                  backgroundColor:
                                                    theme.colors
                                                      .custom_rgb97_116_246,
                                                  borderRadius: 12,
                                                  flexDirection: 'row',
                                                  flexGrow: 0,
                                                  flexShrink: 0,
                                                  justifyContent: 'center',
                                                  paddingBottom: 6,
                                                  paddingLeft: 6,
                                                  paddingRight: 6,
                                                  paddingTop: 6,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Icon Frame */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { marginRight: 3 },
                                                  dimensions.width
                                                )}
                                              >
                                                <Icon
                                                  color={theme.colors.medium}
                                                  name={'MaterialIcons/clear'}
                                                  size={24}
                                                />
                                              </View>
                                            </View>
                                          </Touchable>
                                        </View>
                                      </View>
                                    </Touchable>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* isSharedFolder */}
                            <>
                              {!listData?.isShared ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginTop: 12 },
                                    dimensions.width
                                  )}
                                >
                                  {/* Flex Touchable */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginBottom: 12,
                                        marginLeft: 12,
                                        marginRight: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Touchable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            navigation.navigate(
                                              'ProgramsScreen'
                                            );
                                            (
                                              await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                {
                                                  notifications_id:
                                                    listData?.id,
                                                }
                                              )
                                            )?.json;
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      {/* Record Item Frame */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: theme.colors.divider,
                                            borderLeftWidth: 1,
                                            borderRadius: 12,
                                            borderRightWidth: 1,
                                            borderTopWidth: 1,
                                            flexDirection: 'row',
                                            flexGrow: 0,
                                            flexShrink: 0,
                                            paddingBottom: 12,
                                            paddingTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Left Side Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              borderBottomLeftRadius: 12,
                                              borderTopLeftRadius: 12,
                                              flexGrow: 0,
                                              flexShrink: 0,
                                              justifyContent: 'center',
                                              paddingLeft: 12,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Review Image */}
                                          <CircleImage
                                            size={64}
                                            source={{
                                              uri: `${
                                                listData &&
                                                listData['_sender_details']
                                                  ?.profile_image?.url
                                              }`,
                                            }}
                                          />
                                        </View>
                                        {/* Middle Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flexGrow: 1,
                                              flexShrink: 0,
                                              justifyContent: 'center',
                                              marginLeft: 18,
                                              maxWidth: 200,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Top Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { marginRight: 12 },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Record Name */}
                                            <Text
                                              ellipsizeMode={'tail'}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily:
                                                    'OpenSans_700Bold',
                                                  paddingBottom: 9,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listData &&
                                                listData['_sender_details']
                                                  ?.name}
                                            </Text>
                                          </View>
                                          {/* Second Line Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                flexGrow: 0,
                                                flexShrink: 0,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Time */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors
                                                      .custom_rgb153_153_153,
                                                  fontFamily:
                                                    'OpenSans_600SemiBold',
                                                  fontSize: 10,
                                                  lineHeight: 16,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {formatDate(listData?.created_at)}
                                            </Text>
                                          </View>
                                          {/* Third Line Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginTop: 6,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Data Point */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily:
                                                    'OpenSans_400Regular',
                                                  fontSize: 12,
                                                  lineHeight: 18,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Message}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* Right Side Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'center',
                                              alignItems: 'center',
                                              alignSelf: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Touchable
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: true,
                                                  });
                                                  (
                                                    await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                      {
                                                        notifications_id:
                                                          listData?.id,
                                                      }
                                                    )
                                                  )?.json;
                                                  await refetchGetNotifications();
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: false,
                                                  });
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                          >
                                            {/* Badge Frame */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignContent: 'center',
                                                  alignItems: 'center',
                                                  alignSelf: 'center',
                                                  backgroundColor:
                                                    theme.colors
                                                      .custom_rgb97_116_246,
                                                  borderRadius: 12,
                                                  flexDirection: 'row',
                                                  flexGrow: 0,
                                                  flexShrink: 0,
                                                  justifyContent: 'center',
                                                  paddingBottom: 6,
                                                  paddingLeft: 6,
                                                  paddingRight: 6,
                                                  paddingTop: 6,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Icon Frame */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { marginRight: 3 },
                                                  dimensions.width
                                                )}
                                              >
                                                <Icon
                                                  color={theme.colors.medium}
                                                  name={'MaterialIcons/clear'}
                                                  size={24}
                                                />
                                              </View>
                                            </View>
                                          </Touchable>
                                        </View>
                                      </View>
                                    </Touchable>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* isFollow */}
                            <>
                              {!listData?.isFollowed ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginTop: 12 },
                                    dimensions.width
                                  )}
                                >
                                  {/* Flex Touchable */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginBottom: 12,
                                        marginLeft: 12,
                                        marginRight: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Touchable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            navigation.navigate(
                                              'MemberDetailScreen',
                                              {
                                                navigate_user_Id:
                                                  listData &&
                                                  listData['_sender_details']
                                                    ?.id,
                                              }
                                            );
                                            (
                                              await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                {
                                                  notifications_id:
                                                    listData?.id,
                                                }
                                              )
                                            )?.json;
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      {/* Record Item Frame */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: theme.colors.divider,
                                            borderLeftWidth: 1,
                                            borderRadius: 12,
                                            borderRightWidth: 1,
                                            borderTopWidth: 1,
                                            flexDirection: 'row',
                                            flexGrow: 0,
                                            flexShrink: 0,
                                            paddingBottom: 12,
                                            paddingTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Left Side Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              borderBottomLeftRadius: 12,
                                              borderTopLeftRadius: 12,
                                              flexGrow: 0,
                                              flexShrink: 0,
                                              justifyContent: 'center',
                                              paddingLeft: 12,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Review Image */}
                                          <CircleImage
                                            size={64}
                                            source={{
                                              uri: `${
                                                listData &&
                                                listData['_sender_details']
                                                  ?.profile_image?.url
                                              }`,
                                            }}
                                          />
                                        </View>
                                        {/* Middle Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flexGrow: 1,
                                              flexShrink: 0,
                                              justifyContent: 'center',
                                              marginLeft: 18,
                                              maxWidth: 200,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Top Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { marginRight: 12 },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Record Name */}
                                            <Text
                                              ellipsizeMode={'tail'}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily:
                                                    'OpenSans_700Bold',
                                                  paddingBottom: 9,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listData &&
                                                listData['_sender_details']
                                                  ?.name}
                                            </Text>
                                          </View>
                                          {/* Second Line Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                flexGrow: 0,
                                                flexShrink: 0,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Time */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors
                                                      .custom_rgb153_153_153,
                                                  fontFamily:
                                                    'OpenSans_600SemiBold',
                                                  fontSize: 10,
                                                  lineHeight: 16,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {formatDate(listData?.created_at)}
                                            </Text>
                                          </View>
                                          {/* Third Line Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginTop: 6,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Data Point */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily:
                                                    'OpenSans_400Regular',
                                                  fontSize: 12,
                                                  lineHeight: 18,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Message}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* Right Side Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'center',
                                              alignItems: 'center',
                                              alignSelf: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Touchable
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: true,
                                                  });
                                                  (
                                                    await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                      {
                                                        notifications_id:
                                                          listData?.id,
                                                      }
                                                    )
                                                  )?.json;
                                                  await refetchGetNotifications();
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: false,
                                                  });
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                          >
                                            {/* Badge Frame */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignContent: 'center',
                                                  alignItems: 'center',
                                                  alignSelf: 'center',
                                                  backgroundColor:
                                                    theme.colors
                                                      .custom_rgb97_116_246,
                                                  borderRadius: 12,
                                                  flexDirection: 'row',
                                                  flexGrow: 0,
                                                  flexShrink: 0,
                                                  justifyContent: 'center',
                                                  paddingBottom: 6,
                                                  paddingLeft: 6,
                                                  paddingRight: 6,
                                                  paddingTop: 6,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Icon Frame */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { marginRight: 3 },
                                                  dimensions.width
                                                )}
                                              >
                                                <Icon
                                                  color={theme.colors.medium}
                                                  name={'MaterialIcons/clear'}
                                                  size={24}
                                                />
                                              </View>
                                            </View>
                                          </Touchable>
                                        </View>
                                      </View>
                                    </Touchable>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* isGlobal Frame */}
                            <>
                              {!listData?.isGlobal ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginTop: 12 },
                                    dimensions.width
                                  )}
                                >
                                  {/* Flex Touchable */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginBottom: 12,
                                        marginLeft: 12,
                                        marginRight: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Touchable>
                                      {/* Record Item Frame */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: theme.colors.divider,
                                            borderLeftWidth: 1,
                                            borderRadius: 12,
                                            borderRightWidth: 1,
                                            borderTopWidth: 1,
                                            flexDirection: 'row',
                                            flexGrow: 0,
                                            flexShrink: 0,
                                            paddingBottom: 12,
                                            paddingTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Left Side Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              borderBottomLeftRadius: 12,
                                              borderTopLeftRadius: 12,
                                              flexGrow: 0,
                                              flexShrink: 0,
                                              justifyContent: 'center',
                                              paddingLeft: 12,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Review Image */}
                                          <CircleImage
                                            size={64}
                                            source={{
                                              uri: `${
                                                listData &&
                                                listData['_sender_details']
                                                  ?.profile_image?.url
                                              }`,
                                            }}
                                          />
                                        </View>
                                        {/* Middle Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flexGrow: 1,
                                              flexShrink: 0,
                                              justifyContent: 'center',
                                              marginLeft: 18,
                                              maxWidth: 200,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Top Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { marginRight: 12 },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Record Name */}
                                            <Text
                                              ellipsizeMode={'tail'}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily:
                                                    'OpenSans_700Bold',
                                                  paddingBottom: 9,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listData &&
                                                listData['_sender_details']
                                                  ?.name}
                                            </Text>
                                          </View>
                                          {/* Second Line Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                flexGrow: 0,
                                                flexShrink: 0,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Time */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    theme.colors
                                                      .custom_rgb153_153_153,
                                                  fontFamily:
                                                    'OpenSans_600SemiBold',
                                                  fontSize: 10,
                                                  lineHeight: 16,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {formatDate(listData?.created_at)}
                                            </Text>
                                          </View>
                                          {/* Third Line Frame */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginTop: 6,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Data Point */}
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color: theme.colors.strong,
                                                  fontFamily:
                                                    'OpenSans_400Regular',
                                                  fontSize: 12,
                                                  lineHeight: 18,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Message}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* Right Side Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'center',
                                              alignItems: 'center',
                                              alignSelf: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Touchable
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: true,
                                                  });
                                                  (
                                                    await risingCoachesDeleteNotificationDELETE.mutateAsync(
                                                      {
                                                        notifications_id:
                                                          listData?.id,
                                                      }
                                                    )
                                                  )?.json;
                                                  await refetchGetNotifications();
                                                  setGlobalVariableValue({
                                                    key: 'is_loading',
                                                    value: false,
                                                  });
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                          >
                                            {/* Badge Frame */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignContent: 'center',
                                                  alignItems: 'center',
                                                  alignSelf: 'center',
                                                  backgroundColor:
                                                    theme.colors
                                                      .custom_rgb97_116_246,
                                                  borderRadius: 12,
                                                  flexDirection: 'row',
                                                  flexGrow: 0,
                                                  flexShrink: 0,
                                                  justifyContent: 'center',
                                                  paddingBottom: 6,
                                                  paddingLeft: 6,
                                                  paddingRight: 6,
                                                  paddingTop: 6,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Icon Frame */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { marginRight: 3 },
                                                  dimensions.width
                                                )}
                                              >
                                                <Icon
                                                  color={theme.colors.medium}
                                                  name={'MaterialIcons/clear'}
                                                  size={24}
                                                />
                                              </View>
                                            </View>
                                          </Touchable>
                                        </View>
                                      </View>
                                    </Touchable>
                                  </View>
                                </View>
                              )}
                            </>
                          </>
                        );
                      }}
                    />
                  </>
                );
              }}
            </RisingCoachesApi.FetchGetNotificationsGET>
          </View>
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

export default withTheme(NotificationsScreen);
