import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import isEmpty from '../global-functions/isEmpty';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  IconButton,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { MasonryFlashList } from '@shopify/flash-list';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const ProgramsScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const risingCoachesCreateFolderPOST = RisingCoachesApi.useCreateFolderPOST();
  const risingCoachesCreateAdminFolderPOST =
    RisingCoachesApi.useCreateAdminFolderPOST();
  const risingCoachesDeleteFolderDELETE =
    RisingCoachesApi.useDeleteFolderDELETE();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setGlobalVariableValue({
          key: 'is_loading',
          value: true,
        });
        setShowModal(props.route?.params?.nav_show_modal ?? '');
        const user = (
          await RisingCoachesApi.getUserDetailGET(Constants, {
            user_id: Constants['user_id'],
          })
        )?.json;
        const admin_result = user?.isAdmin;
        setIsAdmin(admin_result);
        setGlobalVariableValue({
          key: 'is_loading',
          value: false,
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);
  const [folderName, setFolderName] = React.useState('');
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isDeleteFolder, setIsDeleteFolder] = React.useState(false);
  const [isFolders, setIsFolders] = React.useState(false);
  const [pickerValue, setPickerValue] = React.useState('');
  const [programs, setPrograms] = React.useState([]);
  const [selectedFolderId, setSelectedFolderId] = React.useState(0);
  const [selectedModules, setSelectedModules] = React.useState([]);
  const [selectedProgramID, setSelectedProgramID] = React.useState(0);
  const [selectedProgramName, setSelectedProgramName] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [showPersonal, setShowPersonal] = React.useState(true);
  const [showRising, setShowRising] = React.useState(false);
  const [showShared, setShowShared] = React.useState(false);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.background },
        dimensions.width
      )}
    >
      <>
        {isFolders ? null : (
          <RisingCoachesApi.FetchGetProgramListGET>
            {({ loading, error, data, refetchGetProgramList }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <MasonryFlashList
                  data={fetchData}
                  estimatedItemSize={30}
                  keyExtractor={masonryListData =>
                    masonryListData?.id ||
                    masonryListData?.uuid ||
                    JSON.stringify(masonryListData)
                  }
                  listKey={'6etVsj9w'}
                  numColumns={2}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item }) => {
                    const masonryListData = item;
                    return (
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setIsFolders(true);
                              const results = (
                                await RisingCoachesApi.getFoldersByProgramIDGET(
                                  Constants,
                                  { program_id: masonryListData?.id }
                                )
                              )?.json;
                              setSelectedModules(results);
                              setGlobalVariableValue({
                                key: 'is_loading',
                                value: true,
                              });
                              setGlobalVariableValue({
                                key: 'is_loading',
                                value: false,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              paddingBottom: 20,
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 20,
                            },
                            dimensions.width
                          )}
                        >
                          <Image
                            resizeMode={'contain'}
                            source={{
                              uri: `${masonryListData?.background_img?.url}`,
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageStyles(theme)['Image'],
                                {
                                  borderColor: theme.colors['Divider'],
                                  borderRadius: 11,
                                  borderWidth: 1,
                                  height: 160,
                                  width: 160,
                                }
                              ),
                              dimensions.width
                            )}
                          />
                          <Text
                            ellipsizeMode={'tail'}
                            numberOfLines={1}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'],
                                {
                                  alignSelf: 'center',
                                  fontSize: 9,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {masonryListData?.title}
                          </Text>
                        </View>
                      </Touchable>
                    );
                  }}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={true}
                />
              );
            }}
          </RisingCoachesApi.FetchGetProgramListGET>
        )}
      </>
      {/* isPaying */}
      <>
        {!isFolders ? null : (
          <View>
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-start', alignSelf: 'flex-start' },
                dimensions.width
              )}
            >
              <Button
                onPress={() => {
                  try {
                    setIsFolders(false);
                    setSelectedModules('');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'],
                    {
                      backgroundColor: theme.colors['Secondary'],
                      marginBottom: 5,
                      marginLeft: 20,
                      marginRight: 20,
                      marginTop: 20,
                    }
                  ),
                  dimensions.width
                )}
                title={'Back'}
              />
            </View>
            <>
              {!showModal ? null : (
                <Modal animationType={'slide'} visible={showModal}>
                  <>
                    {!'Cancel' ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            justifyContent: 'center',
                            marginBottom: 50,
                            marginLeft: 50,
                            marginRight: 50,
                            marginTop: 100,
                          },
                          dimensions.width
                        )}
                      >
                        <TextInput
                          onChangeText={newTextInputValue => {
                            try {
                              setFolderName(newTextInputValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          placeholder={'Folder Name'}
                          placeholderTextColor={theme.colors.light}
                          style={StyleSheet.applyWidth(
                            {
                              borderBottomWidth: 1,
                              borderColor: theme.colors.divider,
                              borderLeftWidth: 1,
                              borderRadius: 8,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              paddingRight: 8,
                              paddingTop: 8,
                            },
                            dimensions.width
                          )}
                          value={folderName}
                        />
                        <Spacer bottom={8} left={8} right={8} top={8} />
                        {/* isPersonal */}
                        <>
                          {!showPersonal ? null : (
                            <View>
                              <View>
                                {/* Normal Button Solid */}
                                <>
                                  {Constants['is_loading'] ? null : (
                                    <Button
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            setGlobalVariableValue({
                                              key: 'is_loading',
                                              value: true,
                                            });
                                            (
                                              await risingCoachesCreateFolderPOST.mutateAsync(
                                                {
                                                  folder_name: folderName,
                                                  program_id: selectedProgramID,
                                                }
                                              )
                                            )?.json;
                                            setShowModal(false);
                                            const selected = (
                                              await RisingCoachesApi.getProgramFoldersGET(
                                                Constants,
                                                {
                                                  program_name:
                                                    selectedProgramName,
                                                }
                                              )
                                            )?.json;
                                            setFolderName('');
                                            setSelectedModules(selected);
                                            setGlobalVariableValue({
                                              key: 'is_loading',
                                              value: false,
                                            });
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor: theme.colors.primary,
                                          borderRadius: 8,
                                          fontFamily: 'System',
                                          fontWeight: '700',
                                          textAlign: 'center',
                                        },
                                        dimensions.width
                                      )}
                                      title={'Create'}
                                    />
                                  )}
                                </>
                              </View>
                            </View>
                          )}
                        </>
                        {/* isRising */}
                        <>
                          {!showRising ? null : (
                            <View>
                              <>
                                {!isAdmin ? null : (
                                  <View>
                                    {/* Admin Button Solid */}
                                    <>
                                      {Constants['is_loading'] ? null : (
                                        <Button
                                          onPress={() => {
                                            const handler = async () => {
                                              try {
                                                setGlobalVariableValue({
                                                  key: 'is_loading',
                                                  value: true,
                                                });
                                                (
                                                  await risingCoachesCreateAdminFolderPOST.mutateAsync(
                                                    { name: folderName }
                                                  )
                                                )?.json;
                                                setShowModal(false);
                                                setGlobalVariableValue({
                                                  key: 'is_loading',
                                                  value: false,
                                                });
                                                setFolderName('');
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            };
                                            handler();
                                          }}
                                          style={StyleSheet.applyWidth(
                                            {
                                              backgroundColor:
                                                theme.colors.primary,
                                              borderRadius: 8,
                                              fontFamily: 'System',
                                              fontWeight: '700',
                                              textAlign: 'center',
                                            },
                                            dimensions.width
                                          )}
                                          title={'Create'}
                                        />
                                      )}
                                    </>
                                  </View>
                                )}
                              </>
                              <>
                                {isAdmin ? null : (
                                  <View>
                                    {/* Normal Button Solid */}
                                    <>
                                      {Constants['is_loading'] ? null : (
                                        <Button
                                          onPress={() => {
                                            const handler = async () => {
                                              try {
                                                setGlobalVariableValue({
                                                  key: 'is_loading',
                                                  value: true,
                                                });
                                                (
                                                  await risingCoachesCreateFolderPOST.mutateAsync(
                                                    { folder_name: folderName }
                                                  )
                                                )?.json;
                                                setShowModal(false);
                                                setGlobalVariableValue({
                                                  key: 'is_loading',
                                                  value: false,
                                                });
                                                setFolderName('');
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            };
                                            handler();
                                          }}
                                          style={StyleSheet.applyWidth(
                                            {
                                              backgroundColor:
                                                theme.colors.primary,
                                              borderRadius: 8,
                                              fontFamily: 'System',
                                              fontWeight: '700',
                                              textAlign: 'center',
                                            },
                                            dimensions.width
                                          )}
                                          title={'Create'}
                                        />
                                      )}
                                    </>
                                  </View>
                                )}
                              </>
                            </View>
                          )}
                        </>
                        {/* Button Loading */}
                        <>
                          {!Constants['is_loading'] ? null : (
                            <Button
                              disabled={false}
                              loading={true}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors.primary,
                                  borderRadius: 8,
                                  fontFamily: 'System',
                                  fontWeight: '700',
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                              title={''}
                            />
                          )}
                        </>
                        <Spacer bottom={8} left={8} right={8} top={8} />
                        {/* Button Outline */}
                        <Button
                          onPress={() => {
                            try {
                              setShowModal(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: 'transparent',
                              borderColor: theme.colors['Error'],
                              borderRadius: 8,
                              borderWidth: 1,
                              color: theme.colors['Error'],
                              fontFamily: 'System',
                              fontWeight: '700',
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                          title={'Cancel'}
                        />
                      </View>
                    )}
                  </>
                </Modal>
              )}
            </>
            {/* isAdmin */}
            <>
              {!Constants['is_admin'] ? null : (
                <View>
                  {/* Admin Folder View */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginBottom: 5,
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 5,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setShowModal(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Add */}
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.strong,
                              fontFamily: 'Roboto_400Regular',
                              fontSize: 12,
                              marginRight: 5,
                            },
                            dimensions.width
                          )}
                        >
                          {'Create New Folder'}
                        </Text>
                        <IconButton
                          icon={'Ionicons/add-circle-sharp'}
                          onPress={() => {
                            try {
                              setShowModal(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={32}
                        />
                      </View>
                    </Touchable>
                  </View>
                </View>
              )}
            </>
            {/* Personal Fetch */}
            <>
              {!showPersonal ? null : (
                <RisingCoachesApi.FetchGetUserFoldersGET method={'GET'}>
                  {({ loading, error, data, refetchGetUserFolders }) => {
                    const personalFetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Delete Modal */}
                        <>
                          {!isDeleteFolder ? null : (
                            <Modal
                              animationType={'none'}
                              visible={isDeleteFolder}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    alignSelf: 'center',
                                    flex: 1,
                                    justifyContent: 'center',
                                    marginBottom: 30,
                                    marginLeft: 30,
                                    marginRight: 30,
                                    marginTop: 30,
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'center' },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors.strong,
                                        fontFamily: 'Manrope_700Bold',
                                        textAlign: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      'Are you sure you want to delete this folder? All contents will be lost. '
                                    }
                                  </Text>
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignSelf: 'stretch',
                                      flexDirection: 'row',
                                      justifyContent: 'space-around',
                                      marginTop: 30,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Button Solid */}
                                  <Button
                                    onPress={() => {
                                      try {
                                        setIsDeleteFolder(false);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor: theme.colors.light,
                                        borderRadius: 8,
                                        fontFamily: 'System',
                                        fontWeight: '700',
                                        minWidth: 150,
                                        textAlign: 'center',
                                      },
                                      dimensions.width
                                    )}
                                    title={'No'}
                                  />
                                  {/* Button Solid */}
                                  <>
                                    {Constants['is_loading'] ? null : (
                                      <Button
                                        onPress={() => {
                                          const handler = async () => {
                                            try {
                                              setGlobalVariableValue({
                                                key: 'is_loading',
                                                value: true,
                                              });
                                              (
                                                await risingCoachesDeleteFolderDELETE.mutateAsync(
                                                  {
                                                    folders_id:
                                                      selectedFolderId,
                                                  }
                                                )
                                              )?.json;
                                              await refetchGetUserFolders();
                                              setGlobalVariableValue({
                                                key: 'is_loading',
                                                value: false,
                                              });
                                              setIsDeleteFolder(false);
                                              const selected = (
                                                await RisingCoachesApi.getProgramFoldersGET(
                                                  Constants,
                                                  {
                                                    program_name:
                                                      selectedProgramName,
                                                  }
                                                )
                                              )?.json;
                                              setSelectedModules(selected);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors.primary,
                                            borderRadius: 8,
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            minWidth: 150,
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                        title={'Yes'}
                                      />
                                    )}
                                  </>
                                  {/* Button Solid */}
                                  <>
                                    {!Constants['is_loading'] ? null : (
                                      <Button
                                        disabled={true}
                                        loading={true}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors.primary,
                                            borderRadius: 8,
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            minWidth: 150,
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                        title={'Yes'}
                                      />
                                    )}
                                  </>
                                </View>
                              </View>
                            </Modal>
                          )}
                        </>
                        {/* Personal Grid */}
                        <>
                          {isEmpty(selectedModules) ? null : (
                            <FlatList
                              contentContainerStyle={StyleSheet.applyWidth(
                                {
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  paddingTop: 8,
                                },
                                dimensions.width
                              )}
                              data={selectedModules}
                              keyExtractor={personalGridData =>
                                personalGridData?.id ||
                                personalGridData?.uuid ||
                                JSON.stringify(personalGridData)
                              }
                              listKey={'fPdqEZAA'}
                              numColumns={2}
                              renderItem={({ item }) => {
                                const personalGridData = item;
                                return (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flex: 1,
                                        maxWidth: '50%',
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        paddingTop: 8,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Touchable
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'FolderContentsScreen',
                                            {
                                              isShared: showShared,
                                              folder_id: personalGridData?.id,
                                            }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors.surface,
                                            borderBottomWidth: 1,
                                            borderColor: theme.colors.divider,
                                            borderLeftWidth: 1,
                                            borderRadius: 12,
                                            borderRightWidth: 1,
                                            borderTopWidth: 1,
                                            overflow: 'hidden',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              backgroundColor:
                                                theme.colors.secondary,
                                              height: 140,
                                              justifyContent: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                alignSelf: 'center',
                                                justifyContent: 'center',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignSelf: 'center',
                                                  color: theme.colors.surface,
                                                  fontFamily: 'Roboto_700Bold',
                                                  textAlign: 'center',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {personalGridData?.name}
                                            </Text>
                                          </View>
                                        </View>

                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flex: 1,
                                              justifyContent: 'space-between',
                                              paddingBottom: 12,
                                              paddingLeft: 12,
                                              paddingRight: 12,
                                              paddingTop: 12,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            ellipsizeMode={'tail'}
                                            numberOfLines={1}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                fontFamily:
                                                  'Poppins_600SemiBold',
                                                fontSize: 12,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {personalGridData?.properties?.name}{' '}
                                          </Text>

                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  flexDirection: 'row',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color: theme.colors.primary,
                                                    fontFamily:
                                                      'Poppins_600SemiBold',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {personalGridData &&
                                                  personalGridData[
                                                    '_contentcount'
                                                  ]}
                                              </Text>

                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color: theme.colors.primary,
                                                    fontFamily:
                                                      'Poppins_500Medium',
                                                    fontSize: 10,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {' media items'}
                                              </Text>
                                            </View>
                                            <>
                                              {!Constants['is_admin'] ? null : (
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { alignItems: 'center' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <IconButton
                                                    color={theme.colors.error}
                                                    icon={'Feather/trash-2'}
                                                    onPress={() => {
                                                      try {
                                                        setSelectedFolderId(
                                                          personalGridData?.id
                                                        );
                                                        setIsDeleteFolder(true);
                                                      } catch (err) {
                                                        console.error(err);
                                                      }
                                                    }}
                                                    size={24}
                                                  />
                                                </View>
                                              )}
                                            </>
                                          </View>
                                        </View>
                                      </View>
                                    </Touchable>
                                  </View>
                                );
                              }}
                            />
                          )}
                        </>
                      </>
                    );
                  }}
                </RisingCoachesApi.FetchGetUserFoldersGET>
              )}
            </>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(ProgramsScreen);
