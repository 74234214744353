import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import { Button, ScreenContainer, TextInput, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View, useWindowDimensions } from 'react-native';

const EditContentPageScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const risingCoachesUpdateContentPUT = RisingCoachesApi.useUpdateContentPUT();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setEditedTitle(props.route?.params?.editable_content ?? '');
      setEditedTags(props.route?.params?.editable_content ?? '');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);
  const [editedTags, setEditedTags] = React.useState('');
  const [editedTitle, setEditedTitle] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'stretch',
          alignSelf: 'stretch',
          flex: 1,
          justifyContent: 'center',
          padding: 20,
        },
        dimensions.width
      )}
    >
      {/* Text 3 */}
      <Text
        accessible={true}
        allowFontScaling={true}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
            fontFamily: 'Manrope_700Bold',
            marginBottom: 20,
          }),
          dimensions.width
        )}
      >
        {'Content Title'}
      </Text>
      <TextInput
        allowFontScaling={true}
        autoCapitalize={'none'}
        changeTextDelay={500}
        onChangeText={newTextInputValue => {
          try {
            setEditedTitle(newTextInputValue);
          } catch (err) {
            console.error(err);
          }
        }}
        placeholder={'Enter a value...'}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.TextInputStyles(theme)['Text Input'],
            { marginBottom: 20 }
          ),
          dimensions.width
        )}
        value={editedTitle}
      />
      {/* Text 2 */}
      <Text
        accessible={true}
        allowFontScaling={true}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
            fontFamily: 'Manrope_700Bold',
            marginBottom: 20,
          }),
          dimensions.width
        )}
      >
        {'Searchable Tags/Description\n'}
      </Text>
      <TextInput
        allowFontScaling={true}
        changeTextDelay={500}
        multiline={true}
        numberOfLines={4}
        onChangeText={newTextAreaValue => {
          try {
            setEditedTags(newTextAreaValue);
          } catch (err) {
            console.error(err);
          }
        }}
        placeholder={
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        }
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.TextInputStyles(theme)['Text Area'], {
            marginBottom: 20,
          }),
          dimensions.width
        )}
        textAlignVertical={'top'}
        value={editedTags}
      />
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: 'space-around',
            flexDirection: 'row',
            justifyContent: 'space-around',
          },
          dimensions.width
        )}
      >
        <Button
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
              backgroundColor: theme.colors['Error'],
            }),
            dimensions.width
          )}
          title={'Cancel'}
        />
        {/* Button 2 */}
        <Button
          onPress={() => {
            const handler = async () => {
              try {
                (
                  await risingCoachesUpdateContentPUT.mutateAsync({
                    editedTags: editedTags,
                    editedTitle: editedTitle,
                    folder_content_id: props.route?.params?.folder_id ?? '',
                  })
                )?.json;
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
              backgroundColor: theme.colors['Secondary'],
            }),
            dimensions.width
          )}
          title={'Save'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(EditContentPageScreen);
