export default {
  ScreenShot20220627At64622AM: require('../assets/images/ScreenShot20220627At64622AM.png'),
  Avatar: require('../assets/images/Avatar.png'),
  UploadIcon: require('../assets/images/UploadIcon.png'),
  ProductCategory: require('../assets/images/ProductCategory.png'),
  CharlieGreen3JmfENcL24MUnsplash: require('../assets/images/CharlieGreen3JmfENcL24MUnsplash.jpg'),
  Ramen: require('../assets/images/Ramen.jpg'),
  File1cabff: require('../assets/images/File1cabff.png'),
  Sky: require('../assets/images/Sky.jpg'),
  JulianWanWNoLnJo7tS8Unsplash: require('../assets/images/JulianWanWNoLnJo7tS8Unsplash.jpg'),
  Risingcoacheswhite: require('../assets/images/Risingcoacheswhite.png'),
  PivotIcon: require('../assets/images/PivotIcon.png'),
  PsLogoWhite: require('../assets/images/PsLogoWhite.png'),
  Bg: require('../assets/images/Bg.png'),
  Whitebg: require('../assets/images/Whitebg.png'),
};
