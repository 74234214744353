import React from 'react';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import { ScreenContainer, WebView, withTheme } from '@draftbit/ui';
import { useWindowDimensions } from 'react-native';

const FeaturedVideoScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme } = props;

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <WebView
        cacheEnabled={true}
        javaScriptEnabled={true}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        source={{
          uri: `${
            props.route?.params?.video_url ??
            'https://www.youtube.com/embed/x92WW8GdSnM'
          }`,
        }}
        style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
      />
    </ScreenContainer>
  );
};

export default withTheme(FeaturedVideoScreen);
