import React from 'react';
import * as PivotAnalysisApi from '../apis/PivotAnalysisApi.js';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getPushTokenUtil from '../utils/getPushToken';
import {
  Button,
  Link,
  ScreenContainer,
  Spacer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View, useWindowDimensions } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const SignInScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const pivotAnalysisGetTokenPOST = PivotAnalysisApi.useGetTokenPOST();
  const risingCoachesUpdatePushTokenPOST =
    RisingCoachesApi.useUpdatePushTokenPOST();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!Constants['auth_header']) {
        return;
      }
      navigation.navigate('HomeScreen', { nav_show_modal: false });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);
  const [emailInput, setEmailInput] = React.useState('');
  const [passwordInput, setPasswordInput] = React.useState('');
  const [styledTextFieldValue, setStyledTextFieldValue] = React.useState('');
  const [styledTextFieldValue2, setStyledTextFieldValue2] = React.useState('');

  return (
    <ScreenContainer hasTopSafeArea={false}>
      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          { flex: 1, justifyContent: 'center' },
          dimensions.width
        )}
      >
        {/* Header */}
        <View>
          {/* Title */}
          <Text
            style={StyleSheet.applyWidth(
              {
                fontFamily: 'System',
                fontSize: 36,
                fontWeight: '600',
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Pure Sweat'}
          </Text>
          {/* Subtitle */}
          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors.strong,
                fontFamily: 'System',
                fontWeight: '400',
                marginTop: 4,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Log in to get started'}
          </Text>
        </View>
        {/* Register Form */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: 'center',
              marginTop: 24,
              maxWidth: 500,
              paddingLeft: 36,
              paddingRight: 36,
            },
            dimensions.width
          )}
        >
          {/* Error Message */}
          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors.error,
                fontSize: 12,
                marginBottom: 16,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {Constants['error_message']}
          </Text>
          {/* Email Input */}
          <TextInput
            autoCapitalize={'none'}
            keyboardType={'email-address'}
            onChangeText={newEmailInputValue => {
              try {
                setEmailInput(newEmailInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            placeholder={'Email'}
            placeholderTextColor={theme.colors.light}
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: 1,
                borderColor: theme.colors.light,
                borderLeftWidth: 1,
                borderRadius: 8,
                borderRightWidth: 1,
                borderTopWidth: 1,
                paddingBottom: 16,
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 16,
              },
              dimensions.width
            )}
            textContentType={'emailAddress'}
          />
          <Spacer bottom={8} left={8} right={8} top={12} />
          {/* Password Input */}
          <TextInput
            autoCapitalize={'none'}
            onChangeText={newPasswordInputValue => {
              try {
                setPasswordInput(newPasswordInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            placeholder={'Password'}
            placeholderTextColor={theme.colors.light}
            returnKeyType={'done'}
            secureTextEntry={true}
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: 1,
                borderColor: theme.colors.light,
                borderLeftWidth: 1,
                borderRadius: 8,
                borderRightWidth: 1,
                borderTopWidth: 1,
                paddingBottom: 16,
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 16,
              },
              dimensions.width
            )}
            textContentType={'password'}
          />
          <Spacer bottom={24} left={8} right={8} top={24} />
          {/* Sign In Button */}
          <>
            {Constants['is_loading'] ? null : (
              <Button
                onPress={() => {
                  const handler = async () => {
                    try {
                      setGlobalVariableValue({
                        key: 'is_loading',
                        value: true,
                      });
                      const response = (
                        await RisingCoachesApi.loginPOST(Constants, {
                          email: emailInput,
                          password: passwordInput,
                        })
                      )?.json;
                      const message = response?.message;
                      const authDetails = response?.authDetails;
                      setGlobalVariableValue({
                        key: 'error_message',
                        value: message,
                      });
                      setGlobalVariableValue({
                        key: 'is_loading',
                        value: false,
                      });
                      const authTokenFinal = authDetails?.authToken;
                      if (!authTokenFinal) {
                        return;
                      }
                      const user_details = authDetails?.user;
                      const profile_image = user_details?.profile_image;
                      const url = profile_image?.url;
                      setGlobalVariableValue({
                        key: 'user_image',
                        value: url,
                      });
                      const user_id = user_details?.id;
                      const name = user_details?.name;
                      const email = user_details?.email;
                      const isAdmin = user_details?.isAdmin;
                      setGlobalVariableValue({
                        key: 'user_email',
                        value: email,
                      });
                      setGlobalVariableValue({
                        key: 'is_admin',
                        value: isAdmin,
                      });
                      setGlobalVariableValue({
                        key: 'auth_header',
                        value: authTokenFinal,
                      });
                      setGlobalVariableValue({
                        key: 'user_id',
                        value: user_id,
                      });
                      setGlobalVariableValue({
                        key: 'user_name',
                        value: name,
                      });
                      setEmailInput('');
                      navigation.navigate('HomeScreen', {
                        nav_show_modal: false,
                      });
                      setPasswordInput('');
                      setGlobalVariableValue({
                        key: 'error_message',
                        value: '',
                      });
                      const pivotAuthResponse = (
                        await pivotAnalysisGetTokenPOST.mutateAsync()
                      )?.json;
                      const pivotAuth = pivotAuthResponse?.access;
                      setGlobalVariableValue({
                        key: 'pivot_auth_header',
                        value: 'Bearer ' + pivotAuth,
                      });
                      const push_token = await getPushTokenUtil({
                        failMessage:
                          'Failed to get push token for push notification!',
                        deviceMessage:
                          'Must use physical device for Push Notifications',
                      });

                      (
                        await risingCoachesUpdatePushTokenPOST.mutateAsync({
                          expo_push_token: push_token,
                          user_id: user_id,
                        })
                      )?.json;
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors.primary,
                    borderRadius: 8,
                    fontFamily: 'System',
                    fontWeight: '700',
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Sign In'}
              >
                {'Sign Up'}
              </Button>
            )}
          </>
          {/* Sign Up Button */}
          <>
            {!Constants['is_loading'] ? null : (
              <Button
                disabled={true}
                loading={true}
                onPress={() => {
                  try {
                    navigation.navigate('NewsFeedScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors.primary,
                    borderRadius: 8,
                    fontFamily: 'System',
                    fontWeight: '700',
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={''}
              >
                {'Sign Up'}
              </Button>
            )}
          </>
          <Spacer bottom={16} left={8} right={8} top={16} />
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', justifyContent: 'center' },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                { color: theme.colors.strong, marginRight: 2 },
                dimensions.width
              )}
            >
              {"Don't have an account?"}
            </Text>
            <Spacer bottom={8} left={2} right={2} top={8} />
            {/* Sign In Link */}
            <Link
              onPress={() => {
                try {
                  navigation.navigate('SignUpScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { color: theme.colors.primary },
                dimensions.width
              )}
              title={'Sign up.'}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', justifyContent: 'center', marginTop: 20 },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                { color: theme.colors.strong, marginRight: 2 },
                dimensions.width
              )}
            >
              {'Forgot your password?'}
            </Text>
            <Spacer bottom={8} left={2} right={2} top={8} />
            {/* Sign In Link */}
            <Link
              onPress={() => {
                try {
                  navigation.navigate('PasswordRecoveryScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { color: theme.colors.primary },
                dimensions.width
              )}
              title={'Reset Password.'}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(SignInScreen);
