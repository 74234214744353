import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import isEmpty from '../global-functions/isEmpty';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Divider,
  Icon,
  IconButton,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const RisingCoachesContentScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const FilterList = list => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    const newList = list.filter(item =>
      item.content_title.toLowerCase().includes(textInputValue2.toLowerCase())
    );

    return newList;
  };

  const [isSearch, setIsSearch] = React.useState(false);
  const [textInputValue2, setTextInputValue2] = React.useState('');

  return (
    <ScreenContainer hasTopSafeArea={true} scrollable={true}>
      {/* Add Folder View */}
      <>
        {!(props.route?.params?.isShared ?? false) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginBottom: 5,
                marginLeft: 10,
                marginRight: 10,
                marginTop: 5,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('UploadAdminContentScreen', {
                    navigation_folder_id: props.route?.params?.folder_id ?? 35,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'flex-start',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  },
                  dimensions.width
                )}
              >
                {/* Add New Content */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'Roboto_400Regular',
                      fontSize: 12,
                      marginRight: 5,
                    },
                    dimensions.width
                  )}
                >
                  {'Add new item'}
                </Text>
                <IconButton
                  icon={'Ionicons/add-circle-sharp'}
                  onPress={() => {
                    try {
                      navigation.navigate('UploadAdminContentScreen', {
                        navigation_folder_id:
                          props.route?.params?.folder_id ?? 35,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                />
              </View>
            </Touchable>
          </View>
        )}
      </>
      {/* Search Bar Component */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-around',
            paddingBottom: 24,
            paddingLeft: 18,
            paddingTop: 24,
          },
          dimensions.width
        )}
      >
        {/* Left Side Frame */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'flex-start',
              alignSelf: 'flex-start',
              flexGrow: 0,
              flexShrink: 0,
              width: '70%',
            },
            dimensions.width
          )}
        >
          <>
            {!isSearch ? null : (
              <TextInput
                autoFocus={true}
                onChangeText={newTextInputValue => {
                  try {
                    setTextInputValue2(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Search Content'}
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: theme.colors.divider,
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '100%',
                  },
                  dimensions.width
                )}
                value={textInputValue2}
              />
            )}
          </>
        </View>
        {/* Right Side Frame */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              flexGrow: 0,
              flexShrink: 0,
              justifyContent: 'center',
              maxWidth: '10%',
              minWidth: '10%',
            },
            dimensions.width
          )}
        >
          {/* Right Icon Touchable 2 */}
          <>
            {isSearch ? null : (
              <Touchable
                onPress={() => {
                  try {
                    setIsSearch(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Right Icon 2 */}
                <Icon
                  color={theme.colors.custom_rgb189_198_212}
                  name={'AntDesign/search1'}
                  size={18}
                />
              </Touchable>
            )}
          </>
          {/* Right Icon Touchable 2 */}
          <>
            {!isSearch ? null : (
              <Touchable
                onPress={() => {
                  try {
                    setIsSearch(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Right Icon 2 */}
                <Icon
                  color={theme.colors.custom_rgb189_198_212}
                  name={'Feather/x'}
                  size={18}
                />
              </Touchable>
            )}
          </>
        </View>
      </View>
      <Divider
        color={theme.colors.divider}
        style={StyleSheet.applyWidth({ height: 1 }, dimensions.width)}
      />
      {/* ContentsFetch */}
      <RisingCoachesApi.FetchGetAdminContentGET
        folder_id={props.route?.params?.folder_id ?? 35}
      >
        {({ loading, error, data, refetchGetAdminContent }) => {
          const contentsFetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* No Data */}
              <>
                {!isEmpty(contentsFetchData) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignSelf: 'center', marginTop: 20, width: 200 },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          alignSelf: 'center',
                          color: theme.colors['Light'],
                          fontFamily: 'Manrope_400Regular',
                          fontSize: 16,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {'There is no content yet. Upload your first file. '}
                    </Text>
                  </View>
                )}
              </>
              <FlatList
                contentContainerStyle={StyleSheet.applyWidth(
                  { flex: 1 },
                  dimensions.width
                )}
                data={FilterList(contentsFetchData)}
                keyExtractor={listData =>
                  listData?.id || listData?.uuid || JSON.stringify(listData)
                }
                listKey={'MH8bXPJb'}
                numColumns={1}
                renderItem={({ item }) => {
                  const listData = item;
                  return (
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate(
                            'RisingCoachesContentDetailsScreen',
                            { navigation_content_id: listData?.id }
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* File View */}
                        <>
                          {!listData?.isFile ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  backgroundColor: theme.colors.secondary,
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors.strong,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={theme.colors.surface}
                                name={'FontAwesome/file-text-o'}
                                size={45}
                                style={StyleSheet.applyWidth(
                                  {
                                    height: 100,
                                    minHeight: 100,
                                    minWidth: 100,
                                    width: 100,
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                          )}
                        </>
                        {/* Image View */}
                        <>
                          {!listData?.isImage ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors.strong,
                                },
                                dimensions.width
                              )}
                            >
                              <Image
                                resizeMode={'contain'}
                                source={{ uri: `${listData?.image?.url}` }}
                                style={StyleSheet.applyWidth(
                                  { height: 100, width: 100 },
                                  dimensions.width
                                )}
                              />
                            </View>
                          )}
                        </>
                        {/* Video View */}
                        <>
                          {!listData?.isVideo ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderBottomWidth: 1,
                                  borderColor: theme.colors.strong,
                                },
                                dimensions.width
                              )}
                            >
                              <Image
                                resizeMode={'cover'}
                                source={{
                                  uri: `${listData?.youtube_thumbnail}`,
                                }}
                                style={StyleSheet.applyWidth(
                                  { height: 100, width: 100 },
                                  dimensions.width
                                )}
                              />
                            </View>
                          )}
                        </>
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              borderBottomWidth: 1,
                              borderColor: theme.colors.divider,
                              flex: 1,
                              flexDirection: 'row',
                              paddingLeft: 12,
                              paddingRight: 12,
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.light,
                                  fontFamily: 'System',
                                  fontSize: 10,
                                  fontWeight: '400',
                                },
                                dimensions.width
                              )}
                            >
                              {listData?.content_type}
                            </Text>

                            <Text
                              ellipsizeMode={'tail'}
                              numberOfLines={2}
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'System',
                                  fontWeight: '600',
                                  marginTop: 2,
                                },
                                dimensions.width
                              )}
                            >
                              {listData?.content_title}
                            </Text>

                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.primary,
                                  fontFamily: 'System',
                                  fontWeight: '400',
                                  marginTop: 4,
                                },
                                dimensions.width
                              )}
                            >
                              {'Uploaded By '}
                              {listData?.user_details?.name}
                            </Text>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              { marginLeft: 8 },
                              dimensions.width
                            )}
                          >
                            <Icon
                              color={theme.colors.light}
                              name={'Feather/chevron-right'}
                              size={24}
                            />
                          </View>
                        </View>
                      </View>
                    </Touchable>
                  );
                }}
              />
            </>
          );
        }}
      </RisingCoachesApi.FetchGetAdminContentGET>
    </ScreenContainer>
  );
};

export default withTheme(RisingCoachesContentScreen);
