import React from 'react';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import selectFileUtil from '../utils/selectFile';
import {
  Button,
  CircleImage,
  Icon,
  Picker,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import * as WebBrowser from 'expo-web-browser';
import {
  Image,
  KeyboardAvoidingView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const CreateFeedPostScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const risingCoachesCreateFeedPostPOST =
    RisingCoachesApi.useCreateFeedPostPOST();

  const [descriptionInput, setDescriptionInput] = React.useState('');
  const [fileBase, setFileBase] = React.useState('');
  const [imageBase, setImageBase] = React.useState('');
  const [pickerOptions, setPickerOptions] = React.useState([
    'Scheduling',
    'Strategy/Concepts',
    'Motivation/Leadership',
    'Recruiting',
    'Player Development',
    'Jobs',
  ]);
  const [pickerValue, setPickerValue] = React.useState('');
  const [showFile, setShowFile] = React.useState(false);
  const [showImage, setShowImage] = React.useState(false);
  const [showText, setShowText] = React.useState(false);
  const [showVideo, setShowVideo] = React.useState(true);
  const [titleTextInput, setTitleTextInput] = React.useState('');
  const [videoLinkInput, setVideoLinkInput] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
    >
      <KeyboardAvoidingView behavior={'position'} enabled={true}>
        <View>
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors.divider,
                borderBottomWidth: 1,
                borderColor: theme.colors.lightest,
                borderLeftWidth: 1,
                borderRadius: 14,
                borderRightWidth: 1,
                borderTopWidth: 1,
                flexDirection: 'row',
              },
              dimensions.width
            )}
          >
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              {/* GridButtonActive */}
              <>
                {!showVideo ? null : (
                  <Button
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.primary,
                        borderRadius: 0,
                        fontFamily: 'Roboto_700Bold',
                        fontSize: 12,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'Video'}
                  />
                )}
              </>
              {/* GridButtonInactive */}
              <>
                {showVideo ? null : (
                  <Button
                    onPress={() => {
                      try {
                        setShowImage(false);
                        setShowFile(false);
                        setShowVideo(true);
                        setShowText(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.divider,
                        borderRadius: 0,
                        color: theme.colors.light,
                        fontFamily: 'Roboto_700Bold',
                        fontSize: 12,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'Video'}
                  />
                )}
              </>
            </View>

            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              {/* ListButtonActive */}
              <>
                {!showImage ? null : (
                  <Button
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.primary,
                        borderRadius: 0,
                        fontFamily: 'Roboto_700Bold',
                        fontSize: 12,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'Image'}
                  />
                )}
              </>
              {/* ListButtonInactive */}
              <>
                {showImage ? null : (
                  <Button
                    onPress={() => {
                      try {
                        setShowVideo(false);
                        setShowImage(true);
                        setShowFile(false);
                        setShowText(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.divider,
                        borderRadius: 0,
                        color: theme.colors.light,
                        fontFamily: 'Roboto_700Bold',
                        fontSize: 12,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'Image'}
                  />
                )}
              </>
            </View>

            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              {/* ListButtonActive */}
              <>
                {!showFile ? null : (
                  <Button
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.primary,
                        borderRadius: 0,
                        fontFamily: 'Roboto_700Bold',
                        fontSize: 12,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'File'}
                  />
                )}
              </>
              {/* ListButtonInactive */}
              <>
                {showFile ? null : (
                  <Button
                    onPress={() => {
                      try {
                        setShowFile(true);
                        setShowVideo(false);
                        setShowImage(false);
                        setShowText(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.divider,
                        borderRadius: 0,
                        color: theme.colors.light,
                        fontFamily: 'Roboto_700Bold',
                        fontSize: 12,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'File'}
                  />
                )}
              </>
            </View>

            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              {/* ListButtonActive */}
              <>
                {!showText ? null : (
                  <Button
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.primary,
                        borderRadius: 0,
                        fontFamily: 'Roboto_700Bold',
                        fontSize: 12,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'Text'}
                  />
                )}
              </>
              {/* ListButtonInactive */}
              <>
                {showText ? null : (
                  <Button
                    onPress={() => {
                      try {
                        setShowFile(false);
                        setShowVideo(false);
                        setShowImage(false);
                        setShowText(true);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.divider,
                        borderRadius: 0,
                        color: theme.colors.light,
                        fontFamily: 'Roboto_700Bold',
                        fontSize: 12,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'Text'}
                  />
                )}
              </>
            </View>
          </View>
        </View>

        <KeyboardAwareScrollView
          enableAutomaticScroll={true}
          keyboardShouldPersistTaps={'never'}
          showsVerticalScrollIndicator={true}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'stretch',
                alignSelf: 'stretch',
                height: '100%',
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: 40, paddingRight: 40 },
                dimensions.width
              )}
            >
              {/* File Input */}
              <>
                {!showFile ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          alignSelf: 'flex-start',
                          color: theme.colors.strong,
                          fontFamily: 'System',
                          fontWeight: '600',
                        },
                        dimensions.width
                      )}
                    >
                      {'Upload Your File'}
                    </Text>
                    <Spacer bottom={8} left={8} right={8} top={8} />
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const file_base = await selectFileUtil({
                              returnNameAndValue: false,
                            });
                            setFileBase(file_base);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      <View>
                        <>
                          {!imageBase ? null : (
                            <Image
                              resizeMode={'cover'}
                              source={{ uri: `${fileBase}` }}
                              style={StyleSheet.applyWidth(
                                { height: 150, width: 150 },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        <>
                          {fileBase ? null : (
                            <Icon
                              color={theme.colors.secondary}
                              name={'AntDesign/addfile'}
                              size={80}
                            />
                          )}
                        </>
                      </View>
                    </Touchable>
                  </View>
                )}
              </>
              {/* Image Input */}
              <>
                {!showImage ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          alignSelf: 'flex-start',
                          color: theme.colors.strong,
                          fontFamily: 'System',
                          fontWeight: '600',
                        },
                        dimensions.width
                      )}
                    >
                      {'Upload Your Image'}
                    </Text>
                    <Spacer bottom={8} left={8} right={8} top={8} />
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const base64_image = await openImagePickerUtil({
                              mediaTypes: 'Images',
                              allowsEditing: false,
                              quality: 0.2,
                            });

                            setImageBase(base64_image);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      <View>
                        <>
                          {!imageBase ? null : (
                            <Image
                              resizeMode={'cover'}
                              source={{ uri: `${imageBase}` }}
                              style={StyleSheet.applyWidth(
                                { height: 150, width: 150 },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        <>
                          {imageBase ? null : (
                            <CircleImage
                              size={100}
                              source={Images.UploadIcon}
                            />
                          )}
                        </>
                      </View>
                    </Touchable>
                  </View>
                )}
              </>
              <Spacer bottom={8} left={8} right={8} top={8} />
              {/* Video Input */}
              <>
                {!showVideo ? null : (
                  <View>
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'System',
                          fontWeight: '600',
                        },
                        dimensions.width
                      )}
                    >
                      {'YouTube Video URL Link'}
                    </Text>
                    <TextInput
                      onChangeText={newTextInputValue => {
                        try {
                          setVideoLinkInput(newTextInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Enter the YouTube link...'}
                      placeholderTextColor={theme.colors.light}
                      style={StyleSheet.applyWidth(
                        {
                          borderBottomWidth: 1,
                          borderColor: theme.colors.divider,
                          borderLeftWidth: 1,
                          borderRadius: 8,
                          borderRightWidth: 1,
                          borderTopWidth: 1,
                          marginTop: 10,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 8,
                        },
                        dimensions.width
                      )}
                      value={videoLinkInput}
                    />
                    <Spacer bottom={8} left={8} right={8} top={8} />
                  </View>
                )}
              </>
              <View>
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontWeight: '600',
                    },
                    dimensions.width
                  )}
                >
                  {'Category'}
                </Text>
                <Picker
                  iconSize={24}
                  leftIconMode={'inset'}
                  onValueChange={newPickerValue => {
                    try {
                      setPickerValue(newPickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  options={pickerOptions}
                  placeholder={'Select an option'}
                  placeholderTextColor={theme.colors.light}
                  style={StyleSheet.applyWidth(
                    { fontSize: 12, marginTop: 10, maxHeight: 40 },
                    dimensions.width
                  )}
                  type={'solid'}
                />
              </View>
              <Spacer bottom={8} left={8} right={8} top={8} />
              <View>
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontWeight: '600',
                    },
                    dimensions.width
                  )}
                >
                  {'Post Title'}
                </Text>
                <TextInput
                  onChangeText={newTextInputValue => {
                    try {
                      setTitleTextInput(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a title...'}
                  placeholderTextColor={theme.colors.light}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: theme.colors.divider,
                      borderLeftWidth: 1,
                      borderRadius: 8,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      marginTop: 10,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={titleTextInput}
                />
                <Spacer bottom={8} left={8} right={8} top={8} />
              </View>

              <View>
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      fontFamily: 'System',
                      fontWeight: '600',
                    },
                    dimensions.width
                  )}
                >
                  {'Post Description'}
                </Text>
                <TextInput
                  multiline={true}
                  numberOfLines={4}
                  onChangeText={newTextAreaValue => {
                    try {
                      setDescriptionInput(newTextAreaValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Insert your post details here...'}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: theme.colors.divider,
                      borderLeftWidth: 1,
                      borderRadius: 8,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      marginTop: 10,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={descriptionInput}
                />
              </View>
              <Spacer bottom={8} left={8} right={8} top={8} />
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  marginBottom: 40,
                  paddingBottom: 10,
                  paddingLeft: 40,
                  paddingRight: 40,
                  paddingTop: 10,
                },
                dimensions.width
              )}
            >
              {/* Button Solid */}
              <>
                {Constants['is_loading'] ? null : (
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setGlobalVariableValue({
                            key: 'is_loading',
                            value: true,
                          });
                          const response = (
                            await risingCoachesCreateFeedPostPOST.mutateAsync({
                              content_title: titleTextInput,
                              content_type: 'Video',
                              file_content: fileBase,
                              image_content: imageBase,
                              isFile: showFile,
                              isImage: showImage,
                              isText: showText,
                              isVideo: showVideo,
                              tags: pickerValue,
                              text_content: descriptionInput,
                              youtubeID: videoLinkInput,
                            })
                          )?.json;
                          navigation.navigate('NewsFeedScreen');
                          setGlobalVariableValue({
                            key: 'is_loading',
                            value: false,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.secondary,
                        borderRadius: 64,
                        fontFamily: 'System',
                        fontWeight: '700',
                        paddingBottom: 16,
                        paddingTop: 16,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'Submit Post'}
                  />
                )}
              </>
              {/* Button Solid */}
              <>
                {!Constants['is_loading'] ? null : (
                  <Button
                    disabled={false}
                    loading={true}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.secondary,
                        borderRadius: 64,
                        fontFamily: 'System',
                        fontWeight: '700',
                        paddingBottom: 16,
                        paddingTop: 16,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={''}
                  />
                )}
              </>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </KeyboardAvoidingView>
    </ScreenContainer>
  );
};

export default withTheme(CreateFeedPostScreen);
