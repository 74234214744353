import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as StripeApi from '../apis/StripeApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import * as WebBrowser from 'expo-web-browser';
import { Text, View, useWindowDimensions } from 'react-native';

const PurchaseProgramsScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const stripeStripeSessionPOST = StripeApi.useStripeSessionPOST();

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginTop: 20,
          },
          dimensions.width
        )}
      >
        <Text
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'],
            dimensions.width
          )}
        >
          {'Setting Ball Screens Course'}
        </Text>
        <Button
          onPress={() => {
            const handler = async () => {
              try {
                const result = (
                  await stripeStripeSessionPOST.mutateAsync({
                    fail_url: 'https://example.com/fail',
                    product_id: 'price_1Nc6Gc4OaeceRtyKj5ys6hiz',
                    success_url: 'https://example.com/success',
                  })
                )?.json;
                await WebBrowser.openBrowserAsync(`${result?.url}`);
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            GlobalStyles.ButtonStyles(theme)['Button'],
            dimensions.width
          )}
          title={'Buy'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(PurchaseProgramsScreen);
