import React from 'react';
import * as CustomCode from '../CustomCode';
import * as GlobalStyles from '../GlobalStyles.js';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  Button,
  IconButton,
  ScreenContainer,
  Spacer,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  ImageBackground,
  Platform,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const ContentDetailsScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const getImageUrl = userImageObj => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return userImageObj.url;
  };

  const convertDate = dateString => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    // Using moment's inbuilt function to get relative time.
    return CustomCode.moment(dateString).fromNow().toString();
  };

  const risingCoachesDeleteContentItemDELETE =
    RisingCoachesApi.useDeleteContentItemDELETE();

  const [isActionMenu, setIsActionMenu] = React.useState(false);
  const [new_comment, setNew_comment] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
    >
      {/* Page Header */}
      <View
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ViewStyles(theme)['Page Header'], {
            height: '10%',
          }),
          dimensions.width
        )}
      >
        <IconButton
          color={theme.colors['Custom #ffffff']}
          icon={'AntDesign/left'}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          size={32}
          style={StyleSheet.applyWidth({ marginLeft: 10 }, dimensions.width)}
        />
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              color: theme.colors['Custom #ffffff'],
              fontFamily: 'Manrope_700Bold',
              fontSize: 16,
            }),
            dimensions.width
          )}
        >
          {props.route?.params?.content_name ?? ''}
        </Text>
        {/* Text 2 */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              color: theme.colors['Secondary'],
              marginRight: 10,
            }),
            dimensions.width
          )}
        >
          {'Next'}
        </Text>
      </View>
      {/* Mobiel Fetch */}
      <>
        {Platform.OS === 'web' ? null : (
          <RisingCoachesApi.FetchGetContentDetailsGET
            folder_content_id={props.route?.params?.navigation_content_id ?? 45}
            handlers={{
              onData: mobielFetchData => {
                const handler = async () => {
                  try {
                    (
                      await RisingCoachesApi.getContentDetailsGET(Constants, {
                        folder_content_id:
                          props.route?.params?.navigation_content_id ?? 45,
                      })
                    )?.json;
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              },
            }}
            method={'GET'}
          >
            {({ loading, error, data, refetchGetContentDetails }) => {
              const mobielFetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  <ActionSheet visible={isActionMenu}>
                    {/* Delete Action */}
                    <ActionSheetItem
                      color={theme.colors.error}
                      label={'Delete Item'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            (
                              await risingCoachesDeleteContentItemDELETE.mutateAsync(
                                {
                                  folder_content_id:
                                    props.route?.params
                                      ?.navigation_content_id ?? 45,
                                }
                              )
                            )?.json;
                            setIsActionMenu(false);
                            navigation.navigate('ProgramsScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { textAlign: 'center' },
                        dimensions.width
                      )}
                    />
                    {/* Edit Action */}
                    <ActionSheetItem
                      color={theme.colors.error}
                      label={'Edit Item'}
                      onPress={() => {
                        try {
                          setIsActionMenu(false);
                          navigation.navigate('EditContentPageScreen', {
                            editable_content: mobielFetchData?.content_title,
                            content_tags:
                              mobielFetchData?.searchable_description,
                            folder_id: mobielFetchData?.id,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { textAlign: 'center' },
                        dimensions.width
                      )}
                    />
                    <ActionSheetCancel
                      label={'Cancel'}
                      onPress={() => {
                        try {
                          setIsActionMenu(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    />
                  </ActionSheet>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        height: '10%',
                        justifyContent: 'space-around',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginLeft: 20,
                          marginRight: 10,
                          width: '60%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { maxWidth: '80%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          allowFontScaling={true}
                          numberOfLines={3}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.strong,
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 12,
                            },
                            dimensions.width
                          )}
                          textBreakStrategy={'highQuality'}
                        >
                          {mobielFetchData?.content_title}
                        </Text>
                      </View>
                      <Spacer bottom={12} left={8} right={8} top={12} />
                      <>
                        {!Constants['is_admin'] ? null : (
                          <View>
                            <IconButton
                              color={theme.colors.darken}
                              icon={'Entypo/dots-three-horizontal'}
                              onPress={() => {
                                try {
                                  setIsActionMenu(true);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              size={32}
                            />
                          </View>
                        )}
                      </>
                    </View>
                    <>
                      {!(mobielFetchData?.text_content !== '') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { marginLeft: 20, marginRight: 20, marginTop: 10 },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'Poppins_600SemiBold',
                                fontSize: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {'Description'}
                          </Text>
                          <Spacer bottom={6} left={8} right={8} top={6} />
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.medium,
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 14,
                                lineHeight: 26,
                                textAlign: 'left',
                              },
                              dimensions.width
                            )}
                          >
                            {mobielFetchData?.text_content}
                          </Text>
                        </View>
                      )}
                    </>
                    {/* Next Button */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          alignSelf: 'center',
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      <Button
                        loading={Constants['is_loading']}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setGlobalVariableValue({
                                key: 'is_loading',
                                value: true,
                              });
                              if (props.route?.params?.isSub ?? true) {
                                const next_sub_id = (
                                  await RisingCoachesApi.getNextVideoNumberInSubGET(
                                    Constants,
                                    {
                                      order_number:
                                        mobielFetchData?.item_order + 1,
                                      sub_folder_id:
                                        props.route?.params?.sub_folder_id ??
                                        528,
                                    }
                                  )
                                )?.json;
                                navigation.navigate('ContentDetailsScreen', {
                                  isSub: props.route?.params?.isSub ?? true,
                                  sub_folder_id:
                                    props.route?.params?.sub_folder_id ?? 528,
                                  navigation_content_id: next_sub_id,
                                });
                              } else {
                                const next_id = (
                                  await RisingCoachesApi.getNextVideoNumberGET(
                                    Constants,
                                    {
                                      folder_id: mobielFetchData?.folders_id,
                                      order_number:
                                        mobielFetchData?.item_order + 1,
                                    }
                                  )
                                )?.json;
                                navigation.navigate('ContentDetailsScreen', {
                                  isSub: props.route?.params?.isSub ?? true,
                                  sub_folder_id:
                                    props.route?.params?.sub_folder_id ?? 528,
                                  navigation_content_id: next_id,
                                });
                              }

                              setGlobalVariableValue({
                                key: 'is_loading',
                                value: false,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          dimensions.width
                        )}
                        title={'Next'}
                      />
                    </View>
                  </View>
                  {/* File View */}
                  <>
                    {!mobielFetchData?.isFile ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { height: '80%', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <>
                          {!mobielFetchData?.isFile ? null : (
                            <WebView
                              source={{ uri: `${mobielFetchData?.file?.url}` }}
                              style={StyleSheet.applyWidth(
                                { flex: 1, height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* WebOrMobile */}
                  <View>
                    {/* Mobile */}
                    <>
                      {Platform.OS === 'web' ? null : (
                        <View>
                          {/* Video View */}
                          <>
                            {!mobielFetchData?.isVideo ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { height: '80%', width: '100%' },
                                  dimensions.width
                                )}
                              >
                                <>
                                  {!mobielFetchData?.isVideo ? null : (
                                    <WebView
                                      source={{
                                        uri: `${mobielFetchData?.youtube_render_link}`,
                                      }}
                                      style={StyleSheet.applyWidth(
                                        {
                                          flex: 1,
                                          height: '100%',
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                              </View>
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                  {/* Image View */}
                  <>
                    {!mobielFetchData?.isImage ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { height: '80%', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <ImageBackground
                          resizeMode={'contain'}
                          source={{ uri: `${mobielFetchData?.image?.url}` }}
                          style={StyleSheet.applyWidth(
                            {
                              height: '90%',
                              justifyContent: 'flex-end',
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </>
              );
            }}
          </RisingCoachesApi.FetchGetContentDetailsGET>
        )}
      </>
      {/* Web Fetch */}
      <>
        {!(Platform.OS === 'web') ? null : (
          <RisingCoachesApi.FetchGetFolderContentsWebGET
            folder_content_id={props.route?.params?.navigation_content_id ?? 45}
            handlers={{
              onData: webFetchData => {
                const handler = async () => {
                  try {
                    (
                      await RisingCoachesApi.getContentDetailsGET(Constants, {
                        folder_content_id:
                          props.route?.params?.navigation_content_id ?? 45,
                      })
                    )?.json;
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              },
            }}
            method={'GET'}
          >
            {({ loading, error, data, refetchGetFolderContentsWeb }) => {
              const webFetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  <ActionSheet visible={isActionMenu}>
                    {/* Delete Action */}
                    <ActionSheetItem
                      color={theme.colors.error}
                      label={'Delete Item'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            (
                              await risingCoachesDeleteContentItemDELETE.mutateAsync(
                                {
                                  folder_content_id:
                                    props.route?.params
                                      ?.navigation_content_id ?? 45,
                                }
                              )
                            )?.json;
                            setIsActionMenu(false);
                            navigation.navigate('ProgramsScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { textAlign: 'center' },
                        dimensions.width
                      )}
                    />
                    {/* Edit Action */}
                    <ActionSheetItem
                      color={theme.colors.error}
                      label={'Edit Item'}
                      onPress={() => {
                        try {
                          setIsActionMenu(false);
                          navigation.navigate('EditContentPageScreen', {
                            editable_content: webFetchData?.content_title,
                            content_tags: webFetchData?.searchable_description,
                            folder_id: webFetchData?.id,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { textAlign: 'center' },
                        dimensions.width
                      )}
                    />
                    <ActionSheetCancel
                      label={'Cancel'}
                      onPress={() => {
                        try {
                          setIsActionMenu(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    />
                  </ActionSheet>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        height: '10%',
                        justifyContent: 'space-around',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginLeft: 20,
                          marginRight: 10,
                          width: '60%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { maxWidth: '80%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          allowFontScaling={true}
                          numberOfLines={3}
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.strong,
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 12,
                            },
                            dimensions.width
                          )}
                          textBreakStrategy={'highQuality'}
                        >
                          {webFetchData?.content_title}
                        </Text>
                      </View>
                      <Spacer bottom={12} left={8} right={8} top={12} />
                      <>
                        {!Constants['is_admin'] ? null : (
                          <View>
                            <IconButton
                              color={theme.colors.darken}
                              icon={'Entypo/dots-three-horizontal'}
                              onPress={() => {
                                try {
                                  setIsActionMenu(true);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              size={32}
                            />
                          </View>
                        )}
                      </>
                    </View>
                    <>
                      {!(webFetchData?.text_content !== '') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { marginLeft: 20, marginRight: 20, marginTop: 10 },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.strong,
                                fontFamily: 'Poppins_600SemiBold',
                                fontSize: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {'Description'}
                          </Text>
                          <Spacer bottom={6} left={8} right={8} top={6} />
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.medium,
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 14,
                                lineHeight: 26,
                                textAlign: 'left',
                              },
                              dimensions.width
                            )}
                          >
                            {webFetchData?.text_content}
                          </Text>
                        </View>
                      )}
                    </>
                    {/* Next Button */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          alignSelf: 'center',
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      <Button
                        loading={Constants['is_loading']}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              setGlobalVariableValue({
                                key: 'is_loading',
                                value: true,
                              });
                              if (props.route?.params?.isSub ?? true) {
                                const next_sub_id = (
                                  await RisingCoachesApi.getNextVideoNumberInSubGET(
                                    Constants,
                                    {
                                      order_number:
                                        webFetchData?.item_order + 1,
                                      sub_folder_id:
                                        props.route?.params?.sub_folder_id ??
                                        528,
                                    }
                                  )
                                )?.json;
                                navigation.navigate('ContentDetailsScreen', {
                                  isSub: props.route?.params?.isSub ?? true,
                                  sub_folder_id:
                                    props.route?.params?.sub_folder_id ?? 528,
                                  navigation_content_id: next_sub_id,
                                });
                              } else {
                                const next_id = (
                                  await RisingCoachesApi.getNextVideoNumberGET(
                                    Constants,
                                    {
                                      folder_id: webFetchData?.folders_id,
                                      order_number:
                                        webFetchData?.item_order + 1,
                                    }
                                  )
                                )?.json;
                                navigation.navigate('ContentDetailsScreen', {
                                  isSub: props.route?.params?.isSub ?? true,
                                  sub_folder_id:
                                    props.route?.params?.sub_folder_id ?? 528,
                                  navigation_content_id: next_id,
                                });
                              }

                              setGlobalVariableValue({
                                key: 'is_loading',
                                value: false,
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          dimensions.width
                        )}
                        title={'Next'}
                      />
                    </View>
                  </View>
                  {/* File View */}
                  <>
                    {!webFetchData?.isFile ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { height: '80%', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <>
                          {!webFetchData?.isFile ? null : (
                            <WebView
                              source={{ uri: `${webFetchData?.file?.url}` }}
                              style={StyleSheet.applyWidth(
                                { flex: 1, height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* WebOrMobile */}
                  <View>
                    {/* Web */}
                    <>
                      {!(Platform.OS === 'web') ? null : (
                        <View>
                          {/* Video View */}
                          <>
                            {!webFetchData?.isVideo ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  { height: '80%', width: '100%' },
                                  dimensions.width
                                )}
                              >
                                <>
                                  {!webFetchData?.isVideo ? null : (
                                    <WebView
                                      source={{
                                        uri: 'https://player.vimeo.com/video/882863319',
                                      }}
                                      style={StyleSheet.applyWidth(
                                        {
                                          flex: 1,
                                          height: '100%',
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    />
                                  )}
                                </>
                              </View>
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                  {/* Image View */}
                  <>
                    {!webFetchData?.isImage ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { height: '80%', width: '100%' },
                          dimensions.width
                        )}
                      >
                        <ImageBackground
                          resizeMode={'contain'}
                          source={{ uri: `${webFetchData?.image?.url}` }}
                          style={StyleSheet.applyWidth(
                            {
                              height: '90%',
                              justifyContent: 'flex-end',
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </>
              );
            }}
          </RisingCoachesApi.FetchGetFolderContentsWebGET>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(ContentDetailsScreen);
