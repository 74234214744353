import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const getScoresGET = (
  Constants,
  { league_id, limit, start_date_after, state_date_before },
  handlers = {}
) =>
  fetch(
    `https://api.pivotanalysis.app/api/stats/feed?start_date_after=${encodeURIComponent(
      `${
        typeof start_date_after === 'string'
          ? start_date_after
          : JSON.stringify(start_date_after ?? '')
      }`
    )}&start_date_before=${encodeURIComponent(
      `${
        typeof state_date_before === 'string'
          ? state_date_before
          : JSON.stringify(state_date_before ?? '')
      }`
    )}&league_id=${encodeURIComponent(
      `${
        typeof league_id === 'string'
          ? league_id
          : JSON.stringify(league_id ?? '')
      }`
    )}&limit=${encodeURIComponent(
      `${typeof limit === 'string' ? limit : JSON.stringify(limit ?? '')}`
    )}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: Constants['pivot_auth_header'],
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetScoresGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Scores', args],
    () => getScoresGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetScoresGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  league_id,
  limit,
  start_date_after,
  state_date_before,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetScoresGET(
    { league_id, limit, start_date_after, state_date_before },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetScores: refetch });
};

export const getTokenPOST = (Constants, _args, handlers = {}) =>
  fetch(`https://api.pivotanalysis.app/api/token/`, {
    body: JSON.stringify({
      email: 'marknaufel@gmail.com',
      password: 'Naufel2022!',
    }),
    headers: {
      Accept: 'application/json',
      Authorization: Constants['pivot_auth_header'],
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useGetTokenPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => getTokenPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Auth', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Auth');
        queryClient.invalidateQueries('Auths');
      },
    }
  );
};

export const FetchGetTokenPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useGetTokenPOST(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetToken: refetch });
};
