import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

export const ButtonStyles = theme =>
  StyleSheet.create({
    Button: {
      borderRadius: 8,
      fontFamily: 'System',
      fontWeight: '700',
      textAlign: 'center',
    },
  });

export const ImageBackgroundStyles = theme =>
  StyleSheet.create({ 'Image Background': { height: '100%', width: '100%' } });

export const ImageStyles = theme =>
  StyleSheet.create({ Image: { height: 100 } });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { minHeight: 40 } });

export const LinkStyles = theme =>
  StyleSheet.create({ Link: { color: theme.colors.primary } });

export const TextStyles = theme =>
  StyleSheet.create({ Text: { color: theme.colors.strong } });

export const VideoPlayerStyles = theme =>
  StyleSheet.create({ Video: { height: 215 } });

export const SurfaceStyles = theme =>
  StyleSheet.create({
    'Home Menu': {
      backgroundColor: theme.colors.surface,
      flex: 2,
      height: '100%',
      position: 'absolute',
      top: 0,
      width: '80%',
      zIndex: 5,
    },
  });

export const ViewStyles = theme =>
  StyleSheet.create({
    'Home Header': {
      alignItems: 'center',
      backgroundColor: theme.colors.secondary,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: 20,
      paddingRight: 20,
    },
    'Page Header': {
      alignItems: 'center',
      backgroundColor: theme.colors['Secondary'],
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: 20,
      paddingTop: 30,
      width: '100%',
    },
    'Page Header 2': {
      alignItems: 'center',
      backgroundColor: theme.colors['Secondary'],
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: 20,
      paddingTop: 30,
      width: '100%',
    },
  });

export const TextInputStyles = theme =>
  StyleSheet.create({
    'Text Area': {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
    'Text Input': {
      borderBottomWidth: 1,
      borderColor: theme.colors.divider,
      borderLeftWidth: 1,
      borderRadius: 8,
      borderRightWidth: 1,
      borderTopWidth: 1,
      paddingBottom: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
    },
  });
