import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import isEmpty from '../global-functions/isEmpty';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  Button,
  Checkbox,
  Circle,
  CircleImage,
  Divider,
  Icon,
  IconButton,
  RadioButtonGroup,
  RadioButtonRow,
  ScreenContainer,
  Surface,
  Touchable,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  Modal,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const NewsFeedScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const todayDate = () => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return CustomCode.moment().format('MMM Do');
  };

  const isWinner = custom => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    if ((custom = '')) {
      return true;
    } else false;
  };

  // Converts a date
  const convertDate = dateString => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    // Using moment's inbuilt function to get relative time.
    return CustomCode.moment(dateString).fromNow().toString();
  };

  // Takes image obj and returns the url within
  const getImageUrl = userImageObj => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return userImageObj?.url;
  };

  const checkValidity = invalidAuth => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    if (invalidAuth) {
      return '';
    }
    return Constants.auth_header;
  };

  const navigateOnFalseOnboarding = onboarded => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    if (!onboarded) {
      setTimeout(() => props.navigation.navigate('OnboardingScreen'));
      return true;
    }
    return false;
  };

  const changeScoreTag = id => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    if (id == 20) {
      setLeagueName('NBA');
    }
    if (id == 1) {
      setLeagueName('NCAAM');
    }
    if (id == 2) {
      setLeagueName('NCAAW');
    }
    if (id == 10) {
      setLeagueName('WNBA');
    }
    if (id == 21) {
      setLeagueName('G');
    }
  };

  const formatScoreDate = dateString => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    // "MMM Do"
    return CustomCode.moment(dateString).format('MMM Do');
  };

  const navigateOnInvalidAuth = onboarded => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    if (onboarded === undefined) {
      setTimeout(() => props.navigation.navigate('SignInScreen'));
      return true;
    }

    return false;
  };

  const risingCoachesDeleteFeedPostDELETE =
    RisingCoachesApi.useDeleteFeedPostDELETE();
  const risingCoachesUnfollowUserDELETE =
    RisingCoachesApi.useUnfollowUserDELETE();
  const risingCoachesFollowUserPOST = RisingCoachesApi.useFollowUserPOST();
  const risingCoachesAddLikePOST = RisingCoachesApi.useAddLikePOST();
  const risingCoachesRemoveLikeDELETE = RisingCoachesApi.useRemoveLikeDELETE();
  const risingCoachesSaveFeedToFolderPOST =
    RisingCoachesApi.useSaveFeedToFolderPOST();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setGlobalVariableValue({
          key: 'is_loading',
          value: false,
        });
        const auth_response = (await RisingCoachesApi.checkAuthGET(Constants))
          ?.json;
        const onboarded = auth_response?.isOnboarded;
        console.log(Constants['auth_header']);
        const invalidAuth = navigateOnInvalidAuth(onboarded);
        const newAuth = checkValidity(invalidAuth);
        setGlobalVariableValue({
          key: 'auth_header',
          value: newAuth,
        });
        if (invalidAuth) {
          return;
        }
        const notOnboarded = navigateOnFalseOnboarding(onboarded);
        setGlobalVariableValue({
          key: 'is_loading',
          value: false,
        });
        const response = (await RisingCoachesApi.checkPayingGET(Constants))
          ?.json;
        const isPaying = response?.isPaying;
        setGlobalVariableValue({
          key: 'user_paying',
          value: isPaying,
        });
        const event = (await RisingCoachesApi.checkLiveEventGET(Constants))
          ?.json;
        const live = event?.isLive;
        setIsLive(live);
        setEvent_name(event?.event_name);
        setEvent_url(event?.event_url);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);
  const [categories, setCategories] = React.useState("['All', 'Recruiting']");
  const [event_name, setEvent_name] = React.useState('');
  const [event_url, setEvent_url] = React.useState('');
  const [folderList, setFolderList] = React.useState(
    "['Test One', 'Test Two']"
  );
  const [isFilterModal, setIsFilterModal] = React.useState(false);
  const [isFolderModal, setIsFolderModal] = React.useState(false);
  const [isLive, setIsLive] = React.useState(false);
  const [isMineSettings, setIsMineSettings] = React.useState(false);
  const [isScoreFilter, setIsScoreFilter] = React.useState(false);
  const [isSettings, setIsSettings] = React.useState(false);
  const [leagueName, setLeagueName] = React.useState('NCCAM');
  const [league_id, setLeague_id] = React.useState(0);
  const [liveStories, setLiveStories] = React.useState({});
  const [pickerValue, setPickerValue] = React.useState('');
  const [radioButtonGroupValue, setRadioButtonGroupValue] = React.useState('');
  const [searchBarValue, setSearchBarValue] = React.useState('Naufel');
  const [selectedCategory, setSelectedCategory] = React.useState('All');
  const [selectedFeedPost, setSelectedFeedPost] = React.useState({});
  const [selectedPost, setSelectedPost] = React.useState(0);
  const [showNav, setShowNav] = React.useState(false);
  const [tokenIsValid, setTokenIsValid] = React.useState(false);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Background'], flexDirection: 'row' },
        dimensions.width
      )}
    >
      <RisingCoachesApi.FetchGetStoriesGET
        handlers={{
          onData: fetchData => {
            try {
              setGlobalVariableValue({
                key: 'is_loading',
                value: true,
              });
              setLiveStories(fetchData);
              setGlobalVariableValue({
                key: 'is_loading',
                value: false,
              });
            } catch (err) {
              console.error(err);
            }
          },
        }}
      >
        {({ loading, error, data, refetchGetStories }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* isMine Action Sheet */}
              <ActionSheet visible={isMineSettings}>
                <ActionSheetItem
                  color={theme.colors.error}
                  label={'Delete Post'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        (
                          await risingCoachesDeleteFeedPostDELETE.mutateAsync({
                            news_feed_content_id: selectedPost,
                          })
                        )?.json;
                        setIsMineSettings(false);
                        await refetchGetFeedWithFilter();
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    { textAlign: 'center' },
                    dimensions.width
                  )}
                />
                <ActionSheetCancel
                  label={'Cancel'}
                  onPress={() => {
                    try {
                      setIsMineSettings(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                />
              </ActionSheet>
              {/* Drawer */}
              <>
                {!showNav ? null : (
                  <Surface
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.surface,
                        flex: 2,
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        width: '80%',
                        zIndex: 5,
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors.secondary,
                          paddingBottom: 24,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 72,
                        },
                        dimensions.width
                      )}
                    >
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('MemberDetailScreen', {
                              navigate_user_Id: Constants['user_id'],
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <Circle bgColor={theme.colors.surface} size={64}>
                            <>
                              {!Constants['user_image'] ? null : (
                                <CircleImage
                                  size={80}
                                  source={{ uri: `${Constants['user_image']}` }}
                                />
                              )}
                            </>
                          </Circle>

                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1, marginLeft: 12 },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.surface,
                                  fontFamily: 'System',
                                  fontSize: 18,
                                  fontWeight: '400',
                                },
                                dimensions.width
                              )}
                            >
                              {Constants['user_name']}
                            </Text>
                          </View>
                        </View>
                      </Touchable>
                    </View>

                    <ScrollView
                      bounces={true}
                      showsVerticalScrollIndicator={false}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1, paddingBottom: 16, paddingTop: 16 },
                          dimensions.width
                        )}
                      >
                        {/* Home */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                paddingBottom: 12,
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 12,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon name={'Feather/home'} size={24} />
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'System',
                                  fontSize: 16,
                                  fontWeight: '400',
                                  marginLeft: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {'Home'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Repository */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                              navigation.navigate('ProgramsScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                paddingBottom: 12,
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 12,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon name={'Feather/folder'} size={24} />
                            {/* Content Repository */}
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'System',
                                  fontSize: 16,
                                  fontWeight: '400',
                                  marginLeft: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {'Programs'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Profile */}
                        <Touchable
                          onPress={() => {
                            try {
                              setShowNav(false);
                              navigation.navigate('MemberDetailScreen', {
                                navigate_user_Id: Constants['user_id'],
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                paddingBottom: 12,
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 12,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon name={'Feather/user'} size={24} />
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'System',
                                  fontSize: 16,
                                  fontWeight: '400',
                                  marginLeft: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {'Profile'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Notifications */}
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('NotificationsScreen');
                              setShowNav(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                paddingBottom: 12,
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 12,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon name={'Feather/bell'} size={24} />
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'System',
                                  fontSize: 16,
                                  fontWeight: '400',
                                  marginLeft: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {'Notifications'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Settings */}
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('AccountSettingsScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                paddingBottom: 12,
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 12,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon name={'AntDesign/setting'} size={24} />
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'System',
                                  fontSize: 16,
                                  fontWeight: '400',
                                  marginLeft: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {'Settings'}
                            </Text>
                          </View>
                        </Touchable>
                        {/* Logout */}
                        <Touchable
                          onPress={() => {
                            try {
                              undefined;
                              navigation.navigate('WelcomeScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                paddingBottom: 12,
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 12,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon name={'Feather/log-out'} size={24} />
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.strong,
                                  fontFamily: 'System',
                                  fontSize: 16,
                                  fontWeight: '400',
                                  marginLeft: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {'Sign out'}
                            </Text>
                          </View>
                        </Touchable>
                      </View>
                    </ScrollView>
                  </Surface>
                )}
              </>
              {/* Main Body */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    flex: 1,
                  },
                  dimensions.width
                )}
              >
                {/* Home Header */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors.secondary,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      paddingLeft: 20,
                      paddingRight: 20,
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingTop: 40,
                      },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'contain'}
                      source={Images.PsLogoWhite}
                      style={StyleSheet.applyWidth(
                        { height: 88, width: 100 },
                        dimensions.width
                      )}
                    />
                    <Checkbox
                      checkedIcon={'Feather/x'}
                      color={theme.colors.primary}
                      onPress={newCheckboxValue => {
                        try {
                          setShowNav(newCheckboxValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      size={32}
                      status={showNav}
                      uncheckedColor={theme.colors.custom_rgb255_255_255}
                      uncheckedIcon={'Feather/menu'}
                    />
                  </View>
                </View>
                {/* Body */}
                <View
                  style={StyleSheet.applyWidth(
                    { backgroundColor: theme.colors['Background'], flex: 1 },
                    dimensions.width
                  )}
                >
                  <KeyboardAwareScrollView
                    keyboardShouldPersistTaps={'never'}
                    showsVerticalScrollIndicator={false}
                  >
                    {/* Program Buttons */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 10, marginTop: 10 },
                        dimensions.width
                      )}
                    />
                    {/* Live Event */}
                    <>
                      {!Constants['user_paying'] ? null : (
                        <View>
                          {/* Live Event */}
                          <>
                            {!isLive ? null : (
                              <Touchable
                                style={StyleSheet.applyWidth(
                                  {
                                    marginBottom: 10,
                                    marginLeft: 20,
                                    marginRight: 20,
                                    marginTop: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      justifyContent: 'center',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <IconButton
                                    icon={'MaterialIcons/live-tv'}
                                    size={32}
                                  />
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors.strong,
                                        fontFamily: 'Roboto_400Regular',
                                        marginLeft: 5,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Live Now: '}
                                    {event_name}
                                  </Text>
                                </View>
                              </Touchable>
                            )}
                          </>
                        </View>
                      )}
                    </>
                    {/* AddPostRow */}
                    <>
                      {!Constants['is_admin'] ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignContent: 'stretch',
                              alignSelf: 'stretch',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              marginBottom: 10,
                              marginLeft: 30,
                              marginRight: 30,
                              marginTop: 0,
                            },
                            dimensions.width
                          )}
                        >
                          {/* LeftAligned */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'stretch',
                                alignSelf: 'stretch',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Button Solid */}
                            <Button
                              onPress={() => {
                                try {
                                  navigation.navigate('CreateFeedPostScreen');
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    theme.colors['Custom Color_3'],
                                  borderRadius: 8,
                                  fontFamily: 'Roboto_700Bold',
                                  fontSize: 12,
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                              title={'+ Add Post '}
                            />
                          </View>
                        </View>
                      )}
                    </>
                    {/* AddPostRow */}
                    <>
                      {!Constants['is_admin'] ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignContent: 'stretch',
                              alignSelf: 'stretch',
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                              marginBottom: 10,
                              marginLeft: 20,
                              marginRight: 20,
                              marginTop: 0,
                            },
                            dimensions.width
                          )}
                        >
                          {/* LeftAligned */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'stretch',
                                alignSelf: 'stretch',
                                maxWidth: 200,
                                minWidth: 150,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Button Solid */}
                            <Button
                              onPress={() => {
                                try {
                                  navigation.navigate('ProgramsScreen');
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Secondary'],
                                  borderRadius: 8,
                                  fontFamily: 'Roboto_700Bold',
                                  fontSize: 12,
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                              title={'View Programs'}
                            />
                          </View>
                          {/* LeftAligned */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'stretch',
                                alignSelf: 'stretch',
                                maxWidth: 200,
                                minWidth: 150,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Button Solid */}
                            <Button
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    await WebBrowser.openBrowserAsync(
                                      'https://www.puresweatbasketball.com/'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: theme.colors['Secondary'],
                                  borderRadius: 8,
                                  fontFamily: 'Roboto_700Bold',
                                  fontSize: 12,
                                  textAlign: 'center',
                                },
                                dimensions.width
                              )}
                              title={'Purchase Programs'}
                            />
                          </View>
                        </View>
                      )}
                    </>
                    <Divider
                      color={theme.colors.lightest}
                      style={StyleSheet.applyWidth(
                        { height: 2 },
                        dimensions.width
                      )}
                    />
                    {/* FeedFetch */}
                    <RisingCoachesApi.FetchGetFeedWithFilterGET
                      category={selectedCategory}
                    >
                      {({ loading, error, data, refetchGetFeedWithFilter }) => {
                        const feedFetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* Filter Modal */}
                            <>
                              {!isFilterModal ? null : (
                                <Modal
                                  animationType={'none'}
                                  visible={isFilterModal}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignContent: 'stretch',
                                        alignSelf: 'stretch',
                                        justifyContent: 'space-around',
                                        marginTop: 100,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <RadioButtonGroup
                                      onValueChange={newRadioButtonGroupValue => {
                                        try {
                                          setRadioButtonGroupValue(
                                            newRadioButtonGroupValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      style={StyleSheet.applyWidth(
                                        {
                                          marginBottom: 30,
                                          marginLeft: 30,
                                          marginRight: 30,
                                          marginTop: 30,
                                        },
                                        dimensions.width
                                      )}
                                      value={radioButtonGroupValue}
                                    >
                                      <RadioButtonRow
                                        color={theme.colors.primary}
                                        direction={'row'}
                                        label={'All'}
                                        unselectedColor={theme.colors.primary}
                                        value={'All'}
                                      />
                                      <RadioButtonRow
                                        color={theme.colors.primary}
                                        direction={'row'}
                                        label={'Scheduling'}
                                        unselectedColor={theme.colors.primary}
                                        value={'Scheduling'}
                                      />
                                      <RadioButtonRow
                                        color={theme.colors.primary}
                                        label={'Strategy/Concepts'}
                                        unselectedColor={theme.colors.primary}
                                        value={'Strategy/Concepts'}
                                      />
                                      <RadioButtonRow
                                        color={theme.colors.primary}
                                        label={'Motivation/Leadership'}
                                        unselectedColor={theme.colors.primary}
                                        value={'Motivation/Leadership'}
                                      />
                                      <RadioButtonRow
                                        color={theme.colors.primary}
                                        label={'Recruiting'}
                                        unselectedColor={theme.colors.primary}
                                        value={'Recruiting'}
                                      />
                                      <RadioButtonRow
                                        color={theme.colors.primary}
                                        label={'Player Development'}
                                        unselectedColor={theme.colors.primary}
                                        value={'Player Development'}
                                      />
                                      <RadioButtonRow
                                        color={theme.colors.primary}
                                        label={'Jobs'}
                                        unselectedColor={theme.colors.primary}
                                        value={'Jobs'}
                                      />
                                    </RadioButtonGroup>
                                    {/* Button Solid */}
                                    <Button
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            setSelectedCategory(
                                              radioButtonGroupValue
                                            );
                                            await refetchGetFeedWithFilter();
                                            setIsFilterModal(false);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor: theme.colors.primary,
                                          borderRadius: 8,
                                          fontFamily: 'System',
                                          fontWeight: '700',
                                          marginLeft: 30,
                                          marginRight: 30,
                                          textAlign: 'center',
                                        },
                                        dimensions.width
                                      )}
                                      title={'Filter'}
                                    />
                                  </View>
                                </Modal>
                              )}
                            </>
                            {/* newsFeedList */}
                            <FlatList
                              data={feedFetchData?.items}
                              horizontal={false}
                              keyExtractor={newsFeedListData =>
                                newsFeedListData?.id ||
                                newsFeedListData?.uuid ||
                                JSON.stringify(newsFeedListData)
                              }
                              listKey={'2mrybeV8'}
                              numColumns={1}
                              renderItem={({ item }) => {
                                const newsFeedListData = item;
                                return (
                                  <>
                                    {/* Post Touchable */}
                                    <Touchable
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'PostDetailsScreen',
                                            {
                                              feed_post_id:
                                                newsFeedListData?.id,
                                            }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      style={StyleSheet.applyWidth(
                                        { width: '100%' },
                                        dimensions.width
                                      )}
                                    >
                                      {/* NewFeedCard */}
                                      <Surface
                                        elevation={1}
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignContent: 'stretch',
                                            alignItems: 'center',
                                            alignSelf: 'center',
                                            backgroundColor:
                                              theme.colors['Custom #ffffff'],
                                            borderRadius: 20,
                                            justifyContent: 'center',
                                            marginBottom: 10,
                                            marginTop: 10,
                                            paddingBottom: 15,
                                            paddingLeft: 15,
                                            paddingRight: 15,
                                            paddingTop: 15,
                                            width: '90%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* PostHeader */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignSelf: 'stretch',
                                              flexDirection: 'row',
                                              justifyContent: 'space-between',
                                              marginBottom: 3,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* LeftAligned */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Touchable
                                              onPress={() => {
                                                try {
                                                  navigation.navigate(
                                                    'MemberDetailScreen',
                                                    {
                                                      navigate_user_Id:
                                                        newsFeedListData?.author
                                                          ?.id,
                                                    }
                                                  );
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              }}
                                            >
                                              {/* Light BG */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    backgroundColor:
                                                      theme.colors
                                                        .custom_rgb245_245_247,
                                                    borderRadius: 10,
                                                    height: 65,
                                                    justifyContent: 'center',
                                                    width: 65,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <CircleImage
                                                  size={50}
                                                  source={{
                                                    uri: `${newsFeedListData?.author?.profile_image?.url}`,
                                                  }}
                                                />
                                              </View>
                                            </Touchable>

                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  marginLeft: 10,
                                                  maxWidth: 140,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Coach Name */}
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color: theme.colors.medium,
                                                    fontFamily:
                                                      'Roboto_700Bold',
                                                    fontSize: 16,
                                                    marginBottom: 2,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {newsFeedListData?.author?.name}
                                              </Text>
                                              {/* Coach Title */}
                                              <>
                                                {!'author.position' ? null : (
                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors.light,
                                                        fontFamily:
                                                          'Roboto_400Regular',
                                                        fontSize: 12,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      newsFeedListData?.author
                                                        ?.job_title
                                                    }
                                                  </Text>
                                                )}
                                              </>
                                            </View>
                                          </View>
                                          {/* RightAligned */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-end',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <>
                                              {newsFeedListData?.isMine ? null : (
                                                <View>
                                                  {/* Button Loading */}
                                                  <>
                                                    {!Constants[
                                                      'is_loading'
                                                    ] ? null : (
                                                      <Button
                                                        disabled={true}
                                                        loading={true}
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            backgroundColor:
                                                              theme.colors
                                                                .primary,
                                                            borderRadius: 8,
                                                            fontSize: 12,
                                                            textAlign: 'center',
                                                          },
                                                          dimensions.width
                                                        )}
                                                        title={''}
                                                      />
                                                    )}
                                                  </>
                                                </View>
                                              )}
                                            </>
                                            {/* isMine Setting Button */}
                                            <>
                                              {!Constants['is_admin'] ? null : (
                                                <IconButton
                                                  color={theme.colors.secondary}
                                                  icon={
                                                    'MaterialCommunityIcons/dots-horizontal-circle'
                                                  }
                                                  onPress={() => {
                                                    try {
                                                      setSelectedPost(
                                                        newsFeedListData?.id
                                                      );
                                                      setIsMineSettings(true);

                                                      const valuezanLD70i =
                                                        newsFeedListData;
                                                      setSelectedFeedPost(
                                                        valuezanLD70i
                                                      );
                                                      const selectedFeedObject =
                                                        valuezanLD70i;
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  }}
                                                  size={32}
                                                />
                                              )}
                                            </>
                                          </View>
                                        </View>
                                        {/* Description */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'stretch',
                                              alignSelf: 'flex-start',
                                              marginBottom: 3,
                                              marginTop: 3,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                fontFamily:
                                                  'Manrope_600SemiBold',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {newsFeedListData?.content_title}
                                          </Text>
                                        </View>
                                        {/* Description */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'stretch',
                                              alignSelf: 'flex-start',
                                              marginBottom: 10,
                                              marginTop: 1,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.strong,
                                                fontFamily:
                                                  'Manrope_400Regular',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {newsFeedListData?.text_content}
                                          </Text>
                                        </View>
                                        {/* FeaturedImageTag */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'flex-end',
                                              justifyContent: 'center',
                                              paddingBottom: 3,
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <>
                                            {!newsFeedListData?.isFeatured ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    backgroundColor:
                                                      theme.colors['Secondary'],
                                                    borderRadius: 8,
                                                    flexDirection: 'row',
                                                    paddingBottom: 6,
                                                    paddingLeft: 12,
                                                    paddingRight: 12,
                                                    paddingTop: 6,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { flex: 1 },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignSelf: 'center',
                                                        color:
                                                          theme.colors.surface,
                                                        fontFamily:
                                                          'Poppins_500Medium',
                                                        fontSize: 16,
                                                        textAlign: 'center',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'Featured'}
                                                  </Text>
                                                </View>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                        {/* SponsoredImageTag */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'flex-end',
                                              justifyContent: 'center',
                                              paddingBottom: 3,
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <>
                                            {!newsFeedListData?.isSponsored ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    backgroundColor:
                                                      theme.colors.primary,
                                                    borderRadius: 8,
                                                    flexDirection: 'row',
                                                    paddingBottom: 6,
                                                    paddingLeft: 12,
                                                    paddingRight: 12,
                                                    paddingTop: 6,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { flex: 1 },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignSelf: 'center',
                                                        color:
                                                          theme.colors.surface,
                                                        fontFamily:
                                                          'Poppins_500Medium',
                                                        fontSize: 16,
                                                        textAlign: 'center',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'Sponsored'}
                                                  </Text>
                                                </View>
                                              </View>
                                            )}
                                          </>
                                        </View>
                                        {/* Content Image */}
                                        <>
                                          {!newsFeedListData?.isImage ? null : (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignContent: 'center',
                                                  alignSelf: 'stretch',
                                                  borderRadius: 12,
                                                  height: 230,
                                                  justifyContent:
                                                    'space-around',
                                                  marginBottom: 8,
                                                  marginTop: 5,
                                                  maxHeight: 800,
                                                  maxWidth: '100%',
                                                  minHeight: 230,
                                                  minWidth: 280,
                                                  overflow: 'hidden',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <ImageBackground
                                                resizeMode={'contain'}
                                                source={{
                                                  uri: `${newsFeedListData?.image?.url}`,
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    height: '100%',
                                                    maxHeight: 230,
                                                    minWidth: 320,
                                                    width: '100%',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      maxWidth: '40%',
                                                      minHeight: 50,
                                                    },
                                                    dimensions.width
                                                  )}
                                                />
                                              </ImageBackground>
                                            </View>
                                          )}
                                        </>
                                        {/* Content Video */}
                                        <>
                                          {!newsFeedListData?.isVideo ? null : (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignContent: 'center',
                                                  alignItems: 'center',
                                                  alignSelf: 'center',
                                                  borderRadius: 12,
                                                  height: 165,
                                                  justifyContent:
                                                    'space-around',
                                                  marginBottom: 8,
                                                  marginTop: 5,
                                                  maxHeight: 165,
                                                  maxWidth: '100%',
                                                  minHeight: 165,
                                                  minWidth: 280,
                                                  overflow: 'hidden',
                                                  width: '100%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    bottom: 1,
                                                    maxWidth: '40%',
                                                    minHeight: 50,
                                                    position: 'absolute',
                                                    right: 3,
                                                  },
                                                  dimensions.width
                                                )}
                                              />
                                              <WebView
                                                source={{
                                                  uri: `${newsFeedListData?.youtube_render_link}`,
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  { flex: 1, width: '80%' },
                                                  dimensions.width
                                                )}
                                              />
                                            </View>
                                          )}
                                        </>
                                        {/* SponsoredFileTag */}
                                        <>
                                          {!newsFeedListData?.isFile ? null : (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  bottom: 1,
                                                  justifyContent: 'center',
                                                  maxWidth: '40%',
                                                  minHeight: 50,
                                                  position: 'absolute',
                                                  right: 3,
                                                  width: '40%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <>
                                                {!newsFeedListData?.isSponsored ? null : (
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                          theme.colors.primary,
                                                        borderRadius: 8,
                                                        flexDirection: 'row',
                                                        paddingBottom: 6,
                                                        paddingLeft: 12,
                                                        paddingRight: 12,
                                                        paddingTop: 6,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        { flex: 1 },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Text
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignSelf: 'center',
                                                            color:
                                                              theme.colors
                                                                .surface,
                                                            fontFamily:
                                                              'Poppins_500Medium',
                                                            fontSize: 16,
                                                            textAlign: 'center',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'Sponsored'}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )}
                                              </>
                                            </View>
                                          )}
                                        </>
                                        {/* Content File */}
                                        <>
                                          {!newsFeedListData?.isFile ? null : (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignContent: 'center',
                                                  alignSelf: 'stretch',
                                                  borderRadius: 12,
                                                  justifyContent:
                                                    'space-around',
                                                  marginBottom: 8,
                                                  marginTop: 5,
                                                  maxHeight: 300,
                                                  maxWidth: '100%',
                                                  minHeight: 180,
                                                  minWidth: 280,
                                                  overflow: 'hidden',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <WebView
                                                source={{
                                                  uri: `${newsFeedListData?.file?.url}`,
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    flex: 1,
                                                    minHeight: '100%',
                                                  },
                                                  dimensions.width
                                                )}
                                              />
                                            </View>
                                          )}
                                        </>
                                        {/* CommentView */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { paddingBottom: 5, width: '100%' },
                                            dimensions.width
                                          )}
                                        >
                                          <FlatList
                                            data={
                                              newsFeedListData
                                                ?.last_two_comments?.items
                                            }
                                            keyExtractor={listData =>
                                              listData?.id ||
                                              listData?.uuid ||
                                              JSON.stringify(listData)
                                            }
                                            listKey={JSON.stringify(
                                              newsFeedListData
                                                ?.last_two_comments?.items
                                            )}
                                            numColumns={1}
                                            renderItem={({ item }) => {
                                              const listData = item;
                                              return (
                                                <>
                                                  <Divider
                                                    color={theme.colors.divider}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        height: 1,
                                                        marginTop: 10,
                                                        width: '100%',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  />
                                                  {/* CommentView */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignContent: 'center',
                                                        alignItems: 'center',
                                                        alignSelf: 'stretch',
                                                        flexDirection: 'row',
                                                        marginTop: 8,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* Light BG */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          backgroundColor:
                                                            theme.colors
                                                              .custom_rgb245_245_247,
                                                          borderRadius: 10,
                                                          height: 35,
                                                          justifyContent:
                                                            'center',
                                                          marginRight: 5,
                                                          width: 35,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <CircleImage
                                                        size={30}
                                                        source={{
                                                          uri: `${getImageUrl(
                                                            listData
                                                              ?.comment_author
                                                              ?.profile_image
                                                          )}`,
                                                        }}
                                                      />
                                                    </View>

                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignSelf: 'stretch',
                                                          maxWidth: 270,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* CommentorName */}
                                                      <Text
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              theme.colors
                                                                .secondary,
                                                            fontFamily:
                                                              'Roboto_700Bold',
                                                            marginBottom: 2,
                                                            marginTop: 5,
                                                            textAlign: 'left',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          listData
                                                            ?.comment_author
                                                            ?.name
                                                        }
                                                      </Text>

                                                      <Text
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              theme.colors
                                                                .strong,
                                                            textAlign: 'left',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {listData?.comment_text}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </>
                                              );
                                            }}
                                            style={StyleSheet.applyWidth(
                                              { width: '100%' },
                                              dimensions.width
                                            )}
                                            contentContainerStyle={StyleSheet.applyWidth(
                                              { flex: 1 },
                                              dimensions.width
                                            )}
                                          />
                                        </View>
                                        {/* Engagement */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignSelf: 'stretch',
                                              flexDirection: 'row',
                                              justifyContent: 'space-between',
                                              marginBottom: 10,
                                              marginTop: 3,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* LeftAligned */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flexDirection: 'row' },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Loading Likes */}
                                            <>
                                              {!Constants[
                                                'is_loading'
                                              ] ? null : (
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { flexDirection: 'row' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Touchable>
                                                    <Icon
                                                      color={theme.colors.error}
                                                      name={'AntDesign/sync'}
                                                      size={20}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          marginLeft: 10,
                                                          marginRight: 5,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    />
                                                  </Touchable>

                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors.strong,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'-'}
                                                  </Text>
                                                </View>
                                              )}
                                            </>
                                            {/* Likes */}
                                            <>
                                              {Constants[
                                                'is_loading'
                                              ] ? null : (
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { flexDirection: 'row' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <>
                                                    {newsFeedListData?.liked ? null : (
                                                      <Touchable
                                                        onPress={() => {
                                                          const handler =
                                                            async () => {
                                                              try {
                                                                setGlobalVariableValue(
                                                                  {
                                                                    key: 'is_loading',
                                                                    value: true,
                                                                  }
                                                                );
                                                                (
                                                                  await risingCoachesAddLikePOST.mutateAsync(
                                                                    {
                                                                      feed_post_id:
                                                                        newsFeedListData?.id,
                                                                    }
                                                                  )
                                                                )?.json;
                                                                await refetchGetFeedWithFilter();
                                                                setGlobalVariableValue(
                                                                  {
                                                                    key: 'is_loading',
                                                                    value: false,
                                                                  }
                                                                );
                                                              } catch (err) {
                                                                console.error(
                                                                  err
                                                                );
                                                              }
                                                            };
                                                          handler();
                                                        }}
                                                      >
                                                        <>
                                                          {newsFeedListData?.liked ? null : (
                                                            <Icon
                                                              color={
                                                                theme.colors
                                                                  .error
                                                              }
                                                              name={
                                                                'AntDesign/hearto'
                                                              }
                                                              size={20}
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  marginLeft: 10,
                                                                  marginRight: 5,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            />
                                                          )}
                                                        </>
                                                      </Touchable>
                                                    )}
                                                  </>
                                                  <>
                                                    {!newsFeedListData?.liked ? null : (
                                                      <Touchable
                                                        onPress={() => {
                                                          const handler =
                                                            async () => {
                                                              try {
                                                                setGlobalVariableValue(
                                                                  {
                                                                    key: 'is_loading',
                                                                    value: true,
                                                                  }
                                                                );
                                                                (
                                                                  await risingCoachesRemoveLikeDELETE.mutateAsync(
                                                                    {
                                                                      post_id:
                                                                        newsFeedListData?.id,
                                                                    }
                                                                  )
                                                                )?.json;
                                                                await refetchGetFeedWithFilter();
                                                                setGlobalVariableValue(
                                                                  {
                                                                    key: 'is_loading',
                                                                    value: false,
                                                                  }
                                                                );
                                                              } catch (err) {
                                                                console.error(
                                                                  err
                                                                );
                                                              }
                                                            };
                                                          handler();
                                                        }}
                                                      >
                                                        <>
                                                          {!newsFeedListData?.liked ? null : (
                                                            <Icon
                                                              color={
                                                                theme.colors
                                                                  .error
                                                              }
                                                              name={
                                                                'AntDesign/heart'
                                                              }
                                                              size={20}
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  marginLeft: 10,
                                                                  marginRight: 5,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            />
                                                          )}
                                                        </>
                                                      </Touchable>
                                                    )}
                                                  </>
                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors.strong,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      newsFeedListData?.like_count
                                                    }
                                                  </Text>
                                                </View>
                                              )}
                                            </>
                                            {/* Comments */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                { flexDirection: 'row' },
                                                dimensions.width
                                              )}
                                            >
                                              <Icon
                                                color={theme.colors.medium}
                                                name={
                                                  'Ionicons/chatbox-ellipses'
                                                }
                                                size={20}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    marginLeft: 10,
                                                    marginRight: 5,
                                                  },
                                                  dimensions.width
                                                )}
                                              />
                                              <Text
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color: theme.colors.strong,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {
                                                  newsFeedListData?.number_of_comments_of_news_feed_posts
                                                }
                                              </Text>
                                            </View>
                                          </View>
                                          {/* Right Aligned */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignContent: 'stretch',
                                                alignSelf: 'stretch',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-end',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignSelf: 'flex-end',
                                                  color: theme.colors.strong,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {convertDate(
                                                newsFeedListData?.created_at
                                              )}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* See More */}
                                        <View>
                                          {/* Make a Comment */}
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.primary,
                                                fontFamily: 'Roboto_500Medium',
                                                fontSize: 12,
                                                marginTop: 10,
                                                textAlign: 'left',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'See more'}
                                          </Text>
                                        </View>
                                        {/* SponsoredFileTag */}
                                        <>
                                          {!newsFeedListData?.isFile ? null : (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  bottom: 1,
                                                  justifyContent: 'center',
                                                  maxWidth: '40%',
                                                  minHeight: 50,
                                                  position: 'absolute',
                                                  right: 3,
                                                  width: '40%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <>
                                                {!newsFeedListData?.isSponsored ? null : (
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        backgroundColor:
                                                          theme.colors.primary,
                                                        borderRadius: 8,
                                                        flexDirection: 'row',
                                                        paddingBottom: 6,
                                                        paddingLeft: 12,
                                                        paddingRight: 12,
                                                        paddingTop: 6,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        { flex: 1 },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Text
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignSelf: 'center',
                                                            color:
                                                              theme.colors
                                                                .surface,
                                                            fontFamily:
                                                              'Poppins_500Medium',
                                                            fontSize: 16,
                                                            textAlign: 'center',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'Sponsored'}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )}
                                              </>
                                            </View>
                                          )}
                                        </>
                                      </Surface>
                                    </Touchable>
                                  </>
                                );
                              }}
                              style={StyleSheet.applyWidth(
                                { width: '100%' },
                                dimensions.width
                              )}
                              contentContainerStyle={StyleSheet.applyWidth(
                                {
                                  alignContent: 'center',
                                  alignSelf: 'stretch',
                                  flex: 1,
                                },
                                dimensions.width
                              )}
                            />
                          </>
                        );
                      }}
                    </RisingCoachesApi.FetchGetFeedWithFilterGET>
                  </KeyboardAwareScrollView>
                </View>
              </View>
              <>
                {!isFolderModal ? null : (
                  <Modal
                    animationType={'slide'}
                    presentationStyle={'overFullScreen'}
                    visible={isFolderModal}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          marginRight: 20,
                          marginTop: 20,
                        },
                        dimensions.width
                      )}
                    >
                      <IconButton
                        color={theme.colors.error}
                        icon={'AntDesign/closecircleo'}
                        onPress={() => {
                          try {
                            setIsFolderModal(false);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        size={32}
                      />
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'stretch',
                          alignItems: 'center',
                          alignSelf: 'stretch',
                          justifyContent: 'space-around',
                          marginTop: 100,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: 'Mulish_700Bold',
                            fontSize: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {'Select Which Folder to Save Post To'}
                      </Text>

                      <ScrollView
                        bounces={true}
                        contentContainerStyle={StyleSheet.applyWidth(
                          { justifyContent: 'space-around' },
                          dimensions.width
                        )}
                        showsVerticalScrollIndicator={true}
                      >
                        {/* Folder Fetch */}
                        <RisingCoachesApi.FetchGetUserFoldersGET>
                          {({
                            loading,
                            error,
                            data,
                            refetchGetUserFolders,
                          }) => {
                            const folderFetchData = data?.json;
                            if (loading) {
                              return <ActivityIndicator />;
                            }

                            if (
                              error ||
                              data?.status < 200 ||
                              data?.status >= 300
                            ) {
                              return <ActivityIndicator />;
                            }

                            return (
                              <>
                                <FlatList
                                  contentContainerStyle={StyleSheet.applyWidth(
                                    { flex: 1 },
                                    dimensions.width
                                  )}
                                  data={folderFetchData}
                                  keyExtractor={listData =>
                                    listData?.id ||
                                    listData?.uuid ||
                                    JSON.stringify(listData)
                                  }
                                  listKey={'GQZylF9q'}
                                  numColumns={1}
                                  renderItem={({ item }) => {
                                    const listData = item;
                                    return (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            alignSelf: 'stretch',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            marginBottom: 10,
                                            marginTop: 10,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Button Outline */}
                                        <Button
                                          onPress={() => {
                                            const handler = async () => {
                                              try {
                                                (
                                                  await risingCoachesSaveFeedToFolderPOST.mutateAsync(
                                                    {
                                                      content:
                                                        selectedFeedPost?.image,
                                                      content_title:
                                                        selectedFeedPost?.content_title,
                                                      file_content:
                                                        selectedFeedPost?.file,
                                                      folder_id: listData?.id,
                                                      isFile:
                                                        selectedFeedPost?.isFile,
                                                      isImage:
                                                        selectedFeedPost?.isImage,
                                                      isShared:
                                                        selectedFeedPost?.isShared,
                                                      isVideo:
                                                        selectedFeedPost?.isVideo,
                                                      tags: selectedFeedPost?.tags,
                                                      text_content:
                                                        selectedFeedPost?.text_content,
                                                      uploader_id:
                                                        selectedFeedPost?.author_user_id,
                                                      youtubeId:
                                                        selectedFeedPost?.youtubeId,
                                                    }
                                                  )
                                                )?.json;
                                                setIsFolderModal(false);
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            };
                                            handler();
                                          }}
                                          style={StyleSheet.applyWidth(
                                            {
                                              backgroundColor:
                                                theme.colors['Primary'],
                                              borderRadius: 8,
                                              fontFamily: 'System',
                                              fontWeight: '700',
                                              textAlign: 'center',
                                            },
                                            dimensions.width
                                          )}
                                          title={`${listData?.name}`}
                                        />
                                      </View>
                                    );
                                  }}
                                />
                                <>
                                  {!isEmpty(folderFetchData) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          marginTop: 50,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          { color: theme.colors.strong },
                                          dimensions.width
                                        )}
                                      >
                                        {'You have not created a folder yet. '}
                                      </Text>
                                      {/* Button Outline */}
                                      <Button
                                        onPress={() => {
                                          try {
                                            setIsFolderModal(false);
                                            navigation.navigate(
                                              'ProgramsScreen',
                                              { nav_show_modal: true }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              theme.colors['Primary'],
                                            borderRadius: 8,
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            marginTop: 20,
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                        title={'Create Folder'}
                                      />
                                    </View>
                                  )}
                                </>
                              </>
                            );
                          }}
                        </RisingCoachesApi.FetchGetUserFoldersGET>
                      </ScrollView>
                    </View>
                  </Modal>
                )}
              </>
              {/* Scoreboard Filter Modal */}
              <>
                {!isScoreFilter ? null : (
                  <Modal animationType={'none'} visible={isScoreFilter}>
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'stretch',
                          alignSelf: 'stretch',
                          justifyContent: 'space-around',
                          marginTop: 100,
                        },
                        dimensions.width
                      )}
                    >
                      <RadioButtonGroup
                        onValueChange={newRadioButtonGroupValue => {
                          try {
                            setRadioButtonGroupValue(newRadioButtonGroupValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            marginBottom: 30,
                            marginLeft: 30,
                            marginRight: 30,
                            marginTop: 30,
                          },
                          dimensions.width
                        )}
                      >
                        <RadioButtonRow
                          color={theme.colors.primary}
                          direction={'row'}
                          label={'NCAAM'}
                          unselectedColor={theme.colors.primary}
                          value={1}
                        />
                        <RadioButtonRow
                          color={theme.colors.primary}
                          direction={'row'}
                          label={'NCAAW'}
                          unselectedColor={theme.colors.primary}
                          value={2}
                        />
                        <RadioButtonRow
                          color={theme.colors.primary}
                          label={'NBA'}
                          unselectedColor={theme.colors.primary}
                          value={20}
                        />
                        <RadioButtonRow
                          color={theme.colors.primary}
                          label={'WNBA'}
                          unselectedColor={theme.colors.primary}
                          value={10}
                        />
                        <RadioButtonRow
                          color={theme.colors.primary}
                          label={'G League'}
                          unselectedColor={theme.colors.primary}
                          value={21}
                        />
                      </RadioButtonGroup>
                      {/* Button Solid */}
                      <Button
                        onPress={() => {
                          const handler = async () => {
                            try {
                              undefined;
                              setLeague_id(radioButtonGroupValue);
                              changeScoreTag(radioButtonGroupValue);
                              setIsScoreFilter(false);
                              await refetchGetScores();
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors.primary,
                            borderRadius: 8,
                            fontFamily: 'System',
                            fontWeight: '700',
                            marginLeft: 30,
                            marginRight: 30,
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                        title={'Filter'}
                      />
                    </View>
                  </Modal>
                )}
              </>
            </>
          );
        }}
      </RisingCoachesApi.FetchGetStoriesGET>
    </ScreenContainer>
  );
};

export default withTheme(NewsFeedScreen);
