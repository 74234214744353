import React from 'react';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import selectFileUtil from '../utils/selectFile';
import {
  Button,
  ScreenContainer,
  Spacer,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const ResumeScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const risingCoachesUpdateResumePOST = RisingCoachesApi.useUpdateResumePOST();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.pDFGrey },
        dimensions.width
      )}
    >
      <RisingCoachesApi.FetchGetUserDetailGET
        user_id={props.route?.params?.nav_user_id ?? ''}
      >
        {({ loading, error, data, refetchGetUserDetail }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              <Spacer bottom={8} left={8} right={8} top={8} />
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', justifyContent: 'center' },
                  dimensions.width
                )}
              >
                {/* Button Solid */}
                <>
                  {!fetchData?.isMe ? null : (
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const base_64 = await selectFileUtil({
                              returnNameAndValue: false,
                            });
                            setGlobalVariableValue({
                              key: 'is_loading',
                              value: true,
                            });
                            (
                              await risingCoachesUpdateResumePOST.mutateAsync({
                                file_base_64: base_64,
                                user_id: Constants['user_id'],
                              })
                            )?.json;
                            setGlobalVariableValue({
                              key: 'is_loading',
                              value: false,
                            });
                            await refetchGetUserDetail();
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors.secondary,
                          borderRadius: 8,
                          fontFamily: 'System',
                          fontWeight: '700',
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'Update Resume'}
                    />
                  )}
                </>
              </View>
              <Spacer bottom={8} left={8} right={8} top={8} />
              <>
                {!fetchData?.isResume ? null : (
                  <WebView
                    source={{ uri: `${fetchData?.resume_file?.url}` }}
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  />
                )}
              </>
              {/* No Resume View */}
              <>
                {fetchData?.isResume ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                        flex: 1,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        { alignSelf: 'center', color: theme.colors.surface },
                        dimensions.width
                      )}
                    >
                      {'No Resume Available'}
                    </Text>
                  </View>
                )}
              </>
            </>
          );
        }}
      </RisingCoachesApi.FetchGetUserDetailGET>
    </ScreenContainer>
  );
};

export default withTheme(ResumeScreen);
