import React from 'react';
import * as MagicLinkApi from '../apis/MagicLinkApi.js';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Icon,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import {
  ActivityIndicator,
  Modal,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';

const AccountSettingsScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const risingCoachesDeleteAccountDELETE =
    RisingCoachesApi.useDeleteAccountDELETE();
  const magicLinkChangePasswordPOST = MagicLinkApi.useChangePasswordPOST();

  const [confirm, setConfirm] = React.useState('');
  const [error_message, setError_message] = React.useState('');
  const [isDelete, setIsDelete] = React.useState(false);
  const [isReset, setIsReset] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer hasBottomSafeArea={true} hasTopSafeArea={true}>
      {/* Content Scroll Frame */}
      <ScrollView bounces={true} showsVerticalScrollIndicator={true}>
        {/* Featured Frame */}
        <View
          style={StyleSheet.applyWidth(
            {
              paddingBottom: 18,
              paddingLeft: 21,
              paddingRight: 21,
              paddingTop: 36,
            },
            dimensions.width
          )}
        >
          {/* Title Featured */}
          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors.nFTTimeUIBlack,
                fontFamily: 'System',
                fontSize: 30,
                fontWeight: '600',
              },
              dimensions.width
            )}
          >
            {'Settings'}
          </Text>
        </View>
        {/* App Menu Frame */}
        <View
          style={StyleSheet.applyWidth(
            {
              paddingBottom: 18,
              paddingLeft: 21,
              paddingRight: 21,
              paddingTop: 18,
            },
            dimensions.width
          )}
        >
          {/* Touchable Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  setIsReset(true);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* App Option Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: theme.colors.nFTTIMEBlack,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: 18,
                    paddingTop: 18,
                  },
                  dimensions.width
                )}
              >
                {/* FAQ Text */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.nFTTIMEBlack,
                      fontFamily: 'System',
                      fontSize: 12,
                      fontWeight: '500',
                      textTransform: 'uppercase',
                    },
                    dimensions.width
                  )}
                >
                  {'Reset Password'}
                </Text>
                {/* Icon Flex Attributes */}
                <View>
                  {/* Right Arrow */}
                  <Icon
                    color={theme.colors.nFTTIMEBlack}
                    name={'AntDesign/arrowright'}
                    size={18}
                  />
                </View>
              </View>
            </Touchable>
          </View>
          {/* Touchable Frame */}
          <View>
            <Touchable
              onPress={() => {
                try {
                  setIsDelete(true);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* App Option Frame */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderBottomWidth: 1,
                    borderColor: theme.colors.nFTTIMEBlack,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: 18,
                    paddingTop: 18,
                  },
                  dimensions.width
                )}
              >
                {/* FAQ Text */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Error'],
                      fontFamily: 'System',
                      fontSize: 12,
                      fontWeight: '500',
                      textTransform: 'uppercase',
                    },
                    dimensions.width
                  )}
                >
                  {'Delete Account'}
                </Text>
                {/* Icon Flex Attributes */}
                <View>
                  {/* Right Arrow */}
                  <Icon
                    color={theme.colors.nFTTIMEBlack}
                    name={'AntDesign/arrowright'}
                    size={18}
                  />
                </View>
              </View>
            </Touchable>
          </View>
        </View>
      </ScrollView>
      {/* DeleteModal */}
      <>
        {!isDelete ? null : (
          <Modal animationType={'slide'} visible={isDelete}>
            {/* Main */}
            <View
              style={StyleSheet.applyWidth(
                { alignSelf: 'center', flex: 1, justifyContent: 'center' },
                dimensions.width
              )}
            >
              {/* Header */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 10,
                    marginLeft: 10,
                    marginRight: 10,
                    marginTop: 10,
                  },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      alignSelf: 'center',
                      color: theme.colors['Error'],
                      fontFamily: 'Manrope_700Bold',
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {
                    'Are you sure you want to delete your account? This action cannot be undone!'
                  }
                </Text>
              </View>
              {/* Buttons */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    marginTop: 10,
                  },
                  dimensions.width
                )}
              >
                <Button
                  onPress={() => {
                    try {
                      setIsDelete(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Secondary'],
                      borderRadius: 8,
                      fontFamily: 'System',
                      fontWeight: '700',
                      textAlign: 'center',
                      width: 100,
                    },
                    dimensions.width
                  )}
                  title={'NO'}
                />
                <Button
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setIsDelete(false);
                        navigation.navigate('WelcomeScreen');
                        (
                          await risingCoachesDeleteAccountDELETE.mutateAsync({
                            user_id: Constants['user_id'],
                          })
                        )?.json;
                        setGlobalVariableValue({
                          key: 'auth_header',
                          value: '',
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['Error'],
                      borderRadius: 8,
                      fontFamily: 'System',
                      fontWeight: '700',
                      textAlign: 'center',
                      width: 100,
                    },
                    dimensions.width
                  )}
                  title={'YES'}
                />
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* ResetModal */}
      <>
        {!isReset ? null : (
          <Modal animationType={'slide'} visible={isReset}>
            {/* Main */}
            <View
              style={StyleSheet.applyWidth(
                { alignContent: 'center', flex: 1, justifyContent: 'center' },
                dimensions.width
              )}
            >
              {/* Header */}
              <>
                {Constants['is_loading'] ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignSelf: 'center',
                        marginBottom: 20,
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        { fontFamily: 'Manrope_700Bold', fontSize: 24 },
                        dimensions.width
                      )}
                    >
                      {'Reset Your Password'}
                    </Text>

                    <Text
                      style={StyleSheet.applyWidth(
                        { color: theme.colors['Error'] },
                        dimensions.width
                      )}
                    >
                      {error_message}
                    </Text>
                  </View>
                )}
              </>
              {/* Form */}
              <View
                style={StyleSheet.applyWidth(
                  { marginLeft: 30, marginRight: 30 },
                  dimensions.width
                )}
              >
                {/* Password Input */}
                <TextInput
                  editable={true}
                  onChangeText={newPasswordInputValue => {
                    try {
                      setPassword(newPasswordInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter New Password'}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: theme.colors.divider,
                      borderLeftWidth: 1,
                      borderRadius: 8,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                />
                <Spacer bottom={8} left={8} right={8} top={8} />
                {/* Confirm Input */}
                <TextInput
                  editable={true}
                  onChangeText={newConfirmInputValue => {
                    try {
                      setConfirm(newConfirmInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Confirm New Password'}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: theme.colors.divider,
                      borderLeftWidth: 1,
                      borderRadius: 8,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                />
                <Spacer bottom={8} left={8} right={8} top={8} />
              </View>
              <>
                {Constants['is_loading'] ? null : (
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setGlobalVariableValue({
                            key: 'is_loading',
                            value: true,
                          });
                          const response = (
                            await magicLinkChangePasswordPOST.mutateAsync({
                              confirm: confirm,
                              password: password,
                            })
                          )?.json;
                          const message = response?.message;
                          setError_message(message);
                          setGlobalVariableValue({
                            key: 'is_loading',
                            value: false,
                          });
                          if (message) {
                            return;
                          }
                          undefined;
                          navigation.navigate('WelcomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.primary,
                        borderRadius: 8,
                        fontFamily: 'System',
                        fontWeight: '700',
                        marginBottom: 30,
                        marginLeft: 30,
                        marginRight: 30,
                        marginTop: 30,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'Change Password'}
                  />
                )}
              </>
              <>
                {!Constants['is_loading'] ? null : (
                  <ActivityIndicator
                    animating={true}
                    hidesWhenStopped={true}
                    size={'small'}
                    style={StyleSheet.applyWidth(
                      { height: 36, width: 36 },
                      dimensions.width
                    )}
                  />
                )}
              </>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(AccountSettingsScreen);
