import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import { ScreenContainer, withTheme } from '@draftbit/ui';
import { Text, View, useWindowDimensions } from 'react-native';

const PurchasePendingScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme } = props;

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          flex: 1,
          justifyContent: 'center',
        },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
            margin: 20,
          },
          dimensions.width
        )}
      >
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              color: theme.colors['Darken'],
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {
            'You have been navigated to Stripe to make a purchase. Upon purchase success, navigate back to the home page of the Pure Sweat App.'
          }
        </Text>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(PurchasePendingScreen);
