import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  CircleImage,
  Divider,
  Icon,
  ScreenContainer,
  Spacer,
  TextInput,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  ImageBackground,
  KeyboardAvoidingView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const PostDetailsScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const getImageUrl = userImageObj => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return userImageObj.url;
  };

  const convertDate = dateString => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    // Using moment's inbuilt function to get relative time.
    return CustomCode.moment(dateString).fromNow().toString();
  };

  const risingCoachesCreateCommentPOST =
    RisingCoachesApi.useCreateCommentPOST();

  const [new_comment, setNew_comment] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={true}
    >
      <RisingCoachesApi.FetchGetFeedPostGET
        method={'GET'}
        post_id={props.route?.params?.feed_post_id ?? 2}
      >
        {({ loading, error, data, refetchGetFeedPost }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <KeyboardAvoidingView behavior={'position'} enabled={true}>
              {/* File View */}
              <>
                {!fetchData?.isFile ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 420, width: '100%' },
                      dimensions.width
                    )}
                  >
                    <WebView
                      source={{ uri: `${fetchData?.file?.url}` }}
                      style={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: 24,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 24,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors.primary,
                            borderRadius: 8,
                            flexDirection: 'row',
                            paddingBottom: 6,
                            paddingLeft: 12,
                            paddingRight: 8,
                            paddingTop: 6,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.surface,
                                fontFamily: 'Poppins_500Medium',
                                fontSize: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {fetchData?.content_title}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                )}
              </>
              {/* Video View */}
              <>
                {!fetchData?.isVideo ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 420, width: '100%' },
                      dimensions.width
                    )}
                  >
                    <WebView
                      source={{ uri: `${fetchData?.youtube_render_link}` }}
                      style={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                    />
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: 24,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 24,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors.primary,
                            borderRadius: 8,
                            flexDirection: 'row',
                            paddingBottom: 6,
                            paddingLeft: 12,
                            paddingRight: 8,
                            paddingTop: 6,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.surface,
                                fontFamily: 'Poppins_500Medium',
                                fontSize: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {fetchData?.content_title}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                )}
              </>
              {/* Image View */}
              <>
                {!fetchData?.isImage ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 420, width: '100%' },
                      dimensions.width
                    )}
                  >
                    <ImageBackground
                      resizeMode={'cover'}
                      source={{ uri: `${fetchData?.image?.url}` }}
                      style={StyleSheet.applyWidth(
                        {
                          height: '100%',
                          justifyContent: 'flex-end',
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            paddingBottom: 24,
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingTop: 24,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: theme.colors.primary,
                              borderRadius: 8,
                              flexDirection: 'row',
                              paddingBottom: 6,
                              paddingLeft: 12,
                              paddingRight: 8,
                              paddingTop: 6,
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <Text
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.surface,
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {fetchData?.content_title}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </ImageBackground>
                  </View>
                )}
              </>
              {/* Text View */}
              <>
                {!fetchData?.isText ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '100%' },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingBottom: 24,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 24,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors.primary,
                            borderRadius: 8,
                            flexDirection: 'row',
                            paddingBottom: 6,
                            paddingLeft: 12,
                            paddingRight: 8,
                            paddingTop: 6,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors.surface,
                                fontFamily: 'Poppins_500Medium',
                                fontSize: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {fetchData?.content_title}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                )}
              </>
              <View>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      paddingBottom: 24,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 0,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.light,
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {fetchData?.city}
                  </Text>

                  <Text
                    allowFontScaling={true}
                    ellipsizeMode={'tail'}
                    numberOfLines={2}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 22,
                      },
                      dimensions.width
                    )}
                    textBreakStrategy={'highQuality'}
                  >
                    {fetchData?.name}
                  </Text>
                  <Spacer bottom={8} left={8} right={8} top={8} />
                  <View>
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            alignSelf: 'stretch',
                            borderBottomWidth: 1,
                            borderColor: theme.colors.lightest,
                            borderLeftWidth: 1,
                            borderRadius: 8,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingBottom: 8,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={theme.colors.primary}
                          name={'AntDesign/heart'}
                          size={24}
                        />
                        <Spacer left={2} right={2} />
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.medium,
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {fetchData?.like_count}
                        </Text>
                      </View>
                      <Spacer bottom={8} left={6} right={6} top={8} />
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            alignSelf: 'stretch',
                            borderBottomWidth: 1,
                            borderColor: theme.colors.lightest,
                            borderLeftWidth: 1,
                            borderRadius: 8,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingBottom: 8,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={theme.colors.primary}
                          name={'FontAwesome/commenting'}
                          size={20}
                        />
                        <Spacer left={2} right={2} />
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.medium,
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {fetchData?.number_of_comments_of_news_feed_posts}
                        </Text>
                      </View>
                      <Spacer bottom={8} left={6} right={6} top={8} />
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors.lightest,
                            borderLeftWidth: 1,
                            borderRadius: 8,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingBottom: 8,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                      >
                        <Spacer left={2} right={2} />
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.medium,
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {convertDate(fetchData?.created_at)}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <Spacer bottom={12} left={8} right={8} top={12} />
                  <View>
                    <Spacer bottom={6} left={8} right={8} top={6} />
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.medium,
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 14,
                          lineHeight: 26,
                          textAlign: 'left',
                        },
                        dimensions.width
                      )}
                    >
                      {fetchData?.text_content}
                    </Text>
                  </View>
                  <Spacer bottom={12} left={8} right={8} top={12} />
                  {/* Comments */}
                  <View>
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Poppins_600SemiBold',
                          fontSize: 16,
                        },
                        dimensions.width
                      )}
                    >
                      {'Comments'}
                    </Text>
                    <Spacer bottom={6} left={8} right={8} top={6} />
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      <TextInput
                        multiline={true}
                        onChangeText={newTextInputValue => {
                          try {
                            setNew_comment(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Leave a comment...'}
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: theme.colors.divider,
                            borderLeftWidth: 1,
                            borderRadius: 8,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 8,
                            width: '70%',
                          },
                          dimensions.width
                        )}
                        value={new_comment}
                      />
                      {/* Button Solid */}
                      <>
                        {Constants['is_loading'] ? null : (
                          <Button
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  setGlobalVariableValue({
                                    key: 'is_loading',
                                    value: true,
                                  });
                                  setNew_comment('');
                                  (
                                    await risingCoachesCreateCommentPOST.mutateAsync(
                                      {
                                        author_user_id: Constants['user_id'],
                                        comment_text: new_comment,
                                        feed_post_id:
                                          props.route?.params?.feed_post_id ??
                                          2,
                                      }
                                    )
                                  )?.json;
                                  await refetchGetFeedPost();
                                  setGlobalVariableValue({
                                    key: 'is_loading',
                                    value: false,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors.primary,
                                borderRadius: 8,
                                fontFamily: 'System',
                                fontWeight: '700',
                                textAlign: 'center',
                              },
                              dimensions.width
                            )}
                            title={'Submit'}
                          />
                        )}
                      </>
                      {/* Button Solid */}
                      <>
                        {!Constants['is_loading'] ? null : (
                          <Button
                            disabled={true}
                            loading={true}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors.primary,
                                borderRadius: 8,
                                fontFamily: 'System',
                                fontWeight: '700',
                                textAlign: 'center',
                                width: 80,
                              },
                              dimensions.width
                            )}
                            title={''}
                          />
                        )}
                      </>
                    </View>
                    <FlatList
                      data={
                        fetchData && fetchData['_comments_of_news_feed_posts']
                      }
                      keyExtractor={listData =>
                        listData?.id ||
                        listData?.uuid ||
                        JSON.stringify(listData)
                      }
                      listKey={'0mUs04gM'}
                      numColumns={1}
                      renderItem={({ item }) => {
                        const listData = item;
                        return (
                          <>
                            <Divider
                              color={theme.colors.divider}
                              style={StyleSheet.applyWidth(
                                { height: 1, marginTop: 10, width: '100%' },
                                dimensions.width
                              )}
                            />
                            {/* CommentView */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'stretch',
                                  flexDirection: 'row',
                                  marginTop: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Light BG */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      theme.colors.custom_rgb245_245_247,
                                    borderRadius: 10,
                                    height: 35,
                                    justifyContent: 'center',
                                    marginRight: 5,
                                    width: 35,
                                  },
                                  dimensions.width
                                )}
                              >
                                <CircleImage
                                  size={30}
                                  source={{
                                    uri: `${listData?.commenting_user?.profile_image?.url}`,
                                  }}
                                />
                              </View>

                              <View
                                style={StyleSheet.applyWidth(
                                  { alignSelf: 'flex-start' },
                                  dimensions.width
                                )}
                              >
                                {/* CommentorName */}
                                <Text
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: theme.colors.secondary,
                                      fontFamily: 'Roboto_700Bold',
                                      marginBottom: 2,
                                      marginTop: 5,
                                      textAlign: 'left',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {listData?.commenting_user?.name}
                                </Text>

                                <Text
                                  style={StyleSheet.applyWidth(
                                    { color: theme.colors.strong },
                                    dimensions.width
                                  )}
                                >
                                  {listData?.comment_text}
                                </Text>
                              </View>
                            </View>
                          </>
                        );
                      }}
                      style={StyleSheet.applyWidth(
                        { width: '100%' },
                        dimensions.width
                      )}
                      contentContainerStyle={StyleSheet.applyWidth(
                        { flex: 1 },
                        dimensions.width
                      )}
                    />
                    <Divider
                      color={theme.colors.lightest}
                      style={StyleSheet.applyWidth(
                        { height: 1, marginBottom: 12, marginTop: 12 },
                        dimensions.width
                      )}
                    />
                  </View>
                  <Spacer bottom={16} left={8} right={8} top={16} />
                </View>
              </View>
            </KeyboardAvoidingView>
          );
        }}
      </RisingCoachesApi.FetchGetFeedPostGET>
    </ScreenContainer>
  );
};

export default withTheme(PostDetailsScreen);
