import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as StripeApi from '../apis/StripeApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import isEmpty from '../global-functions/isEmpty';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Checkbox,
  Circle,
  CircleImage,
  Icon,
  IconButton,
  ScreenContainer,
  Spacer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { MasonryFlashList } from '@shopify/flash-list';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const HomeScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const stripeStripeSessionPOST = StripeApi.useStripeSessionPOST();
  const risingCoachesCreateFolderPOST = RisingCoachesApi.useCreateFolderPOST();
  const risingCoachesCreateAdminFolderPOST =
    RisingCoachesApi.useCreateAdminFolderPOST();
  const risingCoachesDeleteFolderDELETE =
    RisingCoachesApi.useDeleteFolderDELETE();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setGlobalVariableValue({
          key: 'is_loading',
          value: true,
        });
        setShowModal(props.route?.params?.nav_show_modal ?? '');
        const user = (
          await RisingCoachesApi.getUserDetailGET(Constants, {
            user_id: Constants['user_id'],
          })
        )?.json;
        const admin_result = user?.isAdmin;
        setIsAdmin(admin_result);
        setGlobalVariableValue({
          key: 'is_loading',
          value: false,
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);
  const [folderName, setFolderName] = React.useState('');
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isDeleteFolder, setIsDeleteFolder] = React.useState(false);
  const [isFolders, setIsFolders] = React.useState(false);
  const [isPurchasing, setIsPurchasing] = React.useState(false);
  const [pickerValue, setPickerValue] = React.useState('');
  const [programs, setPrograms] = React.useState([]);
  const [selectedFolderId, setSelectedFolderId] = React.useState(0);
  const [selectedModules, setSelectedModules] = React.useState([]);
  const [selectedProgramID, setSelectedProgramID] = React.useState(0);
  const [selectedProgramName, setSelectedProgramName] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [showNav, setShowNav] = React.useState(false);
  const [showPersonal, setShowPersonal] = React.useState(true);
  const [showRising, setShowRising] = React.useState(false);
  const [showShared, setShowShared] = React.useState(false);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors.background, flexDirection: 'column' },
        dimensions.width
      )}
    >
      <RisingCoachesApi.FetchGetUserDetailGET user_id={Constants['user_id']}>
        {({ loading, error, data, refetchGetUserDetail }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* Verified */}
              <>
                {!fetchData?.verified ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignSelf: 'stretch', flex: 1 },
                      dimensions.width
                    )}
                  >
                    {/* Home Menu */}
                    <>
                      {!showNav ? null : (
                        <Surface
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SurfaceStyles(theme)['Home Menu'],
                              { backgroundColor: theme.colors['Surface'] }
                            ),
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: theme.colors.secondary,
                                paddingBottom: 24,
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 72,
                              },
                              dimensions.width
                            )}
                          >
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate('MemberDetailScreen', {
                                    navigate_user_Id: Constants['user_id'],
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Circle
                                  bgColor={theme.colors.surface}
                                  size={64}
                                >
                                  <>
                                    {!Constants['user_image'] ? null : (
                                      <CircleImage
                                        size={80}
                                        source={{
                                          uri: `${Constants['user_image']}`,
                                        }}
                                      />
                                    )}
                                  </>
                                </Circle>

                                <View
                                  style={StyleSheet.applyWidth(
                                    { flex: 1, marginLeft: 12 },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors.surface,
                                        fontFamily: 'System',
                                        fontSize: 18,
                                        fontWeight: '400',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {Constants['user_name']}
                                  </Text>
                                </View>
                              </View>
                            </Touchable>
                          </View>

                          <ScrollView
                            bounces={true}
                            showsVerticalScrollIndicator={false}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { flex: 1, paddingBottom: 16, paddingTop: 16 },
                                dimensions.width
                              )}
                            >
                              {/* Home */}
                              <Touchable
                                onPress={() => {
                                  try {
                                    setShowNav(false);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      paddingBottom: 12,
                                      paddingLeft: 24,
                                      paddingRight: 24,
                                      paddingTop: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Icon name={'Feather/home'} size={24} />
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors.strong,
                                        fontFamily: 'System',
                                        fontSize: 16,
                                        fontWeight: '400',
                                        marginLeft: 8,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Home'}
                                  </Text>
                                </View>
                              </Touchable>
                              {/* Repository */}
                              <Touchable
                                onPress={() => {
                                  try {
                                    setShowNav(false);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      paddingBottom: 12,
                                      paddingLeft: 24,
                                      paddingRight: 24,
                                      paddingTop: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Icon name={'Feather/folder'} size={24} />
                                  {/* Content Repository */}
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors.strong,
                                        fontFamily: 'System',
                                        fontSize: 16,
                                        fontWeight: '400',
                                        marginLeft: 8,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Programs'}
                                  </Text>
                                </View>
                              </Touchable>
                              {/* Settings */}
                              <Touchable
                                onPress={() => {
                                  try {
                                    navigation.navigate(
                                      'AccountSettingsScreen'
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      paddingBottom: 12,
                                      paddingLeft: 24,
                                      paddingRight: 24,
                                      paddingTop: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Icon name={'AntDesign/setting'} size={24} />
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors.strong,
                                        fontFamily: 'System',
                                        fontSize: 16,
                                        fontWeight: '400',
                                        marginLeft: 8,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Settings'}
                                  </Text>
                                </View>
                              </Touchable>
                              {/* Logout */}
                              <Touchable
                                onPress={() => {
                                  try {
                                    setGlobalVariableValue({
                                      key: 'auth_header',
                                      value: '',
                                    });
                                    navigation.navigate('WelcomeScreen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      paddingBottom: 12,
                                      paddingLeft: 24,
                                      paddingRight: 24,
                                      paddingTop: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Icon name={'Feather/log-out'} size={24} />
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors.strong,
                                        fontFamily: 'System',
                                        fontSize: 16,
                                        fontWeight: '400',
                                        marginLeft: 8,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Sign out'}
                                  </Text>
                                </View>
                              </Touchable>
                            </View>
                          </ScrollView>
                        </Surface>
                      )}
                    </>
                    {/* Home Header */}
                    <View
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ViewStyles(theme)['Home Header'],
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingTop: 40,
                          },
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'contain'}
                          source={Images.PsLogoWhite}
                          style={StyleSheet.applyWidth(
                            { height: 88, width: 100 },
                            dimensions.width
                          )}
                        />
                        <Checkbox
                          checkedIcon={'Feather/x'}
                          color={theme.colors.primary}
                          onPress={newCheckboxValue => {
                            try {
                              setShowNav(newCheckboxValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          size={32}
                          status={showNav}
                          uncheckedColor={theme.colors.custom_rgb255_255_255}
                          uncheckedIcon={'Feather/menu'}
                        />
                      </View>
                    </View>
                    {/* My Programs */}
                    <>
                      {isFolders ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              margin: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Left View */}
                          <View>
                            <Text
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    fontFamily: 'Manrope_700Bold',
                                    fontSize: 18,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'My Programs'}
                            </Text>
                          </View>
                        </View>
                      )}
                    </>
                    {/* OwnedFetch */}
                    <>
                      {Constants['is_loading'] ? null : (
                        <RisingCoachesApi.FetchGetUnownedProgramsGET>
                          {({
                            loading,
                            error,
                            data,
                            refetchGetUnownedPrograms,
                          }) => {
                            const ownedFetchData = data?.json;
                            if (loading) {
                              return <ActivityIndicator />;
                            }

                            if (
                              error ||
                              data?.status < 200 ||
                              data?.status >= 300
                            ) {
                              return <ActivityIndicator />;
                            }

                            return (
                              <>
                                {isFolders ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  >
                                    {/* No Data */}
                                    <>
                                      {!isEmpty(
                                        ownedFetchData &&
                                          ownedFetchData['_owned']
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignContent: 'center',
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              justifyContent: 'center',
                                              marginBottom: 20,
                                              marginTop: 20,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: theme.colors.light,
                                                textAlign: 'center',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              'Please purchase a program below to continue.'
                                            }
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                    {/* owned List */}
                                    <>
                                      {isFolders ? null : (
                                        <MasonryFlashList
                                          data={
                                            ownedFetchData &&
                                            ownedFetchData['_owned']
                                          }
                                          estimatedItemSize={30}
                                          keyExtractor={ownedListData =>
                                            ownedListData?.id
                                          }
                                          listKey={'5Dg5CamH'}
                                          numColumns={2}
                                          onEndReachedThreshold={0.5}
                                          renderItem={({ item }) => {
                                            const ownedListData = item;
                                            return (
                                              <>
                                                {!ownedListData?.isPublic ? null : (
                                                  <Touchable
                                                    onPress={() => {
                                                      const handler =
                                                        async () => {
                                                          try {
                                                            setGlobalVariableValue(
                                                              {
                                                                key: 'is_loading',
                                                                value: true,
                                                              }
                                                            );
                                                            setIsFolders(true);
                                                            setSelectedProgramID(
                                                              ownedListData?.id
                                                            );
                                                            const results = (
                                                              await RisingCoachesApi.getFoldersByProgramIDGET(
                                                                Constants,
                                                                {
                                                                  program_id:
                                                                    ownedListData?.id,
                                                                }
                                                              )
                                                            )?.json;
                                                            setSelectedModules(
                                                              results
                                                            );
                                                            setSelectedProgramName(
                                                              ownedListData?.title
                                                            );
                                                            setGlobalVariableValue(
                                                              {
                                                                key: 'is_loading',
                                                                value: false,
                                                              }
                                                            );
                                                          } catch (err) {
                                                            console.error(err);
                                                          }
                                                        };
                                                      handler();
                                                    }}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          paddingBottom: 20,
                                                          paddingLeft: 20,
                                                          paddingRight: 20,
                                                          paddingTop: 20,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Image
                                                        resizeMode={'contain'}
                                                        source={{
                                                          uri: `${ownedListData?.background_img?.url}`,
                                                        }}
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.ImageStyles(
                                                              theme
                                                            )['Image'],
                                                            {
                                                              borderColor:
                                                                theme.colors[
                                                                  'Divider'
                                                                ],
                                                              borderRadius: 11,
                                                              borderWidth: 1,
                                                              height: 160,
                                                              width: 160,
                                                            }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                      />
                                                      <Text
                                                        ellipsizeMode={'tail'}
                                                        numberOfLines={1}
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )['Text'],
                                                            {
                                                              alignSelf:
                                                                'center',
                                                              fontSize: 9,
                                                              textAlign:
                                                                'center',
                                                            }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {ownedListData?.title}
                                                      </Text>
                                                    </View>
                                                  </Touchable>
                                                )}
                                              </>
                                            );
                                          }}
                                          showsHorizontalScrollIndicator={false}
                                          showsVerticalScrollIndicator={true}
                                        />
                                      )}
                                    </>
                                    <>
                                      {isFolders ? null : (
                                        <View>
                                          {/* My Programs */}
                                          <>
                                            {!(
                                              ownedFetchData?._unowned?.length >
                                              0
                                            ) ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-between',
                                                    margin: 12,
                                                    marginBottom: 12,
                                                    marginLeft: 12,
                                                    marginRight: 12,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Left View */}
                                                <View>
                                                  <Text
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'],
                                                        {
                                                          fontFamily:
                                                            'Manrope_700Bold',
                                                          fontSize: 18,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'Purchase Programs'}
                                                  </Text>
                                                </View>
                                              </View>
                                            )}
                                          </>
                                          {/* UnOwned List */}
                                          <>
                                            {isFolders ? null : (
                                              <MasonryFlashList
                                                data={
                                                  ownedFetchData &&
                                                  ownedFetchData['_unowned']
                                                }
                                                keyExtractor={unOwnedListData =>
                                                  unOwnedListData?.id ||
                                                  unOwnedListData?.uuid ||
                                                  JSON.stringify(
                                                    unOwnedListData
                                                  )
                                                }
                                                listKey={'cXe8WT02'}
                                                numColumns={2}
                                                onEndReachedThreshold={0.5}
                                                renderItem={({ item }) => {
                                                  const unOwnedListData = item;
                                                  return (
                                                    <Touchable
                                                      onPress={() => {
                                                        const handler =
                                                          async () => {
                                                            try {
                                                              setGlobalVariableValue(
                                                                {
                                                                  key: 'is_loading',
                                                                  value: true,
                                                                }
                                                              );
                                                              const result = (
                                                                await stripeStripeSessionPOST.mutateAsync(
                                                                  {
                                                                    fail_url:
                                                                      'https://www.puresweatbasketball.com/failed ',
                                                                    product_id:
                                                                      unOwnedListData?.stripe_id,
                                                                    success_url:
                                                                      'https://www.puresweatbasketball.com/success',
                                                                  }
                                                                )
                                                              )?.json;
                                                              console.log(
                                                                result
                                                              );
                                                              navigation.navigate(
                                                                'PurchasePendingScreen'
                                                              );
                                                              await WebBrowser.openBrowserAsync(
                                                                `${result?.url}`
                                                              );
                                                              setGlobalVariableValue(
                                                                {
                                                                  key: 'is_loading',
                                                                  value: false,
                                                                }
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          };
                                                        handler();
                                                      }}
                                                    >
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            paddingBottom: 20,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            paddingTop: 20,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <Image
                                                          resizeMode={'contain'}
                                                          source={{
                                                            uri: `${unOwnedListData?.background_img?.url}`,
                                                          }}
                                                          style={StyleSheet.applyWidth(
                                                            StyleSheet.compose(
                                                              GlobalStyles.ImageStyles(
                                                                theme
                                                              )['Image'],
                                                              {
                                                                borderColor:
                                                                  theme.colors[
                                                                    'Divider'
                                                                  ],
                                                                borderRadius: 11,
                                                                borderWidth: 1,
                                                                height: 160,
                                                                width: 160,
                                                              }
                                                            ),
                                                            dimensions.width
                                                          )}
                                                        />
                                                        <Text
                                                          ellipsizeMode={'tail'}
                                                          numberOfLines={1}
                                                          style={StyleSheet.applyWidth(
                                                            StyleSheet.compose(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )['Text'],
                                                              {
                                                                alignSelf:
                                                                  'center',
                                                                fontSize: 9,
                                                                textAlign:
                                                                  'center',
                                                              }
                                                            ),
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            unOwnedListData?.title
                                                          }
                                                        </Text>
                                                      </View>
                                                    </Touchable>
                                                  );
                                                }}
                                                showsHorizontalScrollIndicator={
                                                  false
                                                }
                                                showsVerticalScrollIndicator={
                                                  true
                                                }
                                              />
                                            )}
                                          </>
                                        </View>
                                      )}
                                    </>
                                  </View>
                                )}
                              </>
                            );
                          }}
                        </RisingCoachesApi.FetchGetUnownedProgramsGET>
                      )}
                    </>
                    {/* isFolders */}
                    <>
                      {!isFolders ? null : (
                        <View>
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                alignSelf: 'flex-start',
                              },
                              dimensions.width
                            )}
                          >
                            <Button
                              onPress={() => {
                                try {
                                  setIsFolders(false);
                                  setSelectedModules('');
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button'],
                                  {
                                    backgroundColor: theme.colors['Secondary'],
                                    marginBottom: 5,
                                    marginLeft: 20,
                                    marginRight: 20,
                                    marginTop: 20,
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Back'}
                            />
                          </View>
                          <>
                            {!showModal ? null : (
                              <Modal
                                animationType={'slide'}
                                visible={showModal}
                              >
                                <>
                                  {!'Cancel' ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          justifyContent: 'center',
                                          marginBottom: 50,
                                          marginLeft: 50,
                                          marginRight: 50,
                                          marginTop: 100,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <TextInput
                                        onChangeText={newTextInputValue => {
                                          try {
                                            setFolderName(newTextInputValue);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        placeholder={'Folder Name'}
                                        placeholderTextColor={
                                          theme.colors.light
                                        }
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: theme.colors.divider,
                                            borderLeftWidth: 1,
                                            borderRadius: 8,
                                            borderRightWidth: 1,
                                            borderTopWidth: 1,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            paddingRight: 8,
                                            paddingTop: 8,
                                          },
                                          dimensions.width
                                        )}
                                        value={folderName}
                                      />
                                      <Spacer
                                        bottom={8}
                                        left={8}
                                        right={8}
                                        top={8}
                                      />
                                      {/* isPersonal */}
                                      <>
                                        {!showPersonal ? null : (
                                          <View>
                                            <View>
                                              {/* Normal Button Solid */}
                                              <>
                                                {Constants[
                                                  'is_loading'
                                                ] ? null : (
                                                  <Button
                                                    onPress={() => {
                                                      const handler =
                                                        async () => {
                                                          try {
                                                            setGlobalVariableValue(
                                                              {
                                                                key: 'is_loading',
                                                                value: true,
                                                              }
                                                            );
                                                            (
                                                              await risingCoachesCreateFolderPOST.mutateAsync(
                                                                {
                                                                  folder_name:
                                                                    folderName,
                                                                  program_id:
                                                                    selectedProgramID,
                                                                }
                                                              )
                                                            )?.json;
                                                            setShowModal(false);
                                                            setFolderName('');
                                                            setGlobalVariableValue(
                                                              {
                                                                key: 'is_loading',
                                                                value: false,
                                                              }
                                                            );
                                                          } catch (err) {
                                                            console.error(err);
                                                          }
                                                        };
                                                      handler();
                                                    }}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        backgroundColor:
                                                          theme.colors.primary,
                                                        borderRadius: 8,
                                                        fontFamily: 'System',
                                                        fontWeight: '700',
                                                        textAlign: 'center',
                                                      },
                                                      dimensions.width
                                                    )}
                                                    title={'Create'}
                                                  />
                                                )}
                                              </>
                                            </View>
                                          </View>
                                        )}
                                      </>
                                      {/* Button Loading */}
                                      <>
                                        {!Constants['is_loading'] ? null : (
                                          <Button
                                            disabled={false}
                                            loading={true}
                                            style={StyleSheet.applyWidth(
                                              {
                                                backgroundColor:
                                                  theme.colors.primary,
                                                borderRadius: 8,
                                                fontFamily: 'System',
                                                fontWeight: '700',
                                                textAlign: 'center',
                                              },
                                              dimensions.width
                                            )}
                                            title={''}
                                          />
                                        )}
                                      </>
                                      <Spacer
                                        bottom={8}
                                        left={8}
                                        right={8}
                                        top={8}
                                      />
                                      {/* Button Outline */}
                                      <Button
                                        onPress={() => {
                                          try {
                                            setShowModal(false);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor: 'transparent',
                                            borderColor: theme.colors['Error'],
                                            borderRadius: 8,
                                            borderWidth: 1,
                                            color: theme.colors['Error'],
                                            fontFamily: 'System',
                                            fontWeight: '700',
                                            textAlign: 'center',
                                          },
                                          dimensions.width
                                        )}
                                        title={'Cancel'}
                                      />
                                    </View>
                                  )}
                                </>
                              </Modal>
                            )}
                          </>
                          {/* isAdmin */}
                          <>
                            {!Constants['is_admin'] ? null : (
                              <View>
                                {/* Admin Folder View */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      justifyContent: 'flex-end',
                                      marginBottom: 5,
                                      marginLeft: 10,
                                      marginRight: 10,
                                      marginTop: 5,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        setShowModal(true);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Add */}
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors.strong,
                                            fontFamily: 'Roboto_400Regular',
                                            fontSize: 12,
                                            marginRight: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Create New Folder'}
                                      </Text>
                                      <IconButton
                                        icon={'Ionicons/add-circle-sharp'}
                                        onPress={() => {
                                          try {
                                            setShowModal(true);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        size={32}
                                      />
                                    </View>
                                  </Touchable>
                                </View>
                              </View>
                            )}
                          </>
                          {/* Personal Fetch */}
                          <>
                            {Constants['is_loading'] ? null : (
                              <RisingCoachesApi.FetchGetProgramFoldersGET
                                method={'GET'}
                                program_name={selectedProgramName}
                              >
                                {({
                                  loading,
                                  error,
                                  data,
                                  refetchGetProgramFolders,
                                }) => {
                                  const personalFetchData = data?.json;
                                  if (loading) {
                                    return <ActivityIndicator />;
                                  }

                                  if (
                                    error ||
                                    data?.status < 200 ||
                                    data?.status >= 300
                                  ) {
                                    return <ActivityIndicator />;
                                  }

                                  return (
                                    <>
                                      {/* Delete Modal */}
                                      <>
                                        {!isDeleteFolder ? null : (
                                          <Modal
                                            animationType={'none'}
                                            visible={isDeleteFolder}
                                          >
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignContent: 'center',
                                                  alignItems: 'center',
                                                  alignSelf: 'center',
                                                  flex: 1,
                                                  justifyContent: 'center',
                                                  marginBottom: 30,
                                                  marginLeft: 30,
                                                  marginRight: 30,
                                                  marginTop: 30,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { alignItems: 'center' },
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        theme.colors.strong,
                                                      fontFamily:
                                                        'Manrope_700Bold',
                                                      textAlign: 'center',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    'Are you sure you want to delete this folder? All contents will be lost. '
                                                  }
                                                </Text>
                                              </View>

                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignSelf: 'stretch',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-around',
                                                    marginTop: 30,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Button Solid */}
                                                <Button
                                                  onPress={() => {
                                                    try {
                                                      setIsDeleteFolder(false);
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  }}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      backgroundColor:
                                                        theme.colors.light,
                                                      borderRadius: 8,
                                                      fontFamily: 'System',
                                                      fontWeight: '700',
                                                      minWidth: 150,
                                                      textAlign: 'center',
                                                    },
                                                    dimensions.width
                                                  )}
                                                  title={'No'}
                                                />
                                                {/* Button Solid */}
                                                <>
                                                  {Constants[
                                                    'is_loading'
                                                  ] ? null : (
                                                    <Button
                                                      onPress={() => {
                                                        const handler =
                                                          async () => {
                                                            try {
                                                              setGlobalVariableValue(
                                                                {
                                                                  key: 'is_loading',
                                                                  value: true,
                                                                }
                                                              );
                                                              (
                                                                await risingCoachesDeleteFolderDELETE.mutateAsync(
                                                                  {
                                                                    folders_id:
                                                                      selectedFolderId,
                                                                  }
                                                                )
                                                              )?.json;
                                                              setIsDeleteFolder(
                                                                false
                                                              );
                                                              await refetchGetProgramFolders();
                                                              setGlobalVariableValue(
                                                                {
                                                                  key: 'is_loading',
                                                                  value: false,
                                                                }
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          };
                                                        handler();
                                                      }}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          backgroundColor:
                                                            theme.colors
                                                              .primary,
                                                          borderRadius: 8,
                                                          fontFamily: 'System',
                                                          fontWeight: '700',
                                                          minWidth: 150,
                                                          textAlign: 'center',
                                                        },
                                                        dimensions.width
                                                      )}
                                                      title={'Yes'}
                                                    />
                                                  )}
                                                </>
                                                {/* Button Solid */}
                                                <>
                                                  {!Constants[
                                                    'is_loading'
                                                  ] ? null : (
                                                    <Button
                                                      disabled={true}
                                                      loading={true}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          backgroundColor:
                                                            theme.colors
                                                              .primary,
                                                          borderRadius: 8,
                                                          fontFamily: 'System',
                                                          fontWeight: '700',
                                                          minWidth: 150,
                                                          textAlign: 'center',
                                                        },
                                                        dimensions.width
                                                      )}
                                                      title={'Yes'}
                                                    />
                                                  )}
                                                </>
                                              </View>
                                            </View>
                                          </Modal>
                                        )}
                                      </>
                                      {/* Personal Grid */}
                                      <>
                                        {!isFolders ? null : (
                                          <FlatList
                                            data={personalFetchData}
                                            keyExtractor={personalGridData =>
                                              personalGridData?.id ||
                                              personalGridData?.uuid ||
                                              JSON.stringify(personalGridData)
                                            }
                                            listKey={'VZRJ9Wqb'}
                                            numColumns={2}
                                            renderItem={({ item }) => {
                                              const personalGridData = item;
                                              return (
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      maxWidth: '50%',
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      paddingRight: 8,
                                                      paddingTop: 8,
                                                      width: '50%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Touchable
                                                    onPress={() => {
                                                      try {
                                                        navigation.navigate(
                                                          'FolderContentsScreen',
                                                          {
                                                            folder_name:
                                                              personalGridData?.name,
                                                            isShared:
                                                              showShared,
                                                            folder_id:
                                                              personalGridData?.id,
                                                          }
                                                        );
                                                      } catch (err) {
                                                        console.error(err);
                                                      }
                                                    }}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          backgroundColor:
                                                            theme.colors
                                                              .surface,
                                                          borderBottomWidth: 1,
                                                          borderColor:
                                                            theme.colors
                                                              .divider,
                                                          borderLeftWidth: 1,
                                                          borderRadius: 12,
                                                          borderRightWidth: 1,
                                                          borderTopWidth: 1,
                                                          overflow: 'hidden',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignContent:
                                                              'center',
                                                            alignItems:
                                                              'center',
                                                            backgroundColor:
                                                              theme.colors
                                                                .secondary,
                                                            height: 140,
                                                            justifyContent:
                                                              'center',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <>
                                                          {!personalGridData?.background_image ? null : (
                                                            <Image
                                                              resizeMode={
                                                                'cover'
                                                              }
                                                              source={{
                                                                uri: `${personalGridData?.background_image.url}`,
                                                              }}
                                                              style={StyleSheet.applyWidth(
                                                                StyleSheet.compose(
                                                                  GlobalStyles.ImageStyles(
                                                                    theme
                                                                  )['Image'],
                                                                  {
                                                                    borderColor:
                                                                      theme
                                                                        .colors[
                                                                        'Divider'
                                                                      ],
                                                                    borderTopLeftRadius: 11,
                                                                    borderTopRightRadius: 11,
                                                                    height:
                                                                      '100%',
                                                                    width:
                                                                      '100%',
                                                                  }
                                                                ),
                                                                dimensions.width
                                                              )}
                                                            />
                                                          )}
                                                        </>
                                                        <>
                                                          {personalGridData?.background_image ? null : (
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    'center',
                                                                  alignSelf:
                                                                    'center',
                                                                  justifyContent:
                                                                    'center',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Text
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignSelf:
                                                                      'center',
                                                                    color:
                                                                      theme
                                                                        .colors
                                                                        .surface,
                                                                    fontFamily:
                                                                      'Roboto_700Bold',
                                                                    textAlign:
                                                                      'center',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {
                                                                  personalGridData?.name
                                                                }
                                                              </Text>
                                                            </View>
                                                          )}
                                                        </>
                                                      </View>

                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            flex: 1,
                                                            justifyContent:
                                                              'space-between',
                                                            paddingBottom: 12,
                                                            paddingLeft: 12,
                                                            paddingRight: 12,
                                                            paddingTop: 12,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <Text
                                                          ellipsizeMode={'tail'}
                                                          numberOfLines={1}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              color:
                                                                theme.colors
                                                                  .strong,
                                                              fontFamily:
                                                                'Poppins_600SemiBold',
                                                              fontSize: 12,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            personalGridData
                                                              ?.properties?.name
                                                          }{' '}
                                                        </Text>

                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                'center',
                                                              flexDirection:
                                                                'row',
                                                              justifyContent:
                                                                'space-between',
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                alignItems:
                                                                  'center',
                                                                flexDirection:
                                                                  'row',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <Text
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  color:
                                                                    theme.colors
                                                                      .primary,
                                                                  fontFamily:
                                                                    'Poppins_600SemiBold',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {personalGridData &&
                                                                personalGridData[
                                                                  '_contentcount'
                                                                ]}
                                                            </Text>

                                                            <Text
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  color:
                                                                    theme.colors
                                                                      .primary,
                                                                  fontFamily:
                                                                    'Poppins_500Medium',
                                                                  fontSize: 10,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {' media items'}
                                                            </Text>
                                                          </View>
                                                          <>
                                                            {!Constants[
                                                              'is_admin'
                                                            ] ? null : (
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      'center',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                <IconButton
                                                                  color={
                                                                    theme.colors
                                                                      .error
                                                                  }
                                                                  icon={
                                                                    'Feather/trash-2'
                                                                  }
                                                                  onPress={() => {
                                                                    try {
                                                                      setSelectedFolderId(
                                                                        personalGridData?.id
                                                                      );
                                                                      setIsDeleteFolder(
                                                                        true
                                                                      );
                                                                    } catch (err) {
                                                                      console.error(
                                                                        err
                                                                      );
                                                                    }
                                                                  }}
                                                                  size={24}
                                                                />
                                                              </View>
                                                            )}
                                                          </>
                                                        </View>
                                                      </View>
                                                    </View>
                                                  </Touchable>
                                                </View>
                                              );
                                            }}
                                            style={StyleSheet.applyWidth(
                                              { width: '100%' },
                                              dimensions.width
                                            )}
                                            contentContainerStyle={StyleSheet.applyWidth(
                                              {
                                                alignContent: 'space-around',
                                                alignItems: 'stretch',
                                                alignSelf: 'stretch',
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </>
                                  );
                                }}
                              </RisingCoachesApi.FetchGetProgramFoldersGET>
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
              <>
                {fetchData?.verified ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'stretch',
                        alignItems: 'stretch',
                        alignSelf: 'stretch',
                        flex: 1,
                        justifyContent: 'space-between',
                        margin: 30,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            alignSelf: 'center',
                            marginTop: 100,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'Your account is not yet verified. Please check your email inbox and verify your account to continue. '
                      }
                    </Text>
                    <Button
                      onPress={() => {
                        try {
                          const out = setGlobalVariableValue({
                            key: 'auth_header',
                            value: '',
                          });
                          navigation.navigate('WelcomeScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            backgroundColor: theme.colors['Secondary'],
                            marginTop: 30,
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Sign Out'}
                    />
                  </View>
                )}
              </>
            </>
          );
        }}
      </RisingCoachesApi.FetchGetUserDetailGET>
    </ScreenContainer>
  );
};

export default withTheme(HomeScreen);
