import React from 'react';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import {
  ImageBackground,
  Platform,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';

const WelcomeScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignContent: 'stretch',
          alignSelf: 'stretch',
          backgroundColor: [
            {
              minWidth: Breakpoints.BigScreen,
              value: theme.colors['Background'],
            },
            {
              minWidth: Breakpoints.Mobile,
              value: theme.colors['Custom #ffffff'],
            },
          ],
        },
        dimensions.width
      )}
    >
      <>
        {!(Platform.OS === 'web') ? null : (
          <ImageBackground
            resizeMode={'center'}
            source={Images.Whitebg}
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'stretch',
                backgroundColor: [
                  {
                    minWidth: Breakpoints.BigScreen,
                    value: theme.colors['Custom #ffffff'],
                  },
                  {
                    minWidth: Breakpoints.Mobile,
                    value: theme.colors['Custom #ffffff'],
                  },
                ],
                height: '100%',
                justifyContent: 'space-around',
                paddingLeft: 16,
                paddingRight: 16,
              },
              dimensions.width
            )}
          >
            <View>
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Figtree_700Bold',
                    fontSize: 36,
                    marginBottom: 8,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Welcome'}
              </Text>

              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Figtree_500Medium',
                    fontSize: 16,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Lets get started.'}
              </Text>
            </View>
            <View />
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', width: '100%' },
                dimensions.width
              )}
            >
              {/* Button Solid */}
              <Button
                onPress={() => {
                  try {
                    navigation.navigate('SignUpScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_2'],
                    borderRadius: 64,
                    fontFamily: 'Figtree_700Bold',
                    maxWidth: 250,
                    minWidth: 150,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Sign Up'}
              >
                {'Sign Up'}
              </Button>
              {/* Button Outline */}
              <Button
                onPress={() => {
                  try {
                    navigation.navigate('SignInScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: 'transparent',
                    borderColor: theme.colors['Custom Color_2'],
                    borderRadius: 64,
                    borderWidth: 1,
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Figtree_700Bold',
                    marginTop: 16,
                    maxWidth: 250,
                    minWidth: 150,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Log In'}
              >
                {'Log In'}
              </Button>
            </View>
          </ImageBackground>
        )}
      </>
      {/* Image Background 2 */}
      <>
        {Platform.OS === 'web' ? null : (
          <ImageBackground
            resizeMode={'cover'}
            source={Images.Whitebg}
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'stretch',
                backgroundColor: theme.colors['Custom #ffffff'],
                height: '100%',
                justifyContent: 'space-around',
                paddingLeft: 16,
                paddingRight: 16,
              },
              dimensions.width
            )}
          >
            <View>
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Figtree_700Bold',
                    fontSize: 36,
                    marginBottom: 8,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Welcome'}
              </Text>

              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Figtree_500Medium',
                    fontSize: 16,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Lets get started.'}
              </Text>
            </View>
            <View />
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', width: '100%' },
                dimensions.width
              )}
            >
              {/* Button Solid */}
              <Button
                onPress={() => {
                  try {
                    navigation.navigate('SignUpScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Custom Color_2'],
                    borderRadius: 64,
                    fontFamily: 'Figtree_700Bold',
                    maxWidth: 250,
                    minWidth: 150,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Sign Up'}
              >
                {'Sign Up'}
              </Button>
              {/* Button Outline */}
              <Button
                onPress={() => {
                  try {
                    navigation.navigate('SignInScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: 'transparent',
                    borderColor: theme.colors['Custom Color_2'],
                    borderRadius: 64,
                    borderWidth: 1,
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Figtree_700Bold',
                    marginTop: 16,
                    maxWidth: 250,
                    minWidth: 150,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
                title={'Log In'}
              >
                {'Log In'}
              </Button>
            </View>
          </ImageBackground>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(WelcomeScreen);
