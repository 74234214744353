import React from 'react';
import * as CustomCode from '../CustomCode';
import * as RisingCoachesApi from '../apis/RisingCoachesApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  ActionSheet,
  ActionSheetCancel,
  ActionSheetItem,
  IconButton,
  ScreenContainer,
  Spacer,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  ImageBackground,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const RisingCoachesContentDetailsScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const convertDate = dateString => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    // Using moment's inbuilt function to get relative time.
    return CustomCode.moment(dateString).fromNow().toString();
  };

  const getImageUrl = userImageObj => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    return userImageObj.url;
  };

  const risingCoachesDeleteContentItemDELETE =
    RisingCoachesApi.useDeleteContentItemDELETE();

  const [isActionMenu, setIsActionMenu] = React.useState(false);
  const [new_comment, setNew_comment] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={true}
    >
      <RisingCoachesApi.FetchGetAdminContentDetailsGET
        folder_content_id={props.route?.params?.navigation_content_id ?? 2}
        handlers={{
          onData: fetchData => {
            const handler = async () => {
              try {
                (
                  await RisingCoachesApi.getContentDetailsGET(Constants, {
                    folder_content_id:
                      props.route?.params?.navigation_content_id ?? 2,
                  })
                )?.json;
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          },
        }}
        method={'GET'}
      >
        {({ loading, error, data, refetchGetAdminContentDetails }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* File View */}
              <>
                {!fetchData?.isFile ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 420, width: '100%' },
                      dimensions.width
                    )}
                  >
                    <>
                      {!fetchData?.isFile ? null : (
                        <WebView
                          source={{ uri: `${fetchData?.file?.url}` }}
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                )}
              </>
              {/* Video View */}
              <>
                {!fetchData?.isVideo ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 420, width: '100%' },
                      dimensions.width
                    )}
                  >
                    <>
                      {!fetchData?.isVideo ? null : (
                        <WebView
                          source={{ uri: `${fetchData?.youtube_render_link}` }}
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                )}
              </>
              {/* Image View */}
              <>
                {!fetchData?.isImage ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 420, width: '100%' },
                      dimensions.width
                    )}
                  >
                    <ImageBackground
                      resizeMode={'contain'}
                      source={{ uri: `${fetchData?.image?.url}` }}
                      style={StyleSheet.applyWidth(
                        {
                          height: '100%',
                          justifyContent: 'flex-end',
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    />
                  </View>
                )}
              </>
              <Spacer bottom={12} left={8} right={8} top={12} />
              <View>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      paddingBottom: 24,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 0,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.light,
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {fetchData?.content_type}
                  </Text>

                  <Text
                    allowFontScaling={true}
                    ellipsizeMode={'tail'}
                    numberOfLines={2}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.strong,
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 22,
                      },
                      dimensions.width
                    )}
                    textBreakStrategy={'highQuality'}
                  >
                    {fetchData?.content_title}
                  </Text>
                  <Spacer bottom={8} left={8} right={8} top={8} />
                  <View>
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            alignSelf: 'stretch',
                            borderBottomWidth: 1,
                            borderColor: theme.colors.lightest,
                            borderLeftWidth: 1,
                            borderRadius: 8,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingBottom: 8,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.medium,
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 12,
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {'Uploaded by '}
                          {fetchData && fetchData['_user']?.name}
                        </Text>
                      </View>
                      <Spacer bottom={8} left={6} right={6} top={8} />
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: theme.colors.lightest,
                            borderLeftWidth: 1,
                            borderRadius: 8,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            paddingBottom: 8,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                      >
                        <Spacer left={2} right={2} />
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors.medium,
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {convertDate(fetchData?.created_at)}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <Spacer bottom={12} left={8} right={8} top={12} />
                  <View>
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Poppins_600SemiBold',
                          fontSize: 16,
                        },
                        dimensions.width
                      )}
                    >
                      {'Description'}
                    </Text>
                    <Spacer bottom={6} left={8} right={8} top={6} />
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.medium,
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 14,
                          lineHeight: 26,
                          textAlign: 'left',
                        },
                        dimensions.width
                      )}
                    >
                      {fetchData?.text_content}
                    </Text>
                  </View>
                  <Spacer bottom={12} left={8} right={8} top={12} />
                </View>
              </View>
            </>
          );
        }}
      </RisingCoachesApi.FetchGetAdminContentDetailsGET>
      <ActionSheet visible={isActionMenu}>
        {/* Delete Action */}
        <ActionSheetItem
          color={theme.colors.error}
          label={'Delete Item'}
          onPress={() => {
            const handler = async () => {
              try {
                (
                  await risingCoachesDeleteContentItemDELETE.mutateAsync({
                    folder_content_id:
                      props.route?.params?.navigation_content_id ?? 2,
                  })
                )?.json;
                setIsActionMenu(false);
                navigation.navigate('ProgramsScreen');
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            { textAlign: 'center' },
            dimensions.width
          )}
        />
        <ActionSheetCancel
          label={'Cancel'}
          onPress={() => {
            try {
              setIsActionMenu(false);
            } catch (err) {
              console.error(err);
            }
          }}
        />
      </ActionSheet>
    </ScreenContainer>
  );
};

export default withTheme(RisingCoachesContentDetailsScreen);
