import React from 'react';
import * as MagicLinkApi from '../apis/MagicLinkApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import { Button, ScreenContainer, TextInput, withTheme } from '@draftbit/ui';
import { Text, View, useWindowDimensions } from 'react-native';

const PasswordRecoveryScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: 'stretch',
            flex: 1,
            justifyContent: 'center',
            marginLeft: 30,
            marginRight: 30,
          },
          dimensions.width
        )}
      >
        <Text
          style={StyleSheet.applyWidth(
            {
              alignSelf: 'center',
              color: theme.colors['Error'],
              fontFamily: 'Manrope_400Regular',
              marginBottom: 10,
              textAlign: 'center',
            },
            dimensions.width
          )}
        >
          {Constants['error_message']}
        </Text>
        <TextInput
          editable={true}
          onChangeText={newTextInputValue => {
            try {
              setTextInputValue(newTextInputValue);
            } catch (err) {
              console.error(err);
            }
          }}
          placeholder={'Enter email here'}
          style={StyleSheet.applyWidth(
            {
              borderBottomWidth: 1,
              borderColor: theme.colors.divider,
              borderLeftWidth: 1,
              borderRadius: 8,
              borderRightWidth: 1,
              borderTopWidth: 1,
              fontFamily: 'Manrope_400Regular',
              paddingBottom: 8,
              paddingLeft: 8,
              paddingRight: 8,
              paddingTop: 8,
            },
            dimensions.width
          )}
          value={textInputValue}
        />
        <Button
          onPress={() => {
            const handler = async () => {
              try {
                const response = (
                  await MagicLinkApi.resetPasswordGET(Constants, {
                    email: textInputValue?.toLowerCase(),
                  })
                )?.json;
                const message = response?.message;
                setGlobalVariableValue({
                  key: 'error_message',
                  value: message,
                });
                if (message) {
                  return;
                }
                setGlobalVariableValue({
                  key: 'auth_header',
                  value: '',
                });
                navigation.navigate('WelcomeScreen');
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.primary,
              borderRadius: 8,
              fontFamily: 'System',
              fontWeight: '700',
              marginTop: 10,
              textAlign: 'center',
            },
            dimensions.width
          )}
          title={'Reset Password'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(PasswordRecoveryScreen);
